export const UPDATE_SYSTEM_MESSAGE = 'UPDATE_SYSTEM_MESSAGE'

export function updateSystemMessage(systemMessages, path) {
	const updatedSystemMessages = {
		type: UPDATE_SYSTEM_MESSAGE,
		systemMessages: []
	}
	if (Array.isArray(systemMessages) && systemMessages.length > 0) {
		updatedSystemMessages.systemMessages = filterValidSystemMessages(systemMessages)
	}
	if (
		systemMessages !== undefined &&
		Array.isArray(systemMessages.systemMessages) &&
		systemMessages.systemMessages.length > 0
	) {
		updatedSystemMessages.systemMessages = filterValidSystemMessages(systemMessages.systemMessages)
	}
	if (path !== undefined) {
		updatedSystemMessages.systemMessages = filterValidSystemMessages(
			setSystemMessagesPath(updatedSystemMessages.systemMessages, path)
		)
	}
	return updatedSystemMessages
}

export function setSystemMessagesPath(systemMessages, path) {
	if (path !== undefined && typeof path === 'string' && Array.isArray(systemMessages)) {
		return systemMessages.map(message => {
			return {
				...message,
				path
			}
		})
	}
	console.error('The system message "path" is undefined')
}

export function filterValidSystemMessages(systemMessages) {
	return systemMessages
		.filter(message => message.hasOwnProperty('message') || message.hasOwnProperty('messages'))
		.map(message => {
			const useMessage = message.message ? message.message : message.messages
			const { messages, ...otherProps } = message
			if (message.hasOwnProperty('icon')) {
				return { ...otherProps, message: useMessage }
			}
			return {
				...otherProps,
				message: useMessage,
				icon: 'information'
			}
		})
}
