import React from 'react'
import { FormattedMessage } from 'react-intl'
import EqualHousingLogo from '../Equal-Housing-Logo.png'
import { BasicFooter, BasicNoContent } from 'ecpfs-react-data-driven-components'

export function CustomBasicFooter({ showEqualHousing = false, links, copyright, instructions }) {
	return (
		<div role="contentinfo">
			<BasicFooter links={links} copyright={copyright} instructions={instructions} />
			<EqualHousing show={showEqualHousing} />
		</div>
	)
}

export function EqualHousing({ show }) {
	if (show) {
		return (
			<div className="EqualHousing">
				<img src={EqualHousingLogo} alt="" />
				<span>
					<FormattedMessage id="custom-basic-footer.label.equal-housing-lender" />
				</span>
			</div>
		)
	} else {
		return <BasicNoContent />
	}
}

CustomBasicFooter.displayName = 'CustomBasicFooter'

export default CustomBasicFooter
