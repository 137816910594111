import { fetchUserProfile } from '../services/fetchUserProfile'
import { updateSystemMessage } from './systemMessages'
import { DataLink } from 'ecpfs-react-data-driven-components'

export function getUserProfile() {
	return async function getUserProfileThunk(dispatch) {
		try {
			const response = await fetchUserProfile()
			if (response.dataLink) {
				const userProfileResponse = DataLink.getContainerContent(response, [])[0]
				await dispatch(getUserProfileSuccess(userProfileResponse))
			} else {
				dispatch(updateSystemMessage(response))
			}
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'

export function getUserProfileSuccess(userProfile) {
	return {
		type: GET_USER_PROFILE_SUCCESS,
		userProfile
	}
}
