import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import get from 'lodash.get'
import en from './locale/en'
import { CEOSessionManager } from '@wf/ria'
import {
	BasicNavigator,
	BrowserNavigationContext,
	addComponents,
	organizeClassNames
} from 'ecpfs-react-data-driven-components'

import { getAppNavigator, signOut } from './actions/navigator'
import { OutagePage } from './components/OutagePage'
import CustomFacilityBasicLabelValue from './components/CustomFacilityBasicLabelValue'
import CustomFacilityColumn from './components/CustomFacilityColumn'
import CustomMenuDropDown from './components/CustomMenuDropDown'
import CustomPrintArea from './components/CustomPrintArea'
import CustomPrintButton from './components/CustomPrintButton'
import LoanIqBasicHeader from './components/LoanIqBasicHeader'
import CustomHomePageTable from './components/CustomHomePageTable'
import { validateJson } from './utils/validateJson'

import CustomBasicFooter from './components/CustomBasicFooter'
import CustomDateRange from './components/CustomDateRange'
import CustomCustomerNumberColumn from './components/CustomCustomerNumberColumn'
import ViewInterestPanel from './containers/ViewInterestPanel'
import ViewAllCustomersPanel from './components/ViewAllCustomersPanel'
import AddNicknamePanel from './containers/AddNicknamePanel'
import CustomNicknameOptions from './components/CustomNicknameOptions'
import ObligationTransactionHistoryFilter from './components/ObligationTransactionHistoryFilter'
import TransactionHistoryTableFilter from './components/TransactionHistoryTableFilter'
import EditNicknamePanel from './containers/EditNicknamePanel'
import ViewLoanBillingStatementsPage from './containers/ViewLoanBillingStatementsPage'
import CustomInvoiceDownloadColumn from './components/CustomInvoiceDownloadColumn'
import ViewCommitmentDetails from './containers/ViewCommitmentDetails'
import CustomAccountStatusColumn from './components/CustomAccountStatusColumn'
import ObligationDetailsPanelContent from './containers/ObligationDetailsPanelContent'
import AddObligationNicknamePanel from './containers/AddObligationNicknamePanel'
import EditObligationNicknamePanel from './containers/EditObligationNicknamePanel'
import CustomObligationMenu from './components/CustomObligationMenu'
import CommitmentDetailsTabs from './containers/CommitmentDetailsTabs'
import CustomSystemMessage from './containers/CustomSystemMessage'

import schemas from './data/schemas.json'

export function AfsApp({ navigator = {}, onLanguageChange, onCEOSignOut }) {
	const { locale = { value: 'en' }, fromInternalSite } = navigator
	const isCldpAdmin = fromInternalSite
	const cldpAdminSignOffUrl = get(navigator, 'session.signOut.url', '/SignOut.html')
	const language = locale.value
	const languages = { en }

	const responsesAreValid = checkInitialResponses([[navigator, schemas.navigator]])

	const { setPath } = useContext(BrowserNavigationContext)
	useEffect(() => {
		const route = document.URL.split('#/')[1]
		if (
			route === '' &&
			Array.isArray(navigator.links) &&
			navigator.links.find(link => link?.dataLink?.path === '/home') !== undefined
		) {
			setPath({ path: '/home' })
		}
		// eslint-disable-next-line
	})

	if (responsesAreValid === false) {
		return (
			<IntlProvider locale={language} messages={languages[language]}>
				<OutagePage />
			</IntlProvider>
		)
	}

	const classNames = organizeClassNames('DigitalServicing', {
		Perusal: fromInternalSite
	})
	const useNavigator = {
		...navigator,
		session: {
			...navigator.session,
			signOut: {
				...navigator.session.signOut,
				onSignOut
			}
		}
	}

	return (
		<div className={classNames}>
			<IntlProvider locale={language} messages={languages[language]}>
				<CustomSessionManager
					isCldpAdmin={isCldpAdmin}
					cldpAdminSignOffUrl={cldpAdminSignOffUrl}
					onCEOSignOut={onCEOSignOut}
				/>
				<BasicNavigator
					{...useNavigator}
					closeOnSelection={true}
					onLanguageChange={locale => onLanguageChange({ locale })}
					onLocationChange={onLocationChange}
				/>
			</IntlProvider>
		</div>
	)
}

export function onSignOut() {
	const { Inq = {} } = window
	const { closeChat } = Inq
	if (typeof closeChat === 'function') {
		closeChat()
	}
}

export function CustomSessionManager({ isCldpAdmin, cldpAdminSignOffUrl, onCEOSignOut }) {
	function handleOnSignOffClick(redirectToSignOff) {
		onCEOSignOut(redirectToSignOff, isCldpAdmin, cldpAdminSignOffUrl)
	}

	return <CEOSessionManager pingUrl={'./'} onSignOffClick={handleOnSignOffClick} />
}

export function onLocationChange() {
	const { Inq = {} } = window
	const { reinitChat } = Inq
	if (typeof reinitChat === 'function') {
		reinitChat()
	}
}

export function checkInitialResponses(responses) {
	const responsesAreValid = responses.find(response => {
		const instance = response[0]
		const schema = response[1]
		return validateJson(schema, instance) === false
	})
	return responsesAreValid === undefined
}

export function runAddComponentsAfs() {
	addComponents(
		AddNicknamePanel,
		CustomBasicFooter,
		CustomAccountStatusColumn,
		CustomCustomerNumberColumn,
		CustomDateRange,
		CustomFacilityBasicLabelValue,
		CustomFacilityColumn,
		CustomHomePageTable,
		CustomInvoiceDownloadColumn,
		CustomMenuDropDown,
		CustomNicknameOptions,
		CustomPrintArea,
		CustomPrintButton,
		EditNicknamePanel,
		LoanIqBasicHeader,
		ViewAllCustomersPanel,
		ViewCommitmentDetails,
		ViewInterestPanel,
		ViewLoanBillingStatementsPage,
		ObligationDetailsPanelContent,
		AddObligationNicknamePanel,
		EditObligationNicknamePanel,
		CustomObligationMenu,
		CommitmentDetailsTabs,
		CustomSystemMessage,
		ObligationTransactionHistoryFilter,
		TransactionHistoryTableFilter
	)
}

const mapStateToProps = state => ({
	navigator: state.navigator
})

export const mapDispatchToProps = dispatch => ({
	onLanguageChange(locale) {
		dispatch(getAppNavigator({ locale }))
	},
	onCEOSignOut(redirectToSignOff, isCldpAdmin, cldpAdminSignOffUrl) {
		dispatch(signOut(redirectToSignOff, isCldpAdmin, cldpAdminSignOffUrl))
	}
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AfsApp)
