import { BasicLabelSelect, BasicNoContent } from 'ecpfs-react-data-driven-components'
import CustomDateRange from './CustomDateRange'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
moment.defaultFormat = 'MM-DD-YYYY'

function ObligationTransactionHistoryFilter({
	formatMessage,
	appliedFilters,
	onUpdateFilterValue,
	validEndMonth,
	validStartMonth
}) {
	const {
		effectiveDateType,
		startEffectiveDate = validStartMonth,
		endEffectiveDate = validEndMonth
	} = appliedFilters

	const [date, setDate] = useState({ startDate: startEffectiveDate, endDate: endEffectiveDate })
	function handleFilterTypeChange(value) {
		onUpdateFilterValue({ key: 'effectiveDateType', value })
	}
	function onDateChange({ startDate, endDate }) {
		setDate({ startDate, endDate })
	}

	useEffect(() => {
		if (
			(date.startDate !== startEffectiveDate &&
				moment(date.startDate).isBetween(validStartMonth, validEndMonth, null, '[]')) ||
			(date.endDate !== endEffectiveDate &&
				moment(date.endDate).isBetween(validStartMonth, validEndMonth, null, '[]'))
		) {
			onUpdateFilterValue({
				key: 'startEffectiveDate',
				value: date.startDate
			})
			onUpdateFilterValue({
				key: 'endEffectiveDate',
				value: date.endDate
			})
		}
	}, [
		date,
		endEffectiveDate,
		onUpdateFilterValue,
		startEffectiveDate,
		validEndMonth,
		validStartMonth
	])

	return (
		<div className="TableFilter">
			<div className="wf-row--2-columns">
				<div className="wf-col--9">
					<div className="EffectiveDate">
						<div className="EffectiveDateSelect">
							<BasicLabelSelect
								layout={{
									width: 'wf-col--3'
								}}
								label={formatMessage({
									id: 'commitment-details-page-transaction-history.filter.select-effective-date'
								})}
								name="effectiveDate"
								onValueSelected={handleFilterTypeChange}
								choices={[
									{
										value: '1',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.previous-day'
										})
									},
									{
										value: '7',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.last-seven-days'
										})
									},
									{
										value: '30',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.last-thirty-days'
										})
									},
									{
										value: 'customDate',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.last-custom-date-range'
										})
									}
								]}
								value={effectiveDateType}
							/>
						</div>
					</div>
					<div className="CustomDateRange">
						<ObligationHistoryCustomDateRange
							effectiveDateType={effectiveDateType}
							date={date}
							formatMessage={formatMessage}
							onDateChange={onDateChange}
							validStartMonth={validStartMonth}
							validEndMonth={validEndMonth}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

function ObligationHistoryCustomDateRange({
	effectiveDateType,
	date,
	formatMessage,
	onDateChange,
	validStartMonth,
	validEndMonth
}) {
	if (effectiveDateType === 'customDate') {
		return (
			<CustomDateRange
				id="effectiveDate"
				applied={date}
				formatMessage={formatMessage}
				dateRange="9999"
				label=""
				reset={true}
				onDateRangeChange={onDateChange}
				minSelectableDate={new Date(validStartMonth)}
				maxSelectableDate={new Date(validEndMonth)}
				customStrings={{
					dateFormatMustBe: function() {
						return formatMessage({
							id: 'commitment-details-page-transaction-history.filter.validation.invalid.format'
						})
					},
					invalidStartEndDate: formatMessage({
						id: 'commitment-details-page-transaction-history.filter.validation.invalid.format'
					})
				}}
			/>
		)
	}
	return <BasicNoContent />
}
ObligationTransactionHistoryFilter.displayName = 'ObligationTransactionHistoryFilter'
export default ObligationTransactionHistoryFilter
