import { updateSystemMessage } from './systemMessages'
import { fetchBillingStatements } from '../services/fetchBillingStatements'
import { fetchDownloadInvoice } from '../services/fetchDownloadInvoice'
import get from 'lodash.get'
import { updateShowLoading } from './siteLoader'
import { fetchGetAccountAndBalances } from '../services/fetchGetAccountAndBalances'
import { fetchConfirmationViewData } from '../services/fetchConfirmationViewData'
import { DataLink } from 'ecpfs-react-data-driven-components'

export function getBillingStatements(ledger, paginationParams = {}) {
	return async function getBillingStatementsThunk(dispatch) {
		dispatch(updateShowLoading(true))
		try {
			const request = {
				ledger: {
					encryptedKey: ledger
				},
				...paginationParams
			}
			const response = await fetchBillingStatements(request)
			if (get(response, 'systemMessages.length', 0) > 0) {
				dispatch(updateSystemMessage(response))
			}
			dispatch(
				getBillingStatementsSuccess(
					response,
					get(response, 'dataLink.params.isSingleCustomer', false)
				)
			)
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
		dispatch(updateShowLoading(false))
	}
}

export const GET_BILLING_STATEMENTS_SUCCESS = 'GET_LOAN_BILLING_STATEMENTS_SUCCESS'

export function getBillingStatementsSuccess(billingStatements, view) {
	return {
		type: GET_BILLING_STATEMENTS_SUCCESS,
		billingStatements,
		view
	}
}

export function downloadInvoice(ledger, formatMessage) {
	return async function downloadInvoiceThunk(dispatch) {
		try {
			const request = {
				ledger: {
					encryptedKey: ledger
				}
			}
			await fetchDownloadInvoice(request)
		} catch (error) {
			dispatch(
				updateSystemMessage([
					{
						icon: 'alert',
						message: formatMessage({
							id: 'loan-billing.statements-page.system-message.download-error'
						})
					}
				])
			)
		}
	}
}

export const SHOW_SOURCEACCOUNT_LOADING = 'SHOW_SOURCEACCOUNT_LOADING'
export function showSourceAccountLoading(showAccountsLoading) {
	return {
		type: SHOW_SOURCEACCOUNT_LOADING,
		showAccountsLoading
	}
}

export const CLEAR_ACCOUNT_AND_BALANCES = 'CLEAR_ACCOUNT_AND_BALANCES'
export function getAccountAndBalances(encryptedKey) {
	return async function getAccountAndBalancesThunk(dispatch) {
		dispatch(showSourceAccountLoading(true))
		try {
			dispatch({
				type: CLEAR_ACCOUNT_AND_BALANCES
			})
			const request = {
				ledger: {
					encryptedKey
				}
			}
			const response = await fetchGetAccountAndBalances(request)
			dispatch(updateSystemMessage(response.systemMessages))
			const rows = DataLink.getContainerContent(response, [])
			if (rows.length > 0) {
				dispatch(getAccountAndBalancesSuccess(response))
			}
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
		dispatch(showSourceAccountLoading(false))
	}
}

export const GET_ACCOUNT_AND_BALANCES_SUCCESS = 'GET_ACCOUNT_AND_BALANCES_SUCCESS'

export function getAccountAndBalancesSuccess(accounts) {
	return {
		type: GET_ACCOUNT_AND_BALANCES_SUCCESS,
		accounts
	}
}

function getAmount(stringValue = '') {
	return Number(stringValue.replace(/,/g, ''))
}

export function getConfirmationViewData(
	invoiceNumber,
	invoiceAmount,
	selectedAccountEncryptedKey,
	responseStatus
) {
	return async function getConfirmationViewDataThunk(dispatch) {
		try {
			const convertedInvoiceAmount = getAmount(invoiceAmount.split(' ')[0])
			const request = {
				invoiceNumber,
				paymentTotal: convertedInvoiceAmount,
				tmsRequest: {
					encryptedKey: selectedAccountEncryptedKey
				}
			}
			const response = await fetchConfirmationViewData(request)
			if (response.systemMessages && response.systemMessages.length === 1) {
				let responseMsg = response.systemMessages[0]
				console.log(responseMsg)
				if (responseMsg.hasOwnProperty('icon') && responseMsg.icon === 'error') {
					responseStatus(false)
					dispatch(updateSystemMessage(response.systemMessages))
				}
			} else {
				responseStatus(true)
				dispatch(getConfirmationViewDataSuccess(response?.rows))
			}
		} catch (error) {
			responseStatus(false)
			dispatch(updateSystemMessage(error))
		}
	}
}

export const GET_CONFIRMATION_VIEW_DATA_SUCCESS = 'GET_CONFIRMATION_VIEW_DATA_SUCCESS'

export function getConfirmationViewDataSuccess(confirmationViewData) {
	return {
		type: GET_CONFIRMATION_VIEW_DATA_SUCCESS,
		confirmationViewData
	}
}
