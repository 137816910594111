import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, MenuButton } from '@wf/ria'
import { DOWNLOAD } from 'ecpfs-authority'
import { BasicNoContent, organizeClassNames } from 'ecpfs-react-data-driven-components'
import { useDispatch } from 'react-redux'
import { updateSystemMessage } from '../actions/systemMessages'

export function CustomColumnDownloadActions({ label, items = [], display = 'simple', ...props }) {
	const className = organizeClassNames('CustomColumnDownloadActions', props.className)
	const { rowData } = props
	const dispatch = useDispatch()
	if (items.length === 0) {
		return <BasicNoContent />
	}

	// https://jira.wellsfargo.net/browse/EFMYA-24923
	if (window && window.Bridge?.isNative()) {
		return <BasicNoContent />
	}

	let useItems = items
	const displayPDF = rowData?.item?.displayPDF
	const displayCSV = rowData?.item?.displayCSV
	const downloadAvailable = displayPDF || displayCSV
	if (items[0].dataLink.download) {
		if (!displayPDF && !displayCSV) {
			useItems = []
		}
		if (displayPDF && !displayCSV) {
			useItems = items.filter(item => {
				return item?.dataLink?.params?.downloadType === 'PDF'
			})
		}
		if (!displayPDF && displayCSV) {
			useItems = items.filter(item => {
				return item?.dataLink?.params?.downloadType === 'CSV'
			})
		}
	}

	return (
		<MenuButton
			disabled={downloadAvailable === false ? true : false}
			className={className}
			kind={display}
			label={label}
		>
			{getMenuItems(useItems, rowData, dispatch)}
		</MenuButton>
	)
}

export async function onExport(api, params, dispatch) {
	try {
		await DOWNLOAD(api, params)
	} catch (error) {
		if (error && error.systemMessages) {
			dispatch(updateSystemMessage(error))
		} else {
			console.error('CustomColumnDownloadActions Error', error)
		}
	}
}

export function getMenuItems(useItems, rowData = {}, dispatch) {
	return useItems.map(item => {
		const {
			display = 'simple',
			value,
			dataLink: { api, params }
		} = item
		const useParams = {
			...params,
			...rowData.item
		}
		const useValue = value ? value : item.dataLink.params.downloadType
		const useItem = {
			...item,
			display,
			rowData: rowData.item,
			onSelect: () => {
				onExport(api, useParams, dispatch)
			}
		}
		const itemName = useItem.dataLink.params.downloadType
		return (
			<MenuItem key={itemName} value={useValue} onSelect={useItem.onSelect}>
				{useItem.label}
			</MenuItem>
		)
	})
}

CustomColumnDownloadActions.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	display: PropTypes.oneOf(['primary', 'standard', 'chromeless', 'simple']),
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
		})
	)
}

CustomColumnDownloadActions.displayName = 'CustomColumnDownloadActions'

export default CustomColumnDownloadActions
