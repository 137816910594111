import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withUserDataForm } from 'ecpfs-react-data-driven-components'
import { CommitmentDetailsTabs } from '../components/CommitmentDetailsTabs'
import {
	getCommitmentTableData,
	handleFilterValueChange,
	clearCommitmentDetailsTableData
} from '../actions/commitment'
import { updateSystemMessage } from '../actions/systemMessages'

export const mapStateToProps = state => ({
	appliedFilters: state.commitment.appliedFilters,
	tabs: state.commitment.tabs,
	commitmentSystemMessage: state.commitment.commitmentSystemMessage,
	commitmentDetailsTableInfo: state.commitment.commitmentDetailsTableInfo,
	fromInternalSite: state.navigator.fromInternalSite,
	commitments: state.commitment.commitments,
	preferenceMonth: state.ledgers.preferenceMonth,
	validStartMonth: state.ledgers.validStartMonth,
	validEndMonth: state.ledgers.validEndMonth
})

export const mapDispatchToProps = dispatch => ({
	onGetCommitmentTableData(selectedTab, facilityKey, pagination, filterData) {
		dispatch(getCommitmentTableData(selectedTab, facilityKey, pagination, filterData))
	},
	onApplyFilter(selectedTab, facilityKey, pagination, filterData) {
		dispatch(getCommitmentTableData(selectedTab, facilityKey, pagination, filterData))
	},
	onHandleFilterValueChange(filter) {
		dispatch(handleFilterValueChange(filter))
	},
	onUpdateSystemMessage(systemMessages) {
		dispatch(updateSystemMessage(systemMessages))
	},
	onClearCommitmentTableData() {
		dispatch(clearCommitmentDetailsTableData())
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withUserDataForm()(CommitmentDetailsTabs))
)
component.displayName = 'CommitmentDetailsTabs'

export default component
