import React, { useEffect, useState, useContext } from 'react'
import {
	BasicTable,
	BasicNoContent,
	BasicLabel,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { Button, Feedback } from '@wf/ria'
import get from 'lodash.get'
import { PaydownsAndAdvancesPanel } from './PaydownsAndAdvancesPanel'
import { getSystemMessageIconData } from '../utils/getSystemMessageIconData'
import isEmpty from 'lodash.isempty'

export function PayDownsAndAdvancesTable({
	formatMessage,
	dropdownValue,
	payDownsAndAdvancesData,
	facilityKey,
	showAccountsLoading,
	onGetPayDownsAndAdvancesData,
	payDownsAndAdvancesSystemMessage,
	commitmentDetails,
	onUpdateSystemMessage,
	onGetPayDownsConfirmationViewData,
	onGetCommitmentDetails
}) {
	if (dropdownValue === 'payDownPrincipal' || dropdownValue === 'advanceFunds') {
		return (
			<div className="PayDownsAndAdvances" tabIndex="0">
				<BasicLabel
					level={2}
					label={
						dropdownValue === 'payDownPrincipal'
							? formatMessage({
									id: 'pay-down-principal-table.label.pay-down-principal'
							  })
							: formatMessage({
									id: 'advance-funds-table.label.advance-funds'
							  })
					}
				/>
				<CustomPaydownsAndAdvancesTable
					formatMessage={formatMessage}
					payDownsAndAdvancesData={payDownsAndAdvancesData}
					dropdownValue={dropdownValue}
					commitmentDetails={commitmentDetails}
					facilityKey={facilityKey}
					showAccountsLoading={showAccountsLoading}
					onGetPayDownsAndAdvancesData={onGetPayDownsAndAdvancesData}
					payDownsAndAdvancesSystemMessage={payDownsAndAdvancesSystemMessage}
					onUpdateSystemMessage={onUpdateSystemMessage}
					onGetPayDownsConfirmationViewData={onGetPayDownsConfirmationViewData}
					onGetCommitmentDetails={onGetCommitmentDetails}
				/>
			</div>
		)
	}
	return <BasicNoContent />
}

function getTableColumns(
	isSingleCustomer,
	formatMessage,
	dropdownValue,
	setRowData,
	setOpenPanel,
	clearMessages,
	onUpdateSystemMessage
) {
	const outstandingAmountId =
		dropdownValue === 'payDownPrincipal'
			? 'loan-summary.view-commitment-details.table.column.outstanding-principal'
			: 'loan-summary.view-commitment-details.table.column.amount-available'
	const amountFieldName =
		dropdownValue === 'payDownPrincipal' ? 'outstandingPrincipalBalance' : 'amountAvailableUSD'
	const columns = [
		{
			key: 'obligationId',
			name: 'obligationId',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.table.column.obligation'
			}),
			collapse: 7
		},
		{
			name: 'obligationNickname',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.table.column.nickname'
			}),
			collapse: 3
		},
		{
			name: 'customer',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.table.column.customer'
			}),
			tag: 'CustomCustomerNumberColumn',
			collapse: 1
		},
		{
			name: 'interestRate',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.table.column.current-rate'
			}),
			collapse: 4
		},
		{
			name: 'description',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.table.column.description'
			}),
			collapse: 2
		},
		{
			name: amountFieldName,
			label: formatMessage({
				id: outstandingAmountId
			}),
			collapse: 5,
			align: 'right'
		},
		{
			label: formatMessage({ id: 'loan-summary.view-commitment-details.table.column.action' }),
			name: 'action',
			collapse: 6,
			formatter: rowData => {
				const { obligationId = '' } = rowData?.item
				const actionButtonLabel = formatMessage({
					id:
						dropdownValue === 'payDownPrincipal'
							? 'loan-billing.statements-page.make-payment'
							: 'advance-funds-table.label.advance-funds'
				})
				return (
					<Button
						kind="chromeless"
						label={actionButtonLabel}
						aria-label={`${actionButtonLabel} ${obligationId}`}
						onClick={() => {
							clearMessages()
							onUpdateSystemMessage([])
							setRowData(rowData)
							setOpenPanel(true)
						}}
					/>
				)
			}
		}
	]

	if (isSingleCustomer) {
		return columns.filter(column => column.name !== 'customer')
	}
	return columns
}

export function CustomPaydownsAndAdvancesTable({
	formatMessage,
	payDownsAndAdvancesData,
	dropdownValue,
	commitmentDetails,
	facilityKey,
	showAccountsLoading,
	payDownsAndAdvancesSystemMessage,
	onGetPayDownsAndAdvancesData,
	onUpdateSystemMessage,
	onGetPayDownsConfirmationViewData,
	onGetCommitmentDetails
}) {
	const { rows = [] } = payDownsAndAdvancesData
	const [rowData, setRowData] = useState(null)
	const [openPanel, setOpenPanel] = useState(false)
	const params = get(payDownsAndAdvancesData, 'dataLink.params', {})
	const { isSingleCustomer = false } = params
	const { clearMessages } = useContext(SystemMessagesContext)
	const tableColumnConfig = getTableColumns(
		isSingleCustomer,
		formatMessage,
		dropdownValue,
		setRowData,
		setOpenPanel,
		clearMessages,
		onUpdateSystemMessage
	)
	const ariaCaption =
		dropdownValue === 'payDownPrincipal'
			? formatMessage({
					id: 'pay-down-principal-table.label.aria-caption'
			  })
			: formatMessage({
					id: 'advance-funds-table.label.aria-caption'
			  })

	useEffect(() => {
		onGetPayDownsAndAdvancesData(facilityKey, dropdownValue)
	}, [facilityKey, dropdownValue, onGetPayDownsAndAdvancesData])

	if (!isEmpty(payDownsAndAdvancesSystemMessage)) {
		const { iconComponent, theme } = getSystemMessageIconData(
			payDownsAndAdvancesSystemMessage.icon,
			'medium'
		)
		return (
			<Feedback icon={iconComponent} size="large" role="alert" padding border theme={theme}>
				{payDownsAndAdvancesSystemMessage.message}
			</Feedback>
		)
	}
	return (
		<>
			<BasicTable
				primaryKey="encryptedKey"
				columns={tableColumnConfig}
				rows={rows}
				ariaCaption={ariaCaption}
			/>
			<PaydownsAndAdvancesPanel
				show={openPanel}
				onClose={() => setOpenPanel(false)}
				rowData={rowData}
				singleCustomerView={isSingleCustomer}
				formatMessage={formatMessage}
				dropdownValue={dropdownValue}
				commitmentDetails={commitmentDetails}
				showAccountsLoading={showAccountsLoading}
				facilityKey={facilityKey}
				onGetCommitmentDetails={onGetCommitmentDetails}
				onGetPayDownsAndAdvancesData={onGetPayDownsAndAdvancesData}
				onGetPayDownsConfirmationViewData={onGetPayDownsConfirmationViewData}
			/>
		</>
	)
}

PayDownsAndAdvancesTable.displayName = 'PayDownsAndAdvancesTable'

export default PayDownsAndAdvancesTable
