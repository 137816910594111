import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import { DataLink } from 'ecpfs-react-data-driven-components'
import get from 'lodash.get'

import { fetchUserProfile } from './services/fetchUserProfile'
import { fetchLedgers } from './services/fetchLedgers'
import afsReducer from './reducers/reducer'

export async function getAfsStore(navigator) {
	return await createAfsStore(createStore, navigator)
}

export async function createAfsStore(createStoreFunc, navigator) {
	const userProfile = await getUserProfile()
	const ledgers = await getLedgers()
	const store = createStoreFunc(
		afsReducer,
		{
			navigator,
			profile: {
				userProfile
			},
			ledgers
		},
		getReduxComposer(applyMiddleware(reduxThunk))
	)

	return store
}

const getReduxComposer = middleware => {
	// WARN comment this next line out before committing
	// return (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(middleware) // EVIL LINE
	return compose(middleware)
}

export async function getUserProfile() {
	try {
		const response = await fetchUserProfile()
		if (response.dataLink) {
			return DataLink.getContainerContent(response, [])[0]
		}
		throw new Error('datalink is undefined')
	} catch (error) {
		throw new Error('Error fetching user profile!')
	}
}

export async function getLedgers() {
	try {
		const ledgersResponse = await fetchLedgers()
		const ledgers = ledgersResponse.ledgers.map((ledger, tabIndex) => {
			return {
				...ledger,
				tabIndex
			}
		})
		return {
			ledgers: DataLink.getContainerContent(ledgers, []),
			selectedLedger: DataLink.getContainerContent(ledgers, [])[0],
			featureFlags: get(DataLink.getContainerContent(ledgers, []), '[0].userAllowedFeatures', {}),
			preferenceMonth: DataLink.getContainerContent(
				ledgersResponse.dataLink.params.transPrefMonth,
				''
			),
			validEndMonth: DataLink.getContainerContent(
				ledgersResponse.dataLink.params.validEndMonth,
				''
			),
			validStartMonth: DataLink.getContainerContent(
				ledgersResponse.dataLink.params.validStartMonth,
				''
			)
		}
	} catch (error) {
		throw new Error('Error fetching ledgers ...')
	}
}
