import React from 'react'
import AddNicknamePanel from '../containers/AddNicknamePanel'
import EditNicknamePanel from '../containers/EditNicknamePanel'

export function CustomNicknameOptions({ encryptedKey }) {
	return (
		<div className="NicknameModal">
			<EditNicknamePanel encryptedKey={encryptedKey} />
			<AddNicknamePanel encryptedKey={encryptedKey} />
		</div>
	)
}

CustomNicknameOptions.displayName = 'CustomNicknameOptions'

export default CustomNicknameOptions
