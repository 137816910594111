import { GET_USER_PROFILE_SUCCESS } from '../actions/profile'

export const initialState = {
	get userProfile() {
		return {}
	}
}

export function profile(state = initialState, action) {
	switch (action.type) {
		case GET_USER_PROFILE_SUCCESS:
			return {
				...state,
				userProfile: action.userProfile
			}
		default:
			return state
	}
}
