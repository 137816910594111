/* eslint-disable no-use-before-define */
/**
 * This work is licensed under the Creative Commons Attribution 3.0 United States License.
 * To view a copy of this license, visit
 * http://creativecommons.org/licenses/by/3.0/us/ or send a letter to
 * Creative Commons, 171 Second Street, Suite 300, San Francisco, California, 94105, USA.
 * Copyright 2010, Rob Blakemore
 * http://wiki.github.com/rdblakemore/JavaScript-Interface/
 */

/**
 * JavaScript class that facilitates coding against an interface.
 * @param {String} args.type Name or type of the interface.
 * @param {JSON Object} args.* Un-implemented functions that provide signatures for the interface.
 * @param {JSON Object} args.implementation Implementations of the interface functions.
 * @constructor
 */
/* exported Interface */
var Interface = function(args) {
	var type, signatureAndImp

	// set default value for args
	signatureAndImp = args || null
	// validate signature and implementation basic information
	validateInput(signatureAndImp)
	// set type
	type = signatureAndImp.type
	// compare interface function signatures with implementation
	return compareSignatureWithImplementation(
		extractSignatures(signatureAndImp),
		signatureAndImp.implementation
	)

	/**
	 * Validate essential argument data
	 * @param {Object}
	 */
	function validateInput(obj) {
		if (obj === null) {
			throw new Error('No arguments supplied to an instance of Interface constructor.')
		}
		if (_isUndefined(obj.type)) {
			throw new Error('Interface.type not defined.')
		}
		if (_isUndefined(obj.implementation)) {
			throw new Error('The interface '.concat(obj.type).concat(' has not been implemented.'))
		}
	}

	/**
	 * Extract signature definitions
	 * @param {Object} signatures with signature definitions
	 * @return object with signature definitions
	 */
	function extractSignatures(signatures) {
		var signatureValue, aux

		aux = {}
		for (var signatureName in signatures) {
			if (signatures.hasOwnProperty(signatureName)) {
				signatureValue = signatures[signatureName]
				if (_isFunction(signatureValue)) {
					aux[signatureName] = signatureValue
				}
			}
		}
		return aux
	}

	/**
	 * Compare Signatures with implememntations to make sure they match
	 * @param {Object} signatures
	 * @param {Object} implementations
	 * @return Implemented functions
	 */
	function compareSignatureWithImplementation(signatures, implementations) {
		var implementationValue, implementedFuncs

		implementedFuncs = {}
		for (var signatureName in signatures) {
			if (signatures.hasOwnProperty(signatureName)) {
				// check if function is not mplemented
				if (_isUndefined(implementations[signatureName])) {
					throw new Error(
						type
							.concat('.')
							.concat(signatureName)
							.concat(' has not been implemented.')
					)
				}
			}
		}
		for (var implementationName in implementations) {
			if (implementations.hasOwnProperty(implementationName)) {
				implementationValue = implementations[implementationName]
				// implemented function was not defined by interface
				if (_isUndefined(signatures[implementationName])) {
					throw new Error(
						implementationName
							.concat(' is not a defined member of ')
							.concat(type)
							.concat('.')
					)
				}
				// implementation is not a function
				if (!_isFunction(implementationValue)) {
					throw new Error(
						type
							.concat('.')
							.concat(implementationName)
							.concat(' has not been implemented as a function.')
					)
				}
				// implementation has wrong number of parameters
				if (_hasInvalidArgumentCount(signatures[implementationName], implementationValue)) {
					throw new Error(
						'An implementation of '
							.concat(type)
							.concat('.')
							.concat(implementationName)
							.concat(' does not have the correct number of arguments.')
					)
				}
				// implement
				implementedFuncs[implementationName] = implementationValue
			}
		}
		return implementedFuncs
	}

	/**
	 * Determines if an object is undefined.
	 * @param {Object} obj The object to be checked.
	 * @return Whether or not the provided object is undefined.
	 */
	function _isUndefined(obj) {
		return typeof obj === 'undefined'
	}

	/**
	 * Determines if an object is a function.
	 * @param {Object} obj The object to be checked.
	 * @return Whether or not the provided object is a function.
	 */
	function _isFunction(obj) {
		return typeof obj === 'function'
	}

	/**
	 * Compares the argument count of two functions.
	 * @return Whether or not the number of parameters of two functions are equal
	 * @param {Function} signature The function representing the signature of the to-be-implemented function.
	 * @param {Function} implementation The function that will provide the implementation of the to-be-implemented function.
	 */
	function _hasInvalidArgumentCount(signature, implementation) {
		if (!_isFunction(signature)) {
			throw new Error('"signature" parameter should be of type "function"')
		}
		if (!_isFunction(implementation)) {
			throw new Error('"implementation" parameter should be of type "function"')
		}
		return signature.length !== implementation.length
	}
}
/***********************
 B R I D G E   C O R E
************************/
/* exported Bridge */
const Bridge = (function(ceomNonce) {
	var // Constants
		Constants,
		Configs,
		// vars
		isNativeReady,
		ceoNative,
		uAgent,
		concreteBridge,
		user,
		bridgeSrc,
		// error vars
		bridgeLogs = []

	// Init Global Constants
	Constants = {
		BRIDGE_VER: '3.0.0',
		Regexp: {
			ANDROID: /android/,
			IOS: /ip(hone|ad|od)/
		},
		Container: {
			ANDROID_NATIVE_APP: 'androidApp',
			IOS_NATIVE_APP: 'iOSApp',
			BROWSER: 'browser'
		},
		Execute: {
			Action: {
				FACE_VOICE_ENROLL: 'FaceVoiceEnroll',
				BIOMETRICS_SIGN_ON_OPTS: 'GoToSignonOptions',
				SOFT_TOKEN_ENROLL: 'softTokenEnroll',
				SOFT_TOKEN_GENERATE: 'softTokenGenerate'
			}
		},
		CapturePhoto: {
			CaptureType: {
				RECEIPT: 'receipt',
				CHECK_FRONT: 'check_front',
				CHECK_BACK: 'check_back',
				CHECK_BOTH: 'check_both'
			},
			SourceType: {
				CAMERA: 'camera',
				GALLERY: 'gallery',
				BOTH: 'both'
			}
		},
		Users: {
			CEO_MOBILE: 'CEO_MOBILE'
		}
	}

	// Init Configurations
	Configs = {
		Debug: {
			FORCE_NATIVE_TRUE: false,
			SHOW_lOGS: false,
			SAVE_LOGS: true,
			THROW_ERROR: false
		},
		ENV: '',
		errorLogURL: ''
	}

	/**
	 * Constructor like function
	 */
	;(function() {
		_init()
	})()

	return {
		getInstance: getInstance,
		nativeReady: nativeReady,
		isNative: isNative,
		getBridgeLogs: getBridgeLogs,
		setBridgeConfig: setBridgeConfig,
		Constants: {
			BRIDGE_VER: Constants.BRIDGE_VER,
			Execute: Constants.Execute,
			CapturePhoto: Constants.CapturePhoto,
			Users: Constants.Users
		},
		nativeBack: nativeBack
	}

	function setBridgeConfig(arg) {
		if (!arg && typeof arg !== 'object') return false
		var setShowLogs = arg.hasOwnProperty('showLogs') ? arg.showLogs : Configs.Debug.SHOW_lOGS,
			setEnv = arg.hasOwnProperty('env') ? arg.env : Configs.ENV,
			setThrowError = arg.hasOwnProperty('throwError') ? arg.throwError : Configs.Debug.THROW_ERROR
		if (typeof setShowLogs === 'boolean') {
			Configs.Debug.SHOW_lOGS = setShowLogs
		}

		if (typeof setEnv === 'string') {
			Configs.ENV = arg.env
			Configs.errorLogURL =
				'https://ceomobile' +
				Configs.ENV +
				'.wellsfargo.com/ceom/features/ReportJSBridgeError.action'
		}

		if (typeof setThrowError === 'boolean') {
			Configs.Debug.THROW_ERROR = setThrowError
		}
	}

	/**
		util method addevent
	*/
	function addEvent(elem, evnt, func) {
		if (elem.addEventListener) {
			elem.addEventListener(evnt, func, false)
		} else if (elem.attachEvent) {
			elem.attachEvent('on' + evnt, func)
		}
	}

	/**
	 * Initialization code
	 */
	function _init() {
		addEvent(window, 'error', globalErrorHandler)
		detectBridgeEnv()
		isNativeReady = false
		ceoNative = null
		uAgent = navigator.userAgent.toLowerCase()
		concreteBridge = null
	}

	/**
	 * Function invoked when Native App is done loading
	 */
	function nativeReady() {
		isNativeReady = true
		_setCeoNative()
	}

	/**
	 * Set Native JSObject in a var
	 */
	function _setCeoNative() {
		ceoNative = window.CeoNative || null
	}

	/**
	 * Function invoked when Native App back (hardware back button or otherwise) is pressed
	 */
	function nativeBack() {
		_logBridge('nativeBack() - Native back button pressed!')
		document.dispatchEvent(new Event('ceoNativeBackPressed'))
	}

	/**
	 * CUSTOM CONSOLE LOG FOR BRIDGE ACTIVITY
	 * @param {String} msg: Message to log.
	 * @return
	 */
	function _logBridge(msg) {
		if (Configs.Debug.SHOW_lOGS) {
			console.log('[BRIDGEv' + Constants.BRIDGE_VER + ']: ' + msg)
		}
		if (Configs.Debug.SAVE_LOGS) {
			bridgeLogs.push(msg)
		}
	}

	/**
	 * @param {String} msg: Message to log and throw error.
	 * @return
	 */
	function _logBridgeError(msg) {
		_logBridge(msg)

		if (Configs.Debug.THROW_ERROR) {
			bridgeLogs.push('BRIDGEERRORKEYWORD')
			throw new Error(msg)
		} else {
			bridgeLogs.push('BRIDGEERRORKEYWORD')
			globalErrorHandler()
			return false
		}
	}

	/**
	 * @return bridgeLogs
	 */
	function getBridgeLogs() {
		return String(bridgeLogs)
	}

	/**
	 * Global Error Handling Function
	 * @param {object} error
	 */
	function globalErrorHandler(error) {
		var errorInfo, xhr

		//Retrun if error does not come from the bridge
		if (!/'BRIDGEERRORKEYWORD'/g.test(bridgeLogs.pop())) {
			return
		}

		errorInfo = {
			version: Constants.BRIDGE_VER,
			user: user,
			uAgent: uAgent,
			href: window.location.href,
			bridgeLogs: bridgeLogs,
			bridgeSrc: bridgeSrc
		}

		document.dispatchEvent(
			new CustomEvent('BRIDGE_ERROR', { detail: { errorEvent: error, errorInfo: errorInfo } })
		)
		xhr = new XMLHttpRequest()
		xhr.open('POST', Configs.errorLogURL, true)

		// Send the proper header information along with the request
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')

		// Call a function when the state changes.
		xhr.onreadystatechange = function() {
			if (xhr.readyState === 4 && xhr.status === 200) {
				console.log('Error Logged')
			}
		}
		xhr.send('errorInfo=' + JSON.stringify(errorInfo))
	}

	/**
	 * Figure out the Log Service Endpoint
	 */
	function detectBridgeEnv() {
		var scriptsTags = document.getElementsByTagName('script'),
			env,
			bridgeSrc =
				scriptsTags[scriptsTags.length - 1] !== undefined
					? scriptsTags[scriptsTags.length - 1].src
					: ''
		if (!/ceo-mobile.bridge/g.test(bridgeSrc)) {
			Configs.errorLogURL =
				'https://ceomobile' +
				Configs.ENV +
				'.wellsfargo.com/ceom/features/ReportJSBridgeError.action'
			return
		}

		bridgeSrc = bridgeSrc.split('.')[0].split('//')[1]
		env = bridgeSrc.replace('ceomedia', '')
		Configs.ENV = env
		Configs.errorLogURL =
			'https://ceomobile' + Configs.ENV + '.wellsfargo.com/ceom/features/ReportJSBridgeError.action'
	}

	/**
	 * _isIphone Boolean
	 * @return
	 */
	function _isIOS() {
		return Constants.Regexp.IOS.test(uAgent)
	}

	/**
	 * _isAndroid Boolean
	 * @return
	 */
	function _isAndroid() {
		return Constants.Regexp.ANDROID.test(uAgent)
	}

	/**
	 * Generates JSON payload required for operations
	 * @param  {Object} args
	 * @return {Object} payload
	 */
	function _generatePayload(args) {
		_logBridge('_generatePayload call started')
		var payload, input, action, callbackName, data, noPayload, callMethod

		payload = {}

		input = args || {}
		action = input.action || null
		callbackName = input.callbackName || null
		data = input.data || null
		noPayload = input.noPayload || false
		callMethod = input.callMethod || null

		// action
		if (action && typeof action !== undefined && action !== null) {
			payload.action = action
		}
		// nonce
		if (window.ceomNonce && typeof window.ceomNonce !== undefined && window.ceomNonce !== null) {
			payload.nonce = window.ceomNonce
		}
		// Callback
		if (callbackName && typeof callbackName !== undefined && callbackName !== null) {
			payload.resultCallback = callbackName
		}
		// Data
		if (data && typeof data !== undefined && data !== null) {
			payload.data = data
		}

		// Call method
		if (callMethod && typeof callMethod !== undefined && callMethod !== null) {
			payload.callMethod = callMethod
		}

		// noPayload
		if (noPayload && typeof noPayload !== undefined && noPayload !== null) {
			payload.noPayload = noPayload
		}

		return JSON.stringify(payload)
	}

	/**
	 * check if certain value is present in an object property
	 */
	function _isValueInObject(obj, value) {
		var isPresent = false

		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				if (obj[prop] === value) {
					isPresent = true
					break
				}
			}
		}
		return isPresent
	}

	/**
	 * Try to guess the web application container based on the X-Application HTTP response header value
	 */
	function _guessContainer() {
		var container

		_setCeoNative()

		// Force Native Identification based on debug config
		if (Configs.Debug.FORCE_NATIVE_TRUE) {
			container = Constants.Container.IOS_NATIVE_APP
			_logBridge('Bridge._guessContainer(): Forced detection of iOS Native App' + container)
			return container
		}

		if (isNativeReady || ceoNative) {
			if (_isAndroid()) {
				container = Constants.Container.ANDROID_NATIVE_APP
			} else if (_isIOS()) {
				container = Constants.Container.IOS_NATIVE_APP
			} else {
				_logBridgeError(
					"Bridge._guessContainer(): Received nativeReady() call or CeoNative object is available, but User Agent doesn't match neither Android nor iOS."
				)
			}
		} else {
			container = _guessIOSAlternateMethod()
		}
		_logBridge('Bridge._guessContainer(): Identified container as: ' + container)
		return container

		/**
		 * Alternate web application wrapper detection method based on the user Agent
		 */
		function _guessIOSAlternateMethod() {
			var standalone = window.navigator.standalone,
				safari = /safari/.test(uAgent)

			_logBridge(
				'Bridge._guessContainer()._guessIOSAlternateMethod(): Using alternate method to identify ios native wrapper'
			)
			if (_isIOS() && !standalone && !safari) {
				return Constants.Container.IOS_NATIVE_APP
			}
			return Constants.Container.BROWSER
		}
	}

	/**
	 * isNative exposes a boolean for confirming whether is Native app or not
	 */
	function isNative() {
		var cnt = _guessContainer(),
			result =
				cnt === Constants.Container.ANDROID_NATIVE_APP || cnt === Constants.Container.IOS_NATIVE_APP
		_logBridge(
			'Bridge.isNative(): Function has evaluated ' + result + ' and the container is: ' + cnt
		)
		return result
	}

	/**
	 * Bridge Factory
	 */
	function BridgeFactory() {
		this.createBridge = function(container) {
			var ConcreteBridgeClass

			switch (container) {
				case Constants.Container.ANDROID_NATIVE_APP:
				case Constants.Container.IOS_NATIVE_APP:
					ConcreteBridgeClass = BridgeIOSAndroid
					_logBridge('Bridge.BridgeFactory(): Creating Android/IOS Concrete Bridge object')
					break
				case Constants.Container.BROWSER:
					ConcreteBridgeClass = BridgeBrowser
					_logBridge('Bridge.BridgeFactory(): Creating Browser Concrete Bridge object')
					break
				default:
					ConcreteBridgeClass = null
					break
			}

			if (ConcreteBridgeClass !== null) {
				return new ConcreteBridgeClass()
			} else {
				return null
			}
		}
	}

	/**
	 * Get corresponding bridge instance
	 * @param {String} user
	 * @return concreteBridge
	 */
	function getInstance(usr, setConfig) {
		var bridgeFactory
		bridgeLogs = []
		_logBridge('Current Bridge Version : ' + Constants.BRIDGE_VER)
		setBridgeConfig(setConfig)

		if (concreteBridge === null) {
			if (!usr) {
				_logBridgeError('User identification is required to use the bridge')
			}
			/*if (!_isValueInObject(Constants.Users, usr.toUpperCase())) {
				throw new Error('User identification is present but is not one of the predefined values');
			}*/

			//Store User Name in global module var
			user = usr

			bridgeFactory = new BridgeFactory()
			concreteBridge = bridgeFactory.createBridge(_guessContainer())
		}
		_logBridge('Bridge.getInstance(): bridge instance created for application - ' + usr)
		return concreteBridge
	}

	/**
	 * Call relevant Native app method
	 * @param {Object} payload
	 */
	function _callNative(jsonPayload) {
		var payload = JSON.parse(jsonPayload),
			payloadAction = false,
			nativeFn

		if (payload) {
			payloadAction = payload.callMethod ? payload.callMethod : payload.action
		}

		//validate callback function exists (if passed)
		if (payload.callbackName && !_validateCallbackFn(payload)) {
			_logBridgeError('_callNative(): Not able to locate callback fn hence not calling native app.')
			return
		}

		if (payloadAction) {
			_logBridge('_callNative() - ' + payloadAction + ' api payload : ' + jsonPayload)
			if (ceoNative && typeof ceoNative[payloadAction] === 'function') {
				_logBridge('_callNative(Android) - ' + payloadAction)

				nativeFn = ceoNative[payloadAction].bind(ceoNative)

				if (!payload.noPayload) {
					nativeFn(jsonPayload)
				} else {
					_logBridge('_callNative(Android) - no payload')
					nativeFn()
				}
				return
			}

			_logBridge('_callNative(iOS) - ' + payloadAction)
			try {
				window.webkit.messageHandlers.CeoNative.postMessage(jsonPayload)
			} catch (e) {
				_logBridgeError(
					'_callNative(): window.webkit.messageHandlers.CeoNative.postMessage is unavailable'
				)
			}
		} else {
			_logBridgeError('_callNative(): payload or action is not present')
		}
	}

	function _validateCallbackFn(data) {
		var cbName, cbArr, i, len, fnName
		cbName = data && data.callbackName
		if (!cbName) {
			return false
		}

		cbArr = data.callbackName.split('.')
		for (i = 0, len = cbArr.length, fnName = window; i < len; ++i) {
			fnName = fnName[cbArr[i]]
		}
		if (typeof fnName === 'function') {
			return fnName
		} else {
			return false
		}
	}

	/****************************************
	 B R I D G E   I N T E R F A C E
	*****************************************/
	function IBridge(implementation) {
		/* global Interface */
		return new Interface({
			/**
			 * Ask the native application to return the token stored on the native device
			 * @param {Object} keys
			 * @param {String} callbackName
			 * @return {Object}
			 */
			getToken: function(data) {},
			/**
			 * Ask the native application open an URL in default browser
			 * @param {String} theURL
			 * @return
			 */
			openUrlInBrowser: function(theURL) {},
			/**
			 * Ask the native application to retrieve device hardware/software related info
			 * @return {Object}
			 */
			getDeviceInfo: function(data) {},
			/**
			 * Ask the native application if a specific feature is available
			 * @return {Object}
			 */
			availableFeatures: function(data) {},
			/**
			 * Execute a specific Native action
			 * @param {string-enum}
			 */
			execute: function(action, callback) {},
			/**
			 * Open new window
			 * @return null
			 */
			openNewWindow: function() {},
			/**
			 * With Native limitation on the number of windows that can be opened at the same time
			 * this operation provides with the number of windows left to open
			 * @return number of available windows to open (max - opened)
			 */
			availableNewWindows: function(data) {},
			/**
			 * Prints content of current window
			 * @return null
			 */
			print: function() {},
			/**
			 * Invoke Native application photo capture.
			 * @param {Object} with the following parameters:
			 * - @param {enumeration} captureType - Type of capture (receipt, check_front, check_back, check_both)
			 * - @param {enumeration} [sourceType=camera] - Type of source (camera, gallery, both)
			 * - @param {boolean} [multipleCapture=false] - Instructs native to capture multiple photos.
			 * - @param {boolean} [extraction=false] - Instructs Kofax to perform data extraction for the given image and get it back in the argument response.
			 * - @param {boolean} [multiplePage=false] - In RECEIPT scenario, this setting instructs to add all captures to same receipt ID and to treat the as pages.
			 * - @param {boolean} [showPreview=false] - Shows a capture verify page to user.
			 * - @param {int} [maxCaptures=1] - Max number of captures to perform on current operation call. When multiple param is set to true it is reasonable to set this value > 1.
			 * - @param {string} [maxCapturesExceededMsg=''] - Front facing message to display to the user whenever the maximum captures allowed is exceeded
			 * - @param {string} callback - Callback function name (string) to be called when capturing is completed on the native side.
			 */
			capturePhoto: function(opts) {},
			/**
			 * isSoftTokenEnabled a specific Native call to check user has softoken Enabled or not.
			 *  @param {obj} dataObj
			 */
			isSoftTokenEnabled: function(dataObj) {},
			/**
			 * getSoftToken is specific native call to get softToken from native.
			 *  @param {obj} dataObj
			 */
			getSoftToken: function(dataObj) {},
			/**
			 * enableNativeBack is specific native call to enable/disable native back button.
			 *  @param boolean data
			 */
			enableNativeBack: function(data) {},
			/**
			 * showNativeBack is specific native call to show/hide native back button.
			 *  @param boolean data
			 */
			showNativeBack: function(data) {},

			implementation: implementation,
			type: 'IBridge'
		})
	}

	/***************************************************
	 A N D R O I D   &   I O S 7 +   I N T E R F A C E
	****************************************************/
	function BridgeIOSAndroid() {
		return new IBridge({
			getToken: function(data) {
				_logBridge('getToken call started : payload Received : data' + data.keys)
				var jsonPayload = _generatePayload({
					action: 'getToken',
					data: data.keys,
					callbackName: data.callbackName
				})
				_callNative(jsonPayload)
			},

			openUrlInBrowser: function(theURL) {
				_logBridge('openUrlInBrowser call started : payload Received : data' + theURL)
				var jsonPayload = _generatePayload({
					action: 'openUrlInBrowser',
					data: [{ url: theURL }]
				})
				_callNative(jsonPayload)
			},

			getDeviceInfo: function(data) {
				_logBridge('getDeviceInfo call started')
				var jsonPayload = _generatePayload({
					action: 'getDeviceInfo',
					callbackName: data && data.callbackName
				})
				_callNative(jsonPayload)
			},

			availableFeatures: function(data) {
				_logBridge('availableFeatures call started : No payload Needed')
				var jsonPayload = _generatePayload({
					action: 'availableFeatures',
					callbackName: data && data.callbackName
				})
				_callNative(jsonPayload)
			},

			execute: function(action, callBack) {
				_logBridge(
					'execute call started : payload Received : action name : ' +
						action +
						'Optional Callback : ' +
						callBack
				)
				_validateInput()
				var jsonPayload = _generatePayload({
					action: action,
					callbackName: callBack || '',
					callMethod: 'execute'
				})
				_callNative(jsonPayload)

				function _validateInput() {
					if (!action) {
						_logBridgeError('execute: action parameter is required')
					} else if (typeof action !== 'string') {
						_logBridgeError('execute: action parameter must be a string value')
					}

					if (typeof callBack !== 'undefined') {
						if (typeof callBack !== 'string') {
							_logBridgeError(
								'execute: callBack parameter must be a string value representing one of the native callback'
							)
						}
					}
				}
			},

			openNewWindow: function() {
				var url = arguments[0]
				_logBridge('openNewWindow call started : payload Received : ' + url)

				var jsonPayload = _generatePayload({
					action: 'openNewWindow',
					data: [{ url: url }]
				})
				_callNative(jsonPayload)
			},

			availableNewWindows: function(data) {
				_logBridge('openNewWindow call started : No payload Needed')
				var jsonPayload = _generatePayload({
					action: 'availableNewWindows',
					callbackName: data && data.callbackName
				})
				_callNative(jsonPayload)
			},

			capturePhoto: function(opts) {
				_logBridge('capturePhoto call started : payload Received : ' + opts)
				var jsonPayload

					/*
					 * Main Function
					 */
				;(function() {
					_setCeoNative()
					// Validate if Native Function Exists
					_validateInput(opts)

					var sourceType = opts.sourceType || Constants.CapturePhoto.SourceType.BOTH,
						multipleCapture = opts.multipleCapture || false,
						extraction = opts.extraction || false,
						multiplePage = opts.multiplePage || false,
						showPreview = opts.showPreview || false,
						maxCaptures = opts.maxCaptures || 1,
						maxCapturesExceededMsg = opts.maxCapturesExceededMsg || ''

					jsonPayload = _generatePayload({
						action: 'capturePhoto',
						nonce: window.ceomNonce,
						callbackName: opts.callbackName,
						user: user,
						data: {
							captureType: opts.captureType,
							sourceType: sourceType,
							multipleCapture: multipleCapture,
							extraction: extraction,
							multiplePage: multiplePage,
							showPreview: showPreview,
							maxCaptures: maxCaptures,
							maxCapturesExceededMsg: maxCapturesExceededMsg
						}
					})
					_callNative(jsonPayload)
				})()

				/**
				 * Validate parameters
				 */
				function _validateInput(o) {
					var parts

					// Validate captureType
					if (!o.captureType) {
						_logBridgeError('captureType parameter should be specified')
					} else if (typeof o.captureType !== 'string') {
						_logBridgeError('captureType parameter should be a string')
					} else if (!_isValueInObject(Constants.CapturePhoto.CaptureType, o.captureType)) {
						_logBridgeError('unexpected captureType parameter value. Received: ' + o.captureType)
					}

					// Validate sourceType
					if (o.sourceType) {
						if (typeof o.sourceType !== 'string') {
							_logBridgeError('sourceType parameter should be a string. Received: ' + o.sourceType)
						} else if (!_isValueInObject(Constants.CapturePhoto.SourceType, o.sourceType)) {
							_logBridgeError('unexpected sourceType parameter value. Received: ' + o.sourceType)
						}
					}

					// Validate multipleCapture
					if (o.multipleCapture) {
						if (typeof o.multipleCapture !== 'boolean') {
							_logBridgeError(
								'multipleCapture parameter should be a boolean. Received: ' + o.multipleCapture
							)
						}
					}

					// Validate extraction
					if (o.extraction) {
						if (typeof o.extraction !== 'boolean') {
							_logBridgeError('extraction parameter should be a boolean. Received: ' + o.extraction)
						}
					}

					// Validate multiplePage
					if (o.multiplePage) {
						if (typeof o.multiplePage !== 'boolean') {
							_logBridgeError(
								'multiplePage parameter should be a boolean. Received: ' + o.multiplePage
							)
						}
					}

					// Validate showPreview
					if (o.showPreview) {
						if (typeof o.showPreview !== 'boolean') {
							_logBridgeError(
								'showPreview parameter should be a boolean. Received: ' + o.showPreview
							)
						}
					}

					// Validate maxCaptures
					if (o.maxCaptures) {
						if (isNaN(o.maxCaptures)) {
							_logBridgeError(
								'maxCaptures parameter should be a number. Received: ' + o.maxCaptures
							)
						}
					}

					// Validate maxCapturesExceededMsg
					if (o.maxCapturesExceededMsg) {
						if (typeof o.maxCapturesExceededMsg !== 'string') {
							_logBridgeError(
								'maxCapturesExceededMsg parameter should be a string. Received: ' +
									o.maxCapturesExceededMsg
							)
						}
					}

					// Validate callback
					if (typeof o.callbackName !== 'string') {
						_logBridgeError(
							'callbackName parameter must be a string representing a function name and it is required'
						)
					} else {
						parts = o.callbackName.split('.')
						for (var i = 0, len = parts.length, obj = window; i < len; ++i) {
							obj = obj[parts[i]]
						}
						if (typeof obj !== 'function') {
							_logBridgeError("callbackName parameter function name doesn't exist")
						}
					}
				}
			},

			print: function() {
				_logBridge('print call started : No payload Needed')
				var jsonPayload = _generatePayload({
					action: 'printCurrentWindow',
					noPayload: true
				})
				_callNative(jsonPayload)
			},

			isSoftTokenEnabled: function(dataObj) {
				_logBridge('isSoftTokenEnabled call started : payload Received : ' + dataObj)
				_validateInput(dataObj)

				var jsonPayload = _generatePayload({
					action: 'isSoftTokenEnabled',
					callbackName: dataObj.callBack,
					data: dataObj.userId
				})
				_callNative(jsonPayload)

				function _validateInput(dataObj) {
					if (!dataObj.userId) {
						_logBridgeError('userId parameter should be specified')
					} else if (typeof dataObj.userId !== 'string') {
						_logBridgeError('userId parameter should be a string')
					}

					if (!dataObj.callBack) {
						_logBridgeError('callBack parameter should be specified')
					} else if (typeof dataObj.callBack !== 'string') {
						_logBridgeError('callBack parameter should be a string')
					}
				}
			},

			getSoftToken: function(dataObj) {
				_logBridge('getSoftToken call started : payload Received : ' + dataObj)
				_validateInput(dataObj)
				var jsonPayload = _generatePayload({
					action: 'getSoftToken',
					callbackName: dataObj.callBack
				})

				_callNative(jsonPayload)

				function _validateInput(dataObj) {
					if (!dataObj.callBack) {
						_logBridgeError('callBack parameter should be specified')
					} else if (typeof dataObj.callBack !== 'string') {
						_logBridgeError('callBack parameter should be a string')
					}
				}
			},

			enableNativeBack: function(data) {
				_logBridge('enableNativeBack call started : payload Received : ' + data)
				var jsonPayload = _generatePayload({
					action: 'enableNativeBack',
					data: [{ enable: data }]
				})

				_callNative(jsonPayload)
			},

			showNativeBack: function(data) {
				_logBridge('showNativeBack call started : payload Received : ' + data)
				var jsonPayload = _generatePayload({
					action: 'showNativeBack',
					data: [{ show: data }]
				})

				_callNative(jsonPayload)
			}
		})
	}

	/****************************************
	 B R O W S E R   I N T E R F A C E
	*****************************************/
	function BridgeBrowser() {
		return new IBridge({
			getToken: function(keys) {
				_logBridge('Bridge.BridgeBrowser.getToken(): getToken not available from Browser.')
				return false
			},

			openUrlInBrowser: function(theURL) {
				_logBridge(
					'Bridge.BridgeBrowser.openUrlInBrowser() call started : payload Received : ' + theURL
				)
				return window.open(theURL, '_blank')
			},

			getDeviceInfo: function(data) {
				_logBridge(
					'Bridge.BridgeBrowser.getDeviceInfo(): getDeviceInfo not available from Browser.'
				)
				return false
			},

			availableFeatures: function(data) {
				_logBridge(
					'Bridge.BridgeBrowser.availableFeatures(): availableFeatures not available from Browser.'
				)
				return false
			},

			execute: function(action, callback) {
				_logBridge('Bridge.BridgeBrowser.execute(): execute not available from Browser.')
			},

			openNewWindow: function() {
				var url, windowName, features

				url = arguments[0]
				windowName = arguments[1]
				features = arguments[2]
				_logBridge(
					'Bridge.BridgeBrowser.openNewWindow() call started : payload Received : ' +
						'url : ' +
						url +
						'windowName : ' +
						windowName +
						'features : ' +
						features
				)
				return window.open(url, windowName, features)
			},

			availableNewWindows: function(data) {
				// There is no a web application limitation on how many windows can be opened in the browser.
				// returning 99.
				var callbackFn
				_logBridge('Bridge.BridgeBrowser.availableNewWindows(data) call started')
				callbackFn = _validateCallbackFn(data)
				if (callbackFn) {
					callbackFn(99)
				} else {
					_logBridgeError(
						'Bridge.BridgeBrowser.availableNewWindows: Not able to locate callback function.'
					)
				}
			},

			capturePhoto: function(opts) {
				_logBridge('capturePhoto not available from Browser.')
				return false
			},

			print: function() {
				_logBridge('Bridge.BridgeBrowser.print() call started')
				window.print()
			},

			isSoftTokenEnabled: function(dataObj) {
				_logBridge(
					'Bridge.BridgeBrowser.isSoftTokenEnabled(): isSoftTokenEnabled not available from Browser.'
				)
			},

			getSoftToken: function(dataObj) {
				_logBridge('Bridge.BridgeBrowser.getSoftToken(): getSoftToken not available from Browser.')
			},

			enableNativeBack: function(data) {
				_logBridge(
					'Bridge.BridgeBrowser.enableNativeBack(): enableNativeBack not available from Browser.'
				)
			},

			showNativeBack: function(data) {
				_logBridge(
					'Bridge.BridgeBrowser.showNativeBack(): showNativeBack not available from Browser.'
				)
			}
		})
	}
})(window.ceomNonce || '')

export function initializeNativeBridgeScript() {
	if (!window.Bridge) {
		window.Bridge = Bridge
	}
}
