import React from 'react'
import {
	IconConfirmation,
	IconWarning,
	IconError,
	IconInformationalLight,
	IconInformationalDark
} from '@wf/ria-icons'

export function getSystemMessageIconData(type, size = 'medium') {
	switch (type) {
		case 'confirmation':
			return {
				iconComponent: <IconConfirmation size={size} />,
				theme: 'confirmation'
			}
		case 'error':
			return {
				iconComponent: <IconError size={size} />,
				theme: 'error'
			}
		case 'alert':
			return {
				iconComponent: <IconError size={size} />,
				theme: 'error'
			}
		case 'informational':
			return {
				iconComponent: <IconInformationalLight size={size} />,
				theme: 'informational'
			}
		case 'warning':
			return {
				iconComponent: <IconWarning size={size} />,
				theme: 'warning'
			}
		case 'transparent':
			return {
				iconComponent: <IconInformationalDark size={size} />,
				theme: 'transparent'
			}
		case 'utility':
		default:
			return {
				iconComponent: <IconInformationalDark size={size} />,
				theme: 'utility'
			}
	}
}
