import React, { useEffect, useContext, useRef, useState } from 'react'
import {
	BasicLabel,
	BrowserNavigationContext,
	BasicNoContent,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { MenuButton, MenuItem, MenuItemGroup } from '@wf/ria'
import CommitmentDetailsTabs from '../containers/CommitmentDetailsTabs'
import { CommitmentDetailsOverview } from './CommitmentDetailsOverview'
import PayDownsAndAdvancesTable from '../containers/PayDownsAndAdvancesTable'
import CustomSystemMessage from '../containers/CustomSystemMessage'
import { CustomWaitMessage } from './CustomWaitMessage'
import get from 'lodash.get'
import { useIntl } from 'react-intl'

export function useProjectDocumentTitle() {
	const { formatMessage } = useIntl()
	return formatMessage({ id: 'credit-management-commitment-details-page.tab.title' })
}

export function ViewCommitmentDetails({
	intl,
	commitments = [],
	dropdownValue,
	onGetSelectedCommitmentDetails,
	onSetDropdownValue,
	facilityKey,
	commitmentDetails = {},
	onLoadCommitmentDetailsPage,
	showLoading,
	onUpdateSystemMessage,
	tabs,
	onGetCommitmentTableData,
	onGetCommitmentDetails
}) {
	const { formatMessage } = intl
	const { location, setPath } = useContext(BrowserNavigationContext)
	const [defaultTab, setDefaultTab] = useState('')
	const viewCommitmentDetailsPageRef = useRef(null)
	const { clearMessages } = useContext(SystemMessagesContext)

	useEffect(() => {
		if (location.state !== undefined) {
			const commitmentEncryptedKey = location?.state?.params?.rowData?.item?.encryptedKey || ''
			const tab = get(location, 'state.params.tabName', '')
			setDefaultTab(tab)
			onLoadCommitmentDetailsPage(commitmentEncryptedKey, tab)
		} else {
			setPath({ path: '/home' })
		}
	}, [onLoadCommitmentDetailsPage, location, setPath])

	function updateCommitment(value) {
		clearMessages()
		onUpdateSystemMessage([])
		setDefaultTab('')
		onGetSelectedCommitmentDetails(value, '')
	}

	const loadingLabel = formatMessage({
		id: 'loan-billing.statements-page.label.loading'
	})

	document.title = useProjectDocumentTitle()

	return (
		<div className="ViewCommitmentDetailsPage" ref={viewCommitmentDetailsPageRef}>
			<CustomWaitMessage
				isLoading={showLoading}
				loadingMessage={loadingLabel}
				containerNode={viewCommitmentDetailsPageRef}
			/>
			<BasicLabel
				label={formatMessage({
					id: 'view-commitment-details.label.commitment-details'
				})}
			/>
			<CustomSystemMessage />
			<CommitmentMenu
				formatMessage={formatMessage}
				commitments={commitments}
				facilityKey={facilityKey}
				updateCommitment={updateCommitment}
			/>
			<CommitmentDetailsOverview
				formatMessage={formatMessage}
				commitmentDetails={commitmentDetails}
			/>
			<CommitmentDetailsTaskMenu
				formatMessage={formatMessage}
				facilityKey={facilityKey}
				defaultTab={defaultTab}
				setDefaultTab={setDefaultTab}
				dropdownValue={dropdownValue}
				onSetDropdownValue={onSetDropdownValue}
				commitmentDetails={commitmentDetails}
				tabs={tabs}
				clearMessages={clearMessages}
				onUpdateSystemMessage={onUpdateSystemMessage}
				onGetCommitmentTableData={onGetCommitmentTableData}
				onGetCommitmentDetails={onGetCommitmentDetails}
			/>
		</div>
	)
}

function CommitmentMenu({ commitments, facilityKey, updateCommitment, formatMessage }) {
	const menuItems = commitments.map(commitment => {
		return (
			<MenuItemGroup label={commitment.groupName}>
				{commitment.facilityGroup.map(group => {
					return (
						<MenuItem className="MenuItemLabel" key={group.encryptedKey} value={group.encryptedKey}>
							{group.facilityName}
						</MenuItem>
					)
				})}
			</MenuItemGroup>
		)
	})

	function getFacilityName() {
		if (commitments.length > 0) {
			return commitments
				.flatMap(commitment => commitment.facilityGroup)
				.find(facility => facility.encryptedKey === facilityKey)?.facilityName
		}
	}
	if (
		commitments.length === 0 ||
		(commitments.length === 1 && commitments[0]?.facilityGroup.length === 1)
	) {
		return <BasicNoContent />
	}

	return (
		<div className="wf-col--9 CommitmentMenu">
			<BasicLabel
				className="wf-row"
				level={0}
				label={formatMessage({
					id: 'view-commitment-details.label.commitment'
				})}
			/>
			<MenuButton
				label={getFacilityName()}
				kind="standard"
				defaultSelectedValue={facilityKey}
				onSelectedValueChange={updateCommitment}
				className="wf-row wf-col--4 wf-col--mob-only-12 CommitmentMenuButton"
				updateLabelOnItemSelection={true}
			>
				{menuItems}
			</MenuButton>
		</div>
	)
}

function CommitmentDetailsTaskMenu({
	facilityKey,
	formatMessage,
	dropdownValue,
	onSetDropdownValue,
	commitmentDetails,
	tabs,
	defaultTab,
	setDefaultTab,
	clearMessages,
	onUpdateSystemMessage,
	onGetCommitmentTableData,
	onGetCommitmentDetails
}) {
	const taskList = [
		{
			key: 'viewObligations',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.label.view-obligations'
			})
		},
		{
			key: 'payDownPrincipal',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.label.pay-down-principal'
			})
		},
		{
			key: 'advanceFunds',
			label: formatMessage({
				id: 'loan-summary.view-commitment-details.label.advance-funds'
			})
		}
	]
	const menuItems = taskList.map(task => {
		return (
			<MenuItem className="MenuItemLabel" key={task.key} value={task.key}>
				{task.label}
			</MenuItem>
		)
	})

	function getTaskName() {
		if (taskList.length > 0) {
			const taskLabel = taskList.filter(task => task.key === dropdownValue)[0].label
			return taskLabel
		}
	}

	function updateSelectedTask(value) {
		clearMessages()
		onUpdateSystemMessage([])
		onSetDropdownValue(value)
		if (value === 'viewObligations') {
			onGetCommitmentTableData(tabs[0], facilityKey, { rowsOffset: 0 })
		} else {
			setDefaultTab('')
		}
	}

	return (
		<>
			<div className="wf-col--9 CommitmentTaskMenu">
				<BasicLabel
					className="wf-row"
					level={0}
					label={formatMessage({
						id: 'view-commitment-details.label.i-want-to'
					})}
				/>
				<MenuButton
					label={getTaskName()}
					kind="standard"
					selectedValue={dropdownValue}
					onSelectedValueChange={updateSelectedTask}
					className="wf-row wf-col--4 wf-col--mob-only-12 CommitmentMenuButton"
					updateLabelOnItemSelection={false}
				>
					{menuItems}
				</MenuButton>
			</div>
			<CommitmentDetailsTabs
				dropdownValue={dropdownValue}
				formatMessage={formatMessage}
				facilityKey={facilityKey}
				defaultTab={defaultTab}
				commitmentDetails={commitmentDetails}
			/>
			<PayDownsAndAdvancesTable
				formatMessage={formatMessage}
				dropdownValue={dropdownValue}
				facilityKey={facilityKey}
				commitmentDetails={commitmentDetails}
				onGetCommitmentDetails={onGetCommitmentDetails}
			/>
		</>
	)
}
