import { UPDATE_SYSTEM_MESSAGE } from '../actions/systemMessages'

export const initialState = {
	get systemMessages() {
		return []
	}
}

export function systemMessages(state = initialState, action) {
	switch (action.type) {
		case UPDATE_SYSTEM_MESSAGE:
			return {
				...state,
				systemMessages: action.systemMessages
			}
		default:
			return state
	}
}
