import React, { Fragment, useState, useEffect, useRef, useContext } from 'react'
import {
	BasicLabel,
	BasicTable,
	BasicSystemMessages,
	BasicNoContent,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { Button } from '@wf/ria'
import CustomPrintArea from './CustomPrintArea'
import { config } from '../../package.json'
import get from 'lodash.get'
import { CustomWaitMessage } from './CustomWaitMessage'
import { MakePaymentPanel } from './MakePaymentPanel'

const { apiContext } = config
// we need to coordinate with backend to determine how to toggle this
export const VIEW_SINGLE_CUSTOMER = 'AFS/VIEW_SINGLE_CUSTOMER'
export const VIEW_MULTI_CUSTOMER = 'AFS/VIEW_MULTI_CUSTOMER'

export function ViewLoanBillingStatementsPage({
	intl,
	ledger,
	billingStatements = {
		dataLink: {
			params: {
				paginationRequired: true,
				rowsBatchSize: 0,
				rowsCountOnServer: 0
			}
		}
	},
	onGetBillingStatements,
	onGetLedger,
	systemMessages = [],
	singleCustomerView,
	showLoading,
	onUpdateSystemMessage,
	onGetConfirmationViewData
}) {
	const { dataLink = {}, rows = [] } = billingStatements
	const { params = {} } = dataLink
	const { rowsBatchSize = 0, rowsCountOnServer = 0 } = params
	const { formatMessage } = intl
	const [rowData, setRowData] = useState(null)
	const [openPanel, setOpenPanel] = useState(false)
	const viewLoanBillingStatementsPageRef = useRef(null)
	const { clearMessages } = useContext(SystemMessagesContext)

	useEffect(() => {
		if (ledger) {
			onGetBillingStatements(ledger, { rowOffset: 0 })
		} else {
			onGetLedger()
		}
	}, [onGetBillingStatements, onGetLedger, ledger])

	const columns = [
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.invoice-date'
			}),
			name: 'invoiceDate',
			sort: true,
			collapse: 3
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.due-date'
			}),
			name: 'dueDate',
			sort: true,
			collapse: 4
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.invoice-number'
			}),
			tag: 'CustomInvoiceDownloadColumn',
			name: 'invoiceNumber',
			collapse: 8,
			sort: true
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.customer'
			}),
			tag: 'CustomCustomerNumberColumn',
			name: 'customer',
			sort: true,
			collapse: 2
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.principal'
			}),
			name: 'principal',
			align: 'right',
			sort: true,
			collapse: 1
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.interest-or-fees'
			}),
			name: 'interest',
			sort: true,
			collapse: 1,
			align: 'right'
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.late.charge'
			}),
			name: 'lateCharge',
			sort: true,
			collapse: 1,
			align: 'right'
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.invoice-amount'
			}),
			name: 'invoiceAmount',
			sort: true,
			collapse: 5,
			align: 'right'
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.payment-setup'
			}),
			name: 'paymentSetup',
			tag: 'CustomAccountStatusColumn',
			collapse: 6
		},
		{
			label: formatMessage({
				id: 'loan-billing.statements-page.table.column.label.action'
			}),
			name: 'action',
			collapse: 7,
			formatter: rowData => {
				if (rowData.item.makePayment === true) {
					const actionButtonLabel = formatMessage({
						id: 'loan-billing.statements-page.make-payment'
					})
					const { invoiceNumber = '' } = rowData?.item
					return (
						<Button
							kind="chromeless"
							label={actionButtonLabel}
							aria-label={`${formatMessage({
								id: 'loan-billing.statements-page.make-payment.aria-label'
							})} ${invoiceNumber}`}
							onClick={() => {
								clearMessages()
								onUpdateSystemMessage([])
								setRowData(rowData)
								setOpenPanel(true)
							}}
						/>
					)
				}
			}
		}
	]

	// if it's a single customer view, remove the customer column
	if (singleCustomerView) {
		columns.splice(columns.findIndex(col => col.name === 'customer'), 1)
	}

	if (rowsCountOnServer <= rowsBatchSize) {
		if (rows.length > 0) {
			const paymentSetupBlankRows = rows.filter(row => !row?.paymentSetup)
			const actionBlankRows = rows.filter(row => !row?.makePayment)
			const updatedRows = rows.filter(row => row?.paymentSetup?.toLowerCase() === 'unauthorized')
			if (paymentSetupBlankRows.length === rowsCountOnServer) {
				columns.splice(columns.findIndex(col => col.name === 'paymentSetup'), 1)
			} else if (updatedRows.length === rowsCountOnServer) {
				columns.splice(columns.findIndex(col => col.name === 'paymentSetup'), 1)
			}

			if (actionBlankRows.length === rowsCountOnServer) {
				columns.splice(columns.findIndex(col => col.name === 'action'), 1)
			} else if (updatedRows.length === rowsCountOnServer) {
				columns.splice(columns.findIndex(col => col.name === 'action'), 1)
			}
		}
	}

	const footer = {
		content: [
			{
				tag: 'CustomPrintButton',
				label: 'Print',
				showIcon: false
			},
			{
				tag: 'CustomMenuDropDown',
				display: 'standard',
				label: 'Export',
				className: 'ExportTable',
				items: [
					{
						label: 'CSV',
						name: 'csv',
						dataLink: {
							download: true,
							api: `${apiContext}/ExportData`,
							params: {
								ledger: {
									encryptedKey: ledger
								},
								reportName: 'LoanBillingStatement',
								exportType: 'csv',
								productCategory: 'Loan'
							}
						}
					},
					{
						label: 'Excel',
						name: 'excel',
						dataLink: {
							download: true,
							api: `${apiContext}/ExportData`,
							params: {
								ledger: {
									encryptedKey: ledger
								},
								reportName: 'LoanBillingStatement',
								exportType: 'xls',
								productCategory: 'Loan'
							}
						}
					}
				]
			}
		]
	}

	const extraContent = {
		tooltip: {
			label: formatMessage({
				id: 'loan-billing.statements-page.table.more-about.tooltip.label'
			}),
			column: 'invoiceNumber'
		}
	}

	const loadingLabel = formatMessage({
		id: 'loan-billing.statements-page.label.loading'
	})

	function handlePaginationAndSorting(paginationParams) {
		clearMessages()
		onUpdateSystemMessage([])
		onGetBillingStatements(ledger, { ...params, ...paginationParams })
	}

	return (
		<CustomPrintArea>
			<div className="ViewLoanBillingStatementsPage" ref={viewLoanBillingStatementsPageRef}>
				<CustomWaitMessage
					isLoading={showLoading}
					loadingMessage={loadingLabel}
					containerNode={viewLoanBillingStatementsPageRef}
				/>
				<BasicLabel
					label={formatMessage({
						id: 'loan-billing.statements-page.title.label'
					})}
				/>
				<BasicSystemMessages messages={systemMessages} />
				<CustomerHeader
					show={singleCustomerView}
					name={get(billingStatements, 'rows[0].customerName', '')}
					number={get(billingStatements, 'rows[0].customerNumber')}
				/>
				<StatementsTable
					extraContent={extraContent}
					systemMessages={systemMessages}
					columns={columns}
					footer={footer}
					billingStatements={billingStatements}
					params={params}
					handlePaginationAndSorting={handlePaginationAndSorting}
					formatMessage={formatMessage}
				/>
				<MakePaymentPanel
					show={openPanel}
					onClose={() => setOpenPanel(false)}
					rowData={rowData}
					singleCustomerView={singleCustomerView}
					formatMessage={formatMessage}
					onGetConfirmationViewData={onGetConfirmationViewData}
				/>
			</div>
		</CustomPrintArea>
	)
}

function StatementsTable({
	columns,
	extraContent,
	footer,
	billingStatements,
	params,
	handlePaginationAndSorting,
	formatMessage
}) {
	const { hideStatementsTable = false } = params
	if (!hideStatementsTable) {
		return (
			<Fragment>
				<BasicTable
					columns={columns}
					extraContent={extraContent}
					footer={footer}
					rows={billingStatements}
					primaryKey="encryptedKey"
					ariaCaption={formatMessage({
						id: 'loan-billing.statements-page.table.aria-caption'
					})}
					onSortRows={handlePaginationAndSorting}
					pagination={{
						show: true,
						onChange: handlePaginationAndSorting,
						paginationClassName: 'wf-col--12 label-right'
					}}
					messages={{
						noDataToDisplay: formatMessage({
							id: 'loan-billing.statements-page.table.no.billing-statements.to-display'
						}),
						collapsedRowAnnouncement: (itemKey, item) =>
							`${formatMessage({
								id: 'basic-table.label.more-about'
							})} ${item.invoiceNumber}${formatMessage({
								id: 'basic-table.label.collapsed'
							})}`,
						expandedRowAnnouncement: (itemKey, item) =>
							`${formatMessage({
								id: 'basic-table.label.more-about'
							})} ${item.invoiceNumber}${formatMessage({
								id: 'basic-table.label.expanded'
							})}`,
						rowExpandButtonLabel: (itemKey, { item, expanded }) =>
							`${
								expanded
									? formatMessage({
											id: 'loan-billing.statements-page.table.label.collapse'
									  })
									: formatMessage({
											id: 'loan-billing.statements-page.table.label.expand'
									  })
							} ${formatMessage({
								id: 'basic-table.label.more-about'
							})} ${item.invoiceNumber}`,
						rowExpandedDetails: (itemKey, item) =>
							`${item.invoiceNumber} ${formatMessage({
								id: 'basic-table.label.expanded-details'
							})}`
					}}
				/>
				<StatementsTableFooter formatMessage={formatMessage} statements={billingStatements} />
			</Fragment>
		)
	}
	return <BasicNoContent />
}

function StatementsTableFooter({ formatMessage, statements }) {
	if (statements.length === 0) {
		return <BasicNoContent />
	}

	return (
		<p className="StatementsFooter">
			<b>{formatMessage({ id: 'loan-billing.statements-page.interest-fees' })}</b>
			{formatMessage({ id: 'loan-billing.statements-page.interest.disclaimer' })}
		</p>
	)
}

function CustomerHeader({ show = false, name, number }) {
	if (!show) {
		return <BasicNoContent />
	}
	const customerNumberParsed = typeof number === 'string' ? `...${number?.substr(-4)}` : ''
	return <div className="SingleCustomerViewHeader">{`${name} ${customerNumberParsed}`}</div>
}
