import React, { Fragment, useEffect, useState, useContext, useRef } from 'react'
import isEmpty from 'lodash.isempty'
import { useSelector } from 'react-redux'
import {
	BasicLabelValue,
	BasicTable,
	BasicTabs,
	BasicNoContent,
	BasicLabel,
	BasicSpacer,
	SystemMessagesContext,
	BasicText
} from 'ecpfs-react-data-driven-components'
import { HelpButton, Feedback } from '@wf/ria'
import { IconHelp } from '@wf/ria-icons'
import { CustomWaitMessage } from './CustomWaitMessage'
import get from 'lodash.get'
import { getSystemMessageIconData } from '../utils/getSystemMessageIconData'
import LoanIqBasicHeader from './LoanIqBasicHeader'
import moment from 'moment-timezone'
import { FormattedMessage } from 'react-intl'

export function ObligationDetailsPanelContent({
	intl,
	obligationDetailsSummaryContent,
	obligationDetailsTableContent,
	commitmentDetails,
	selectedObligation,
	showObligationLoading,
	onGetObligationDetailsSummaryContent,
	onGetObligationDetailsTabContent,
	onResetObligationDetailsTabContent,
	source,
	commitmentDetailsSelectedTab,
	onApplyFilter,
	appliedFilters,
	onUpdateFilterValue,
	preferenceMonth,
	validStartMonth,
	validEndMonth,
	onclearObligationDetailsTabContent
}) {
	const { formatMessage } = intl
	const obligationDetailsPanelContentPageRef = useRef(null)
	const [selectedTab, setSelectedTab] = useState({
		tabIndex: 0,
		tabName: 'summary'
	})
	const [initialLoad, setInitialLoad] = useState(true)
	const { clearMessages } = useContext(SystemMessagesContext)
	const {
		obligationId,
		obligationNickname = '',
		obligationType = '',
		encryptedKey
	} = selectedObligation
	const { facilityNumber, facilityNickname = '' } = commitmentDetails
	const loadingLabel = formatMessage({
		id: 'obligation-details-page.label.loading'
	})

	useEffect(() => {
		if (initialLoad) {
			setInitialLoad(false)
			if (commitmentDetailsSelectedTab === 'transaction-history') {
				onGetObligationDetailsTabContent(encryptedKey, { rowsOffset: 0 })
				setSelectedTab({ tabIndex: 1, tabName: 'transaction-history' })
			} else {
				onGetObligationDetailsSummaryContent(encryptedKey)
			}
		}
	}, [
		onGetObligationDetailsTabContent,
		onGetObligationDetailsSummaryContent,
		selectedObligation,
		selectedTab,
		initialLoad,
		encryptedKey,
		commitmentDetailsSelectedTab
	])

	function handleTabChange(tab, tabInfo) {
		const {
			props: {
				data: { name: tabName }
			}
		} = tabInfo
		clearMessages()
		onResetObligationDetailsTabContent()
		if (tabName === 'summary') {
			onGetObligationDetailsSummaryContent(encryptedKey)
		} else {
			onGetObligationDetailsTabContent(encryptedKey, { rowsOffset: 0 })
		}

		const selectedTab = {
			tabIndex: tab,
			tabName
		}
		setSelectedTab(selectedTab)
	}

	return (
		<div className="ObligationDetailsPanelContent" ref={obligationDetailsPanelContentPageRef}>
			<ObligationInfo
				obligation={obligationId}
				obligationNickname={obligationNickname}
				facility={
					facilityNumber ? facilityNumber : obligationDetailsSummaryContent?.rows?.[0]?.commitmentId
				}
				facilityNickname={facilityNickname}
				formatMessage={formatMessage}
			/>
			<ObligationTabs
				updateSelectedTab={handleTabChange}
				selectedTab={selectedTab.tabIndex}
				formatMessage={formatMessage}
			/>
			<div className="ObligationDetailsPanelTabContent">
				<TabContent
					formatMessage={formatMessage}
					encryptedKey={encryptedKey}
					selectedTab={selectedTab}
					showObligationLoading={showObligationLoading}
					obligationType={obligationType}
					obligationDetailsTableContent={obligationDetailsTableContent}
					obligationDetailsSummaryContent={obligationDetailsSummaryContent}
					onGetObligationDetailsTabContent={onGetObligationDetailsTabContent}
					source={source}
					onApplyFilter={onApplyFilter}
					appliedFilters={appliedFilters}
					onUpdateFilterValue={onUpdateFilterValue}
					preferenceMonth={preferenceMonth}
					validStartMonth={validStartMonth}
					validEndMonth={validEndMonth}
					onclearObligationDetailsTabContent={onclearObligationDetailsTabContent}
				/>
				<CustomWaitMessage
					isLoading={showObligationLoading}
					loadingMessage={loadingLabel}
					containerNode={obligationDetailsPanelContentPageRef}
				/>
			</div>
		</div>
	)
}

export function ObligationTabs({ updateSelectedTab, selectedTab, formatMessage }) {
	const defaultTab = [
		{
			label: formatMessage({
				id: 'obligation-details-page.label.summary'
			}),
			name: 'summary'
		},
		{
			label: formatMessage({
				id: 'obligation-details-page.label.transaction-history'
			}),
			name: 'transaction-history'
		}
	]
	const tabsMeta = {}
	const tabsToDisplayArray = Object.keys(tabsMeta).map(tab => {
		return tabsMeta[tab]
	})

	const displayTabs = defaultTab.concat(tabsToDisplayArray)

	return (
		<BasicTabs tabs={displayTabs} updateSelectedTab={updateSelectedTab} selectedTab={selectedTab} />
	)
}

export function ObligationInfo({
	facility = '',
	facilityNickname,
	obligation,
	obligationNickname,
	formatMessage
}) {
	return (
		<div className="ObligationInfo">
			<BasicLabelValue
				label={formatMessage({
					id: 'obligation-details-page.label.commitment'
				})}
				value={facilityNickname ? `${facilityNickname} ${facility}` : `${facility}`}
			/>
			<BasicSpacer />
			<BasicLabelValue
				label={formatMessage({
					id: 'obligation-details-page.label.obligation'
				})}
				value={obligationNickname ? `${obligationNickname} ${obligation}` : `${obligation}`}
			/>
		</div>
	)
}

export function TabContent({
	selectedTab,
	obligationType,
	obligationDetailsTableContent,
	obligationDetailsSummaryContent,
	formatMessage,
	encryptedKey,
	onGetObligationDetailsTabContent,
	source,
	onApplyFilter,
	appliedFilters = {},
	onUpdateFilterValue,
	preferenceMonth,
	validStartMonth,
	validEndMonth,
	onclearObligationDetailsTabContent
}) {
	const { obligationDetailsSystemMessage } = useSelector(state => state.obligation)
	const { tabName } = selectedTab

	if (tabName === 'summary') {
		return (
			<ObligationDetailsSummary
				summary={obligationDetailsSummaryContent?.rows?.[0]}
				obligationType={obligationType}
				formatMessage={formatMessage}
				source={source}
			/>
		)
	}

	const columns = getColumns(tabName, formatMessage)
	const adjustedColumns = getAdjustedColumns(columns, obligationType, formatMessage)

	function onPagination({ rowsOffset, rowsBatchSize }) {
		onclearObligationDetailsTabContent()
		const params = get(obligationDetailsTableContent, 'dataLink.params', {})
		const filterData = get(obligationDetailsTableContent, 'dataLink.params.userData.filterData', {})
		onGetObligationDetailsTabContent(
			encryptedKey,
			{ ...params, rowsOffset, rowsBatchSize },
			filterData
		)
	}

	function onColumnSort(paginationParams) {
		onclearObligationDetailsTabContent()
		const params = get(obligationDetailsTableContent, 'dataLink.params', {})
		const filterData = get(obligationDetailsTableContent, 'dataLink.params.userData.filterData', {})
		onGetObligationDetailsTabContent(encryptedKey, { ...params, ...paginationParams }, filterData)
	}

	function applyFilterData(encryptedKey) {
		const {
			effectiveDateType,
			startEffectiveDate = validStartMonth,
			endEffectiveDate = validEndMonth
		} = appliedFilters
		const filterData =
			effectiveDateType !== 'customDate'
				? { filterType: effectiveDateType }
				: { startEffectiveDate, endEffectiveDate }
		onApplyFilter(encryptedKey, { rowsOffset: 0 }, filterData)
	}
	const isApplied = Object.keys(appliedFilters).length > 0

	return (
		<Fragment>
			<Note
				formatMessage={formatMessage}
				preferenceMonth={preferenceMonth}
				obligationDetailsTableContent={obligationDetailsTableContent}
			/>
			<ObligationtSystemMessage obligationDetailsSystemMessage={obligationDetailsSystemMessage} />
			<ObligationTransactionHistoryTable
				formatMessage={formatMessage}
				obligationDetailsTableContent={obligationDetailsTableContent}
				adjustedColumns={adjustedColumns}
				getTablePagination={getTablePagination}
				onPagination={onPagination}
				onColumnSort={onColumnSort}
				appliedFilters={appliedFilters}
				onUpdateFilterValue={onUpdateFilterValue}
				validEndMonth={validEndMonth}
				validStartMonth={validStartMonth}
				isApplied={isApplied}
				encryptedKey={encryptedKey}
				applyFilterData={applyFilterData}
			/>
		</Fragment>
	)
}

function ObligationTransactionHistoryTable({
	formatMessage,
	obligationDetailsTableContent,
	adjustedColumns,
	getTablePagination,
	onPagination,
	onColumnSort,
	appliedFilters,
	onUpdateFilterValue,
	validEndMonth,
	validStartMonth,
	isApplied,
	encryptedKey,
	applyFilterData
}) {
	const params = get(obligationDetailsTableContent, 'dataLink.params', {})
	const { hideTable = false } = params
	if (hideTable) {
		return <BasicNoContent />
	}
	return (
		<Fragment>
			<LoanIqBasicHeader
				description={`${formatMessage({
					id: 'commitment-details-page-table.tansaction-history.current.date-time'
				})} ${moment()
					.tz('America/Los_Angeles')
					.format('MM/DD/YYYY hh:mm a')} PT`}
			/>
			<BasicTable
				rows={obligationDetailsTableContent}
				columns={adjustedColumns}
				pagination={getTablePagination(obligationDetailsTableContent, onPagination)}
				onSorted={onColumnSort}
				filter={{
					show: true,
					isApplied,
					label: formatMessage({
						id: 'commitment-details-page-filter.title'
					}),
					appliedLabel: formatMessage({
						id: 'commitment-details-page-filter.applied-label'
					}),
					ariaLabel: formatMessage({
						id: 'commitment-details-page-filter.aria-label'
					}),
					apply: {
						label: formatMessage({
							id: 'commitment-details-page-filter.apply-button'
						}),
						onClick() {
							applyFilterData(encryptedKey)
						}
					},
					cancel: {
						label: formatMessage({
							id: 'commitment-details-page-filter.cancel-button'
						})
					},
					content: [
						{
							tag: 'ObligationTransactionHistoryFilter',
							name: 'ObligationTransactionHistoryFilter',
							formatMessage: formatMessage,
							appliedFilters: appliedFilters,
							onUpdateFilterValue: onUpdateFilterValue,
							validEndMonth: validEndMonth,
							validStartMonth: validStartMonth
						}
					],
					help: {
						helpBody: formatMessage({
							id: 'commitment-details-page-transaction-history.filter.help.body'
						}),
						helpAriaLabel: formatMessage({
							id: 'commitment-details-page-transaction-history.filter.help.aria-label'
						})
					}
				}}
			/>
			<TransactionInProgressMessage
				obligationDetailsTableContent={obligationDetailsTableContent}
				formatMessage={formatMessage}
			/>
		</Fragment>
	)
}

function ObligationtSystemMessage({ obligationDetailsSystemMessage }) {
	if (isEmpty(obligationDetailsSystemMessage)) {
		return <BasicNoContent />
	}
	const { iconComponent, theme } = getSystemMessageIconData(
		obligationDetailsSystemMessage[0].icon,
		'medium'
	)
	return (
		<div className="ObligationSystemMessage">
			<Feedback icon={iconComponent} size="large" padding border theme={theme} role="alert">
				{obligationDetailsSystemMessage[0].message}
			</Feedback>
		</div>
	)
}

function TransactionInProgressMessage({ obligationDetailsTableContent, formatMessage }) {
	const rows = get(obligationDetailsTableContent, 'rows', [])
	const rowsWithNoEffectiveDate = rows.filter(row => !row.effectiveDate)
	if (rowsWithNoEffectiveDate.length > 0) {
		return (
			<div className="TransactionInProgressMessage">
				<b>
					{formatMessage({
						id: 'obligation-details-panel.table.transaction-in-progress.bold.text'
					})}
				</b>
				<span>
					{formatMessage({
						id: 'obligation-details-panel.table.transaction-in-progress.text'
					})}
				</span>
			</div>
		)
	}
	return <BasicNoContent />
}

function Note({ formatMessage, preferenceMonth, obligationDetailsTableContent }) {
	return (
		<Fragment>
			<div className="CommitmentDetailsTabsNote">
				<BasicText
					text={formatMessage({ id: 'commitment-details-page-table.tansaction-history.note' })}
				/>
			</div>
			<div className="NoTransactionMesssage">
				<TransactionDateRangeMessage
					preferenceMonth={preferenceMonth}
					obligationDetailsTableContent={obligationDetailsTableContent}
					formatMessage={formatMessage}
				/>
			</div>
		</Fragment>
	)
}

function TransactionDateRangeMessage({ preferenceMonth, obligationDetailsTableContent }) {
	const params = get(obligationDetailsTableContent, 'dataLink.params', {})
	const { hideTable = false, earliestTxnEffectiveDate = '' } = params
	if (hideTable || !earliestTxnEffectiveDate || earliestTxnEffectiveDate === null) {
		return (
			<FormattedMessage
				id="commitment-details-page-table.tansaction-history.note.no-transaction"
				values={{
					preferenceMonth: preferenceMonth
				}}
			/>
		)
	}
	return (
		<FormattedMessage
			id="commitment-details-page-table.tansaction-history.note.earliest-transaction"
			values={{
				preferenceMonth: preferenceMonth,
				validStartMonth: earliestTxnEffectiveDate
			}}
		/>
	)
}

export function getTablePagination(obligationDetailsTableContent, onPagination) {
	const { dataLink = {} } = obligationDetailsTableContent
	const { params = {} } = dataLink
	const { rowsOffset = 0, rowsBatchSize = 0, rowsCountOnServer = 0 } = params
	return {
		show: true,
		rowsOffset,
		rowsCountOnServer,
		rowsBatchSize,
		totalItems: get(obligationDetailsTableContent, 'rows', []).length,
		onChange: onPagination,
		paginationClassName: 'wf-col--4 label-right'
	}
}

export function getAdjustedColumns(columns, obligationType, formatMessage) {
	const updatedColumns = [...columns]
	const column = updatedColumns.find(item => item.name === 'outstandingPrincipalBalance')

	if (column !== undefined) {
		const id =
			obligationType.toLowerCase() === 'loan'
				? 'transaction-history-table.column.outstanding-principal'
				: 'transaction-history-table.column.balance'
		column.label = formatMessage({ id })
	}
	return updatedColumns
}

export function getColumns(columnsOfTable, formatMessage) {
	const columnsConfig = {
		'transaction-history': [
			{
				label: formatMessage({
					id: 'transaction-history-table.column.effective-date'
				}),
				name: 'effectiveDate',
				collapse: 0,
				sort: true
			},
			{
				label: formatMessage({
					id: 'transaction-history-table.column.transaction-type'
				}),
				name: 'transactionType',
				collapse: 3,
				sort: true
			},
			{
				label: formatMessage({
					id: 'transaction-history-table.column.transaction-amount'
				}),
				name: 'transactionAmount',
				align: 'right',
				wriaProps: {
					column: {
						align: 'right'
					}
				},
				collapse: 2,
				sort: true
			},
			{
				label: '',
				name: 'outstandingPrincipalBalance',
				align: 'right',
				sort: true,
				wriaProps: {
					column: {
						align: 'right'
					}
				},
				collapse: 1
			}
		]
	}
	return columnsConfig[columnsOfTable]
}

export function ObligationDetailsSummary({ summary, obligationType, formatMessage, source }) {
	if (!summary) {
		return <BasicNoContent />
	}
	return (
		<div>
			<Overview
				data={summary}
				obligationType={obligationType}
				formatMessage={formatMessage}
				source={source}
			/>

			<Interests
				interest={summary.interest}
				obligationType={obligationType}
				formatMessage={formatMessage}
				inactiveDate={summary.inactiveDate}
			/>
		</div>
	)
}

export function getSectionHeaderLabel({ formatMessage }) {
	return formatMessage({ id: 'obligation-details-page.label.interest' })
}

export function getSectionHelpContentText({ formatMessage }) {
	return formatMessage({ id: 'obligation-details-page.label.interest-tooltip' })
}
export function getSectionHelpAriaLabel({ formatMessage }) {
	return formatMessage({ id: 'obligation-details-page.label.interest-aria-label' })
}

export function SectionHeader({ formatMessage }) {
	const label = getSectionHeaderLabel({ formatMessage })
	const helpContentText = getSectionHelpContentText({ formatMessage })
	const helpAriaLabel = getSectionHelpAriaLabel({ formatMessage })

	return (
		<div className="InterestContainer">
			<BasicLabel level={2} label={label} className="InterestHeader" />
			<div className="HelpButton">
				<HelpButton
					kind="chromeless"
					helpBody={helpContentText}
					aria-label={helpAriaLabel}
					centerIcon={<IconHelp size="small" />}
				/>
			</div>
		</div>
	)
}

export function Interests({ interest, formatMessage, inactiveDate }) {
	if (!interest || isEmpty(interest)) {
		return <BasicNoContent />
	}
	return (
		<div className="InterestLabelValue">
			<SectionHeader formatMessage={formatMessage} />
			<Overview data={interest} formatMessage={formatMessage} inactiveDate={inactiveDate} />
		</div>
	)
}

export function normalizeJSON(obj, formatMessage) {
	const keyLabelMapping = mapKeysToLabels(formatMessage)

	const keys = Object.keys(obj)
	const mappings = keys.map(key => {
		if (!keyLabelMapping[key]) {
			return {}
		}
		const { label, align, order, currency } = keyLabelMapping[key]
		return {
			key,
			label,
			align,
			order,
			currency,
			value: obj[key]
		}
	})
	return mappings
}

function mapKeysToLabels(formatMessage) {
	const keyLabelMapping = {
		description: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.description' }),
			align: 'left',
			currency: false,
			order: 1
		},
		letterOfCredit: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.letter-of-credit-number' }),
			align: 'left',
			currency: false,
			order: 2
		},
		interestRate: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.interest-rate' }),
			align: 'left',
			currency: false,
			order: 3
		},
		effectiveDate: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.effective-date' }),
			align: 'left',
			currency: false,
			order: 4
		},
		maturityDate: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.maturity-date' }),
			align: 'left',
			currency: false,
			order: 5
		},
		actionDate: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.action-date' }),
			align: 'left',
			currency: false,
			order: 6
		},
		outstandingBalance: {
			label: '',
			align: 'right',
			currency: true,
			order: 7
		},
		balanceInFacilityCurrency: {
			label: formatMessage({
				id: 'obligation-details-page-summary.label.balance-in-facility-currency'
			}),
			align: 'right',
			currency: true,
			order: 8
		},
		inactiveDate: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.inactive-date' }),
			align: 'left',
			order: 9
		},
		totalFeesPaid: {
			label: formatMessage({
				id: 'obligation-details-page-summary.label.total-fees-paid'
			}),
			align: 'left',
			currency: true,
			order: 3
		},
		currentAccruedUnpdInt: {
			label: formatMessage({
				id: 'obligation-details-page-summary.label.current-accrued-unpaid-interest'
			}),
			align: 'left',
			order: 1,
			currency: true
		},
		dailyInterestAccrual: {
			label: formatMessage({ id: 'obligation-details-page-summary.label.daily-interest-accrual' }),
			align: 'left',
			order: 2,
			currency: true
		}
	}
	return keyLabelMapping
}

export function Overview({ data, obligationType, formatMessage, source }) {
	const fields = normalizeJSON(data, formatMessage)

	if (source !== undefined) {
		const outstandingBalanceField = getFieldByKey(fields, 'outstandingBalance')
		setOutstandingBalanceFieldLabel(outstandingBalanceField, obligationType, formatMessage)
	}

	const fieldsOnLeft = sortFields(fields.filter(field => field && field.align === 'left'))
	const fieldsOnRight = sortFields(fields.filter(field => field && field.align === 'right'))

	return (
		<div className="Overview">
			<div className="OverviewLeft">
				<RenderLabelValue fields={fieldsOnLeft} formatMessage={formatMessage} />
			</div>
			<div className="OverviewRight">
				<RenderLabelValue fields={fieldsOnRight} formatMessage={formatMessage} />
			</div>
		</div>
	)
}

export function sortFields(fields) {
	if (fields.length === 0) return []
	const array = [...fields]
	const filtered = array.filter(item => item.order !== undefined)
	if (filtered.length < array.length) return array
	array.sort((a, b) => a.order - b.order)
	return array
}

export function setOutstandingBalanceFieldLabel(field, obligationType, formatMessage) {
	const id =
		obligationType.toLowerCase() === 'loan'
			? 'obligation-details-page-summary.label.outstanding-principal'
			: 'obligation-details-page-summary.label.balance'
	setFieldLabel(field, formatMessage({ id }))
}

export function getFieldByKey(fields, key) {
	return fields.find(item => item.key === key)
}

export function setFieldLabel(field, label) {
	if (field !== undefined) {
		field.label = label
	}
}

export function RenderLabelValue({ fields, formatMessage }) {
	function getClassName(align) {
		if (align === 'left') {
			return 'BasicLabelValue__left'
		}
		return 'BasicLabelValue__right'
	}
	return fields.map(({ label, align, value, currency }, index) => {
		if (!label || !value) {
			return <Fragment />
		}

		function customFormattedValues(value, currency) {
			if (value) {
				if (currency) {
					const currencyUsd = formatMessage({
						id: 'view-interest-panel.label.currency-usd'
					})
					return `${value} ${currencyUsd}`
				}
				return value
			}
			return ''
		}

		return (
			<Fragment key={index}>
				<BasicLabelValue
					value={customFormattedValues(value, currency)}
					label={label}
					align="right"
					layout={{
						width: 'wf-row',
						label: 'wf-col--7',
						field: 'wf-col--5'
					}}
					className={getClassName(align)}
				/>
			</Fragment>
		)
	})
}
