import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { HelpButton } from '@wf/ria'
import { BasicLabel, BasicNoContent } from 'ecpfs-react-data-driven-components'
import { IconHelp } from '@wf/ria-icons'

export function CustomAccountStatusColumn({ rowData }) {
	const { item = {} } = rowData || {}
	const { autoDebitAccountNumber, paymentSetup, iconText, contextualText } = item

	if (autoDebitAccountNumber) {
		return (
			<Fragment>
				<div>{paymentSetup}</div>
				<div>{autoDebitAccountNumber}</div>
			</Fragment>
		)
	} else if (iconText && contextualText) {
		return (
			<CustomLabelWithHelp
				label={paymentSetup}
				iconText={iconText}
				contextualText={contextualText}
			/>
		)
	}
	return <BasicNoContent />
}

export function CustomLabelWithHelp({ label, iconText, contextualText }) {
	return (
		<div className="PaymentSetupColumn">
			<BasicLabel level={0} label={label} />
			<div className="HelpButton">
				<HelpButton
					kind="chromeless"
					helpBody={contextualText}
					aria-label={iconText}
					centerIcon={<IconHelp size="small" />}
				/>
			</div>
		</div>
	)
}

CustomAccountStatusColumn.propTypes = {
	dataLink: PropTypes.object,
	rowData: PropTypes.object
}

CustomAccountStatusColumn.displayName = 'CustomAccountStatusColumn'

export default CustomAccountStatusColumn
