import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	BasicNoContent,
	RenderComponent,
	RenderComponents
} from 'ecpfs-react-data-driven-components'
import { initializeNativeBridgeScript } from '../vendor/nativeBridgeScript'

export const PrintAreaContext = React.createContext()

function CustomPrintArea({ content, action, ...props }) {
	const children = RenderChildren(content, props)
	const { htmlData } = props
	const printAreaRef = React.useRef()

	useEffect(() => {
		initializeNativeBridgeScript()
	})

	return (
		<div ref={printAreaRef} className={props.className} {...htmlData}>
			<PrintAreaContext.Provider value={printAreaRef}>
				{children}
				<RenderPrintAction action={action} />
			</PrintAreaContext.Provider>
		</div>
	)
}

function RenderPrintAction({ action }) {
	if (!action) {
		return <BasicNoContent />
	}
	return <RenderComponent data={action} DefaultComponent={BasicNoContent} />
}

function RenderChildren(content, props) {
	const { children } = props
	if (children) {
		return children
	}
	return <RenderComponents list={content} DefaultComponent={BasicNoContent} />
}

CustomPrintArea.displayName = 'CustomPrintArea'
CustomPrintArea.propTypes = {
	content: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object)
	]),
	action: PropTypes.object,
	props: PropTypes.object
}

export default CustomPrintArea
