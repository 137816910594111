import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { DateRangePicker, DatePicker } from '@wf/ria'
import Moment from 'moment'
import isEqual from 'lodash.isequal'
import get from 'lodash.get'
import React, { useEffect } from 'react'

export function CustomDateRange({
	id,
	applied,
	dateRange,
	label,
	reset = false,
	isSameDateValid = false,
	onDateRangeChange,
	formatMessage,
	minSelectableDate,
	maxSelectableDate,
	customStrings
}) {
	const filterId = id
	const disableWeekends = false
	const [calendarState, setCalendarState] = React.useState(applied)
	const { startDate, endDate } = calendarState
	function onValueChange({ name, value }) {
		setCalendarState({ ...calendarState, [name]: value })
		const dateViolations = getDateFilterValidations(
			applied,
			{ ...calendarState, [name]: value },
			dateRange,
			isSameDateValid
		)
		onDateRangeChange({ startDate, endDate, [name]: value, dateViolations, filterId })
	}

	useEffect(() => {
		if (reset) {
			setCalendarState(applied)
		}
	}, [applied, reset])

	return (
		<div className="DateRange wf-col">
			<div className="DatePicker">
				<div className="DatePickerTitle">
					<DateRangeLabel label={label} formatMessage={formatMessage} />
				</div>
				<div className="DatePickerContent">
					<DateRangePicker
						fromDatePicker={
							<DatePicker
								label={formatMessage({
									id: 'custom-filter.date-range.from.label'
								})}
								name="startDate"
								value={startDate}
								disableWeekends={disableWeekends}
								onValueChange={onValueChange}
								fromMonth={new Date(2011, 0, 1)}
								minSelectableDate={minSelectableDate}
								maxSelectableDate={maxSelectableDate}
								customStrings={customStrings}
								required
							/>
						}
						toDatePicker={
							<DatePicker
								label={formatMessage({
									id: 'custom-filter.date-range.to.label'
								})}
								name="endDate"
								value={endDate}
								disableWeekends={disableWeekends}
								onValueChange={onValueChange}
								fromMonth={new Date(2011, 0, 1)}
								minSelectableDate={minSelectableDate}
								maxSelectableDate={maxSelectableDate}
								customStrings={customStrings}
								required
							/>
						}
					/>
					<div className="DatePickerInstructions">
						<FormattedMessage id="custom-filter.date-range.instructions" />
					</div>
				</div>
			</div>
		</div>
	)
}

export function getDateFilterValidations(appliedDate, calendarState, dateRange, isSameDateValid) {
	const dateViolations = []
	if (isEqual(appliedDate, calendarState) && isSameDateValid) {
		dateViolations.push('sameAppliedDateError')
	}
	if (
		calendarState.startDate === '' ||
		Moment(calendarState.startDate, 'MM/DD/YYYY', true).isValid() === false
	) {
		dateViolations.push('startDateError')
	}
	if (
		calendarState.endDate === '' ||
		Moment(calendarState.endDate, 'MM/DD/YYYY', true).isValid() === false
	) {
		dateViolations.push('endDateError')
	}
	if (isDateRangeValid(calendarState.startDate, calendarState.endDate, dateRange)) {
		dateViolations.push('dateRangeError')
	}
	return dateViolations
}

export function DateRangeLabel({ label, formatMessage }) {
	if (get(label, 'id', false) !== false) {
		return formatMessage({ id: label.id })
	} else {
		return label
	}
}

export function isDateRangeValid(startDate, endDate, range) {
	return Moment(new Date(endDate)).diff(new Date(startDate), 'months', true) > range
}

CustomDateRange.displayName = 'CustomDateRange'

CustomDateRange.propTypes = {
	/**
	 * id for values passed: returned back by handleModalApplyClick prop
	 */
	id: PropTypes.string,
	/**
	 * Prepopulate field {startDate:'',endDate:''} format: MM/DD/YYYY
	 */
	applied: PropTypes.object,
	/**
	 * Label to show on Date Range {label:{id:'id.of.message-to-be-Formatted'}}
	 */
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	/**
	 * If given applied Date and current Date needs check for same values
	 */
	isSameDateValid: PropTypes.bool,
	/**
	 * onDateRangeChange callback returns {{startDate:'', endDate:'',dateVoilation:[], id}}
	 */
	onDateRangeChange: PropTypes.func,
	/**
	 * Format Message
	 */
	formatMessage: PropTypes.func,
	/**
	 * reset - does a hard reset with applied when multiple date filters present
	 */
	reset: PropTypes.bool
}

export default CustomDateRange
