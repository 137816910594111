import React, { Fragment } from 'react'
import { WaitMessage } from '@wf/ria'

export function CustomWaitMessage({ isLoading, loadingMessage, containerNode }) {
	if (isLoading && containerNode && containerNode.current) {
		return (
			<WaitMessage
				container={() => containerNode.current}
				render={() => <span>{loadingMessage}</span>}
			/>
		)
	}
	return <Fragment />
}
