import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { BasicLabel } from 'ecpfs-react-data-driven-components'

export function CustomCustomerNumberColumn({ rowData }) {
	const {
		item: { customer = '', customerName = '', customerNumber }
	} = rowData
	const customerNumberParsed =
		typeof customerNumber === 'string' ? `...${customerNumber?.substr(-4)}` : ''

	if (customer) {
		return <BasicLabel level={0} label={CustomCustomerTabsLabel(customer, customerNumberParsed)} />
	}
	return <BasicLabel level={0} label={CustomCustomerLabel(customerName, customerNumberParsed)} />
}

export function CustomCustomerTabsLabel(customer, customerNumberParsed) {
	return (
		<Fragment>
			<div>{customer}</div>
			<div>{customerNumberParsed}</div>
		</Fragment>
	)
}

export function CustomCustomerLabel(customerName, customerNumberParsed) {
	return (
		<Fragment>
			<div>{customerName}</div>
			<div>{customerNumberParsed}</div>
		</Fragment>
	)
}

CustomCustomerNumberColumn.propTypes = {
	dataLink: PropTypes.object,
	rowData: PropTypes.object
}

CustomCustomerNumberColumn.displayName = 'CustomCustomerNumberColumn'

export default CustomCustomerNumberColumn
