import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withUserDataForm } from 'ecpfs-react-data-driven-components'
import { PayDownsAndAdvancesTable } from '../components/PayDownsAndAdvancesTable'
import { getPayDownsAndAdvancesData, getPayDownsConfirmationViewData } from '../actions/commitment'
import { updateSystemMessage } from '../actions/systemMessages'

export const mapStateToProps = state => ({
	payDownsAndAdvancesSystemMessage: state.commitment.payDownsAndAdvancesSystemMessage,
	payDownsAndAdvancesData: state.commitment.payDownsAndAdvancesData,
	showAccountsLoading: state.billingStatements.showAccountsLoading
})

export const mapDispatchToProps = dispatch => ({
	onGetPayDownsAndAdvancesData(facilityKey, dropdownValue) {
		dispatch(getPayDownsAndAdvancesData(facilityKey, dropdownValue))
	},
	onGetPayDownsConfirmationViewData(
		dropdownValue,
		obligationId,
		paymentTotal,
		selectedAccountEncryptedKey,
		responseStatus,
		additionalAdvanceParams
	) {
		dispatch(
			getPayDownsConfirmationViewData(
				dropdownValue,
				obligationId,
				paymentTotal,
				selectedAccountEncryptedKey,
				responseStatus,
				additionalAdvanceParams
			)
		)
	},
	onUpdateSystemMessage(systemMessages) {
		dispatch(updateSystemMessage(systemMessages))
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withUserDataForm()(PayDownsAndAdvancesTable))
)
component.displayName = 'PayDownsAndAdvancesTable'

export default component
