import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { CustomSystemMessage } from '../components/CustomSystemMessage'
import { updateSystemMessage } from '../actions/systemMessages'

export const mapStateToProps = state => {
	return {
		systemMessages: state.systemMessages.systemMessages
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onUpdateSystemMessage(systemMessages) {
			dispatch(updateSystemMessage(systemMessages))
		}
	}
}

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CustomSystemMessage)
)

component.displayName = 'CustomSystemMessage'
export default component
