import { updateSystemMessage } from './systemMessages'
import { fetchDeleteObligationNickname } from '../services/fetchDeleteObligationNickname'
import { fetchSaveObligationNickname } from '../services/fetchSaveObligationNickname'
import { fetchGetObligationDetailsSummary } from '../services/fetchGetObligationDetailsSummary'
import { fetchGetObligationDetailsTransactionHistory } from '../services/fetchGetObligationDetailsTransactionHistory'
import { getCommitmentTableData } from './commitment'

export function submitObligationNickname(params, commitmentDetailsSelectedTab) {
	return async function submitObligationNicknameThunk(dispatch, getState) {
		const { encryptedKey, nickName } = params
		const { facilityKey, commitmentDetailsTableInfo } = getState().commitment
		const {
			dataLink: { params: paginationParams }
		} = commitmentDetailsTableInfo
		const submitNicknameParams = {
			customerEnrollmentKey: {
				encryptedKey
			},
			nickname: nickName
		}
		const pagination = {
			...paginationParams
		}
		try {
			const response = await fetchSaveObligationNickname(submitNicknameParams)
			const updatedResponse = {
				...response,
				systemMessages: response.systemMessages.map(systemMessage => ({
					...systemMessage,
					type: 'messageBar',
					time: new Date()
				}))
			}
			dispatch(updateSystemMessage(updatedResponse))
			dispatch(getCommitmentTableData(commitmentDetailsSelectedTab, facilityKey, pagination))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export function deleteObligationNickname(commitmentDetailsSelectedTab) {
	return async function deleteObligationNicknameThunk(dispatch, getState) {
		const { obligationRow } = getState().obligation
		const { facilityKey, commitmentDetailsTableInfo } = getState().commitment
		const {
			dataLink: { params }
		} = commitmentDetailsTableInfo
		const deleteNicknameParams = {
			obligationDetailsTabKey: {
				encryptedKey: obligationRow.encryptedKey
			}
		}
		const pagination = {
			...params
		}
		try {
			const response = await fetchDeleteObligationNickname(deleteNicknameParams)
			const updatedResponse = {
				...response,
				systemMessages: response.systemMessages.map(systemMessage => ({
					...systemMessage,
					type: 'messageBar',
					time: new Date()
				}))
			}
			dispatch(updateSystemMessage(updatedResponse))
			dispatch(getCommitmentTableData(commitmentDetailsSelectedTab, facilityKey, pagination))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export const OPEN_ADD_OBLIGATION_NICKNAME_PANEL = 'OPEN_ADD_OBLIGATION_NICKNAME_PANEL'

export function openAddObligationNicknamePanel(isOpen) {
	return {
		type: OPEN_ADD_OBLIGATION_NICKNAME_PANEL,
		isOpen
	}
}

export const OPEN_EDIT_OBLIGATION_NICKNAME_PANEL = 'OPEN_EDIT_OBLIGATION_NICKNAME_PANEL'

export function openEditObligationNicknamePanel(isOpen) {
	return {
		type: OPEN_EDIT_OBLIGATION_NICKNAME_PANEL,
		isOpen
	}
}

export const GET_SELECTED_OBLIGATION_ROW = 'GET_SELECTED_OBLIGATION_ROW'

export function getSelectedObligationRow(obligationRow) {
	return {
		type: GET_SELECTED_OBLIGATION_ROW,
		obligationRow
	}
}

export const RESET_OBLIGATION_DETAILS_TAB_CONTENT = 'RESET_OBLIGATION_DETAILS_TAB_CONTENT'

export function resetObligationDetailsTabContent() {
	return {
		type: RESET_OBLIGATION_DETAILS_TAB_CONTENT
	}
}
export const GET_OBLIGATION_DETAILS_TABS_CONTENT_SYSTEM_MESSAGES =
	'GET_OBLIGATION_DETAILS_TABS_CONTENT_SYSTEM_MESSAGES'
export function getObligationDetailsTabsContentSystemMessages(systemMessages) {
	return {
		type: GET_OBLIGATION_DETAILS_TABS_CONTENT_SYSTEM_MESSAGES,
		systemMessages
	}
}

export function getObligationDetailsSummary(encryptedKey) {
	return async function getObligationDetailsSummaryThunk(dispatch) {
		dispatch(showObligationDetailsLoading(true))
		try {
			const requestBody = {
				obligationDetailsTabKey: {
					encryptedKey
				}
			}
			dispatch(clearObligationDetailsTabContent())
			const response = await fetchGetObligationDetailsSummary(requestBody)
			dispatch(getObligationDetailsSummarySuccess(response))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
		dispatch(showObligationDetailsLoading(false))
	}
}

export function getObligationDetailsTransactionHistory(encryptedKey, pagination, filterData) {
	return async function getObligationDetailsTransactionHistoryThunk(dispatch) {
		dispatch(showObligationDetailsLoading(true))
		try {
			const requestBody = {
				obligationTransactionHistoryTabKey: {
					encryptedKey
				},
				...pagination,
				userData: { filterData }
			}
			const response = await fetchGetObligationDetailsTransactionHistory(requestBody)
			dispatch(getObligationDetailsTransactionHistorySuccess(response))
			dispatch(getObligationDetailsTabsContentSystemMessages(response?.systemMessages))
		} catch (error) {
			dispatch(getObligationDetailsTabsContentSystemMessages(error?.systemMessages))
		}
		dispatch(showObligationDetailsLoading(false))
	}
}

export const GET_OBLIGATION_DETAILS_SUMMARY_SUCCESS = 'GET_OBLIGATION_DETAILS_SUMMARY_SUCCESS'

export function getObligationDetailsSummarySuccess(obligationDetailsSummary) {
	return {
		type: GET_OBLIGATION_DETAILS_SUMMARY_SUCCESS,
		obligationDetailsSummary
	}
}

export const GET_OBLIGATION_DETAILS_TRANSACTION_HISTORY_SUCCESS =
	'GET_OBLIGATION_DETAILS_TRANSACTION_HISTORY_SUCCESS'

export function getObligationDetailsTransactionHistorySuccess(obligationDetailsTransactionHistory) {
	return {
		type: GET_OBLIGATION_DETAILS_TRANSACTION_HISTORY_SUCCESS,
		obligationDetailsTransactionHistory
	}
}

export const CLEAR_OBLIGATION_DETAILS_TAB_CONTENT = 'CLEAR_OBLIGATION_DETAILS_TAB_CONTENT'
export function clearObligationDetailsTabContent() {
	return {
		type: CLEAR_OBLIGATION_DETAILS_TAB_CONTENT
	}
}

export const SHOW_OBLIGATION_DETAILS_LOADING = 'SHOW_OBLIGATION_DETAILS_LOADING'
export function showObligationDetailsLoading(showObligationLoading) {
	return {
		type: SHOW_OBLIGATION_DETAILS_LOADING,
		showObligationLoading
	}
}

export const UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE'

export function updateFilterValue(filter) {
	return {
		type: UPDATE_FILTER_VALUE,
		filter
	}
}
