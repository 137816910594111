import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withUserDataForm } from 'ecpfs-react-data-driven-components'
import { ViewInterestPanel } from '../components/ViewInterestPanel'
import { getViewInterestDetails } from '../actions/commitment'

export const mapStateToProps = state => ({
	viewInterestDetails: state.commitment.viewInterestDetails,
	viewInterestSystemMessages: state.commitment.viewInterestSystemMessages,
	facilityKey: state.commitment.facilityKey,
	commitmentDetails: state.commitment.commitmentDetails
})

export const mapDispatchToProps = dispatch => ({
	onGetViewInterestDetails(facilityKey) {
		dispatch(getViewInterestDetails(facilityKey))
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withUserDataForm()(ViewInterestPanel))
)
component.displayName = 'ViewInterestPanel'

export default component
