import { fetchLedgers } from '../services/fetchLedgers'
import { updateSystemMessage } from './systemMessages'

export function getLedgers() {
	return async function getLedgersThunk(dispatch) {
		try {
			const ledgersReponse = await fetchLedgers()
			const ledgers = ledgersReponse.ledgers.map((ledger, tabIndex) => {
				return {
					...ledger,
					tabIndex
				}
			})
			dispatch(getLedgersSuccess({ ...ledgersReponse, ledgers }))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export const GET_LEDGERS_SUCCESS = 'LIQ/GET_LEDGERS_SUCCESS'
export function getLedgersSuccess(ledgers) {
	return {
		type: GET_LEDGERS_SUCCESS,
		ledgers
	}
}
