import { BasicLabelSelect, BasicNoContent } from 'ecpfs-react-data-driven-components'
import CustomDateRange from './CustomDateRange'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Checkbox, HelpButton } from '@wf/ria'
import { IconHelp } from '@wf/ria-icons'
moment.defaultFormat = 'MM-DD-YYYY'

export function TransactionHistoryTableFilter({
	formatMessage,
	appliedFilters,
	onHandleFilterValueChange,
	validEndMonth,
	validStartMonth,
	fromInternalSite
}) {
	const {
		setAsDefault = false,
		effectiveDateType,
		startEffectiveDate = validStartMonth,
		endEffectiveDate = validEndMonth
	} = appliedFilters

	const [date, setDate] = useState({ startDate: startEffectiveDate, endDate: endEffectiveDate })

	function handleSetAsDefaultCheckbox({ name, checked }) {
		onHandleFilterValueChange({ key: name, value: checked })
	}

	function handleFilterTypeChange(value) {
		onHandleFilterValueChange({ key: 'effectiveDateType', value })
		onHandleFilterValueChange({ key: 'setAsDefault', value: false })
	}
	function onDateChange({ startDate, endDate }) {
		setDate({ startDate, endDate })
	}

	useEffect(() => {
		const validStartDate = moment(validStartMonth).subtract(1, 'days')
		const validEndDate = moment(validEndMonth).add(1, 'days')
		if (
			(date.startDate !== startEffectiveDate &&
				moment(date.startDate).isBetween(validStartDate, validEndDate)) ||
			(date.endDate !== endEffectiveDate &&
				moment(date.endDate).isBetween(validStartDate, validEndDate))
		) {
			onHandleFilterValueChange({
				key: 'startEffectiveDate',
				value: date.startDate
			})
			onHandleFilterValueChange({
				key: 'endEffectiveDate',
				value: date.endDate
			})
		}
	}, [
		date,
		endEffectiveDate,
		onHandleFilterValueChange,
		startEffectiveDate,
		validEndMonth,
		validStartMonth
	])

	return (
		<div className="TableFilter">
			<div className="wf-row--2-columns">
				<div className="wf-col--9">
					<div className="EffectiveDate">
						<div className="EffectiveDateSelect">
							<BasicLabelSelect
								layout={{
									width: 'wf-col--3'
								}}
								label={formatMessage({
									id: 'commitment-details-page-transaction-history.filter.select-effective-date'
								})}
								name="effectiveDate"
								onValueSelected={handleFilterTypeChange}
								choices={[
									{
										value: 'D',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.last-viewded'
										})
									},
									{
										value: '1',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.previous-day'
										})
									},
									{
										value: '7',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.last-seven-days'
										})
									},
									{
										value: '30',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.last-thirty-days'
										})
									},
									{
										value: 'customDate',
										label: formatMessage({
											id:
												'commitment-details-page-transaction-history.filter.select.dropdown.last-custom-date-range'
										})
									}
								]}
								value={effectiveDateType}
							/>
							<div className="DefaultCheckBox">
								<Checkbox
									checked={setAsDefault}
									label={formatMessage({
										id: 'commitment-details-page-transaction-history.filter.checkbox.set-as-default'
									})}
									name="setAsDefault"
									disabled={fromInternalSite || effectiveDateType === 'customDate'}
									onValueChange={handleSetAsDefaultCheckbox}
								/>
								<HelpButton
									kind="chromeless"
									helpBody={formatMessage({
										id: 'commitment-details-page-transaction-history.filter.tooltip.body'
									})}
									aria-label={formatMessage({
										id: 'commitment-details-page-transaction-history.filter.tooltip.hidden.text'
									})}
									centerIcon={<IconHelp size="small" />}
								/>
							</div>
						</div>
					</div>
					<div className="CustomDateRange" role="radio" aria-checked="false">
						<TransactionHistoryCustomDateRange
							effectiveDateType={effectiveDateType}
							date={date}
							formatMessage={formatMessage}
							onDateChange={onDateChange}
							validStartMonth={validStartMonth}
							validEndMonth={validEndMonth}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

function TransactionHistoryCustomDateRange({
	effectiveDateType,
	date,
	formatMessage,
	onDateChange,
	validStartMonth,
	validEndMonth
}) {
	if (effectiveDateType === 'customDate') {
		return (
			<CustomDateRange
				id="effectiveDate"
				applied={date}
				formatMessage={formatMessage}
				dateRange="9999"
				label=""
				reset={true}
				onDateRangeChange={onDateChange}
				minSelectableDate={new Date(validStartMonth)}
				maxSelectableDate={new Date(validEndMonth)}
				customStrings={{
					dateFormatMustBe: function() {
						return formatMessage({
							id: 'commitment-details-page-transaction-history.filter.validation.invalid.format'
						})
					},
					invalidStartEndDate: formatMessage({
						id: 'commitment-details-page-transaction-history.filter.validation.invalid.format'
					})
				}}
			/>
		)
	}
	return <BasicNoContent />
}

TransactionHistoryTableFilter.displayName = 'TransactionHistoryTableFilter'
export default TransactionHistoryTableFilter
