import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withUserDataForm } from 'ecpfs-react-data-driven-components'
import { AddNicknamePanel } from '../components/AddNicknamePanel'
import { submitCommitmentNickname, openAddNicknamePanel } from '../actions/commitment'

export const mapStateToProps = state => ({
	show: state.commitment.showAddNicknamePanel,
	tableParams: state.commitment.tableParams,
	commitmentRow: state.commitment.commitmentRow
})

export const mapDispatchToProps = dispatch => ({
	onSubmitCommitmentNickname(params, tableParams) {
		dispatch(submitCommitmentNickname(params, tableParams))
	},
	onOpenPanel(isOpen) {
		dispatch(openAddNicknamePanel(isOpen))
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withUserDataForm()(AddNicknamePanel))
)
component.displayName = 'AddNicknamePanel'

export default component
