import { combineReducers } from 'redux'

import { billingStatements } from './billingStatements'
import { commitment } from './commitment'
import { obligation } from './obligation'
import { systemMessages } from './systemMessages'
import { feedback as afsFeedback } from './feedback'
import { navigator } from './navigator'
import { siteLoader } from './siteLoader'
import { ledgers } from './ledgers'
import { profile } from './profile'

export default combineReducers({
	billingStatements,
	commitment,
	obligation,
	systemMessages,
	feedback: afsFeedback,
	navigator,
	siteLoader,
	ledgers,
	profile
})
