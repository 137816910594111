import React, { useContext, useEffect, useState } from 'react'
import {
	BasicSystemMessages,
	BasicNoContent,
	BrowserNavigationContext,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { MessageBar } from '@wf/ria'
import { getSystemMessageIconData } from '../utils/getSystemMessageIconData'

export function CustomSystemMessage({
	systemMessages = [],
	onUpdateSystemMessage,
	className = ''
}) {
	const {
		location,
		previousLocation = {
			pathname: ''
		}
	} = useContext(BrowserNavigationContext)
	const { pathname } = location
	const isPathnameChanged = !!(previousLocation.pathname && previousLocation.pathname !== pathname)
	const previousLocationPathname = previousLocation.pathname ? previousLocation.pathname : ''
	const { setMessages, getMessages } = useContext(SystemMessagesContext)
	const hasLocalMessages = systemMessages.length > 0

	useEffect(() => {
		if (pathname === '/' || isPathnameChanged || previousLocationPathname === '') {
			if (hasLocalMessages) {
				const updatedSystemMessages = systemMessages
					.map(message => {
						if (message.path !== previousLocationPathname && message.path === pathname) {
							return message
						}
						if (message.path === undefined) {
							const messageWithPath = {
								...message,
								path: pathname
							}
							return messageWithPath
						}
						return undefined
					})
					.filter(message => message !== undefined)
				if (JSON.stringify(systemMessages) !== JSON.stringify(updatedSystemMessages)) {
					onUpdateSystemMessage(updatedSystemMessages)
				}
			}
			if (getMessages) {
				const contextMessages = getMessages()
				if (contextMessages.length > 0) {
					const updatedContextMessages = contextMessages
						.map(message => {
							if (message.path !== previousLocationPathname && message.path === pathname) {
								return message
							}
							if (message.path === undefined) {
								const messageWithPath = {
									...message,
									path: pathname
								}
								return messageWithPath
							}
							return undefined
						})
						.filter(message => message !== undefined)
					if (JSON.stringify(contextMessages) !== JSON.stringify(updatedContextMessages)) {
						setMessages(updatedContextMessages)
						if (onUpdateSystemMessage) {
							onUpdateSystemMessage(updatedContextMessages)
						}
					}
				}
			}
		}
	}, [
		isPathnameChanged,
		hasLocalMessages,
		systemMessages,
		previousLocationPathname,
		pathname,
		onUpdateSystemMessage,
		getMessages,
		setMessages
	])

	if (hasLocalMessages) {
		const isMessageBar = systemMessages.find(message => message.type === 'messageBar')
		if (
			isMessageBar &&
			(systemMessages?.[0]?.icon === 'confirmation' || systemMessages?.[0].icon === 'error')
		) {
			return (
				<CustomMessageBar
					icon={systemMessages?.[0]?.icon}
					message={systemMessages?.[0]?.message}
					time={systemMessages?.[0]?.time}
				/>
			)
		}
		return (
			<div className={className}>
				<BasicSystemMessages messages={systemMessages} />
			</div>
		)
	} else {
		return <BasicNoContent />
	}
}

function CustomMessageBar({ message, icon, time }) {
	const [open, setOpen] = useState(true)
	const handleClose = () => {
		setOpen(false)
	}
	useEffect(() => {
		setOpen(true)
	}, [time])
	const iconComponent = getSystemMessageIconData(icon)?.iconComponent
	return (
		<div>
			<MessageBar open={open} onClose={handleClose} icon={iconComponent}>
				{message}
			</MessageBar>
		</div>
	)
}
