import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { HelpButton, Panel, Feedback } from '@wf/ria'
import { IconHelp } from '@wf/ria-icons'
import { BasicAction, BasicLabel, BasicLabelValue } from 'ecpfs-react-data-driven-components'
import { getPresentYear, getPreviousYear } from '../utils/getAfsDateHelpers'
import CustomPrintArea from './CustomPrintArea'
import CustomPrintButton from './CustomPrintButton'
import { getSystemMessageIconData } from '../utils/getSystemMessageIconData'

export function ViewInterestPanel({
	facilityKey,
	onGetViewInterestDetails,
	commitmentDetails,
	viewInterestDetails,
	viewInterestSystemMessages = {}
}) {
	const { formatMessage } = useIntl()
	const [panelOpen, setPanelOpen] = useState(false)
	const layout = {
		width: 'wf-row--padding',
		label: 'wf-col--6',
		field: 'wf-col--6'
	}

	function onClosePanel() {
		setPanelOpen(false)
	}

	function onOpenPanel() {
		onGetViewInterestDetails(facilityKey)
		setPanelOpen(true)
	}

	function formatWithCurrency(amount) {
		const currencyUsd = formatMessage({
			id: 'view-interest-panel.label.currency-usd'
		})
		if (amount === undefined || amount === null) {
			return ''
		}
		return `${amount} ${currencyUsd}`
	}

	const helpContentText = formatMessage({ id: 'view-interest-panel.label.interest-tooltip' })
	const helpContentUnpaidInterestText = formatMessage({
		id: 'view-interest-panel.label.unpaid-interest-tooltip'
	})
	const interestPaidHelpTooltip = formatMessage({
		id: 'view-interest-panel.label.interest-paid-help-button-tooltip'
	})
	const unPaidInterestHelpTooltip = formatMessage({
		id: 'view-interest-panel.label.unpaid-interest-help-button-tooltip'
	})

	return (
		<CustomPrintArea>
			<Panel
				onClose={onClosePanel}
				open={panelOpen}
				title={formatMessage({
					id: 'view-interest-panel.label.commitment-interest'
				})}
				className="ViewInterestPanelContainer"
				trigger={
					<BasicAction
						label={formatMessage({
							id: 'view-interest-panel.button.label.view-interest'
						})}
						className="ViewInterestButton"
						disabled={false}
						display="link"
						onClick={onOpenPanel}
					/>
				}
				footer={
					<div className="wf-row ViewModal">
						<BasicAction
							label={formatMessage({
								id: 'view-interest-panel.button.label.close'
							})}
							className="wf-col--10 CloseViewInterestPanel"
							display="standard"
							onClick={onClosePanel}
						/>
						<CustomPrintButton
							className="wf-col--2"
							label={formatMessage({
								id: 'view-interest-panel.button.label.print'
							})}
							showIcon={false}
						/>
					</div>
				}
			>
				<div className="ViewInterestPanel">
					<ViewInterestPanelContent
						viewInterestDetails={viewInterestDetails}
						viewInterestSystemMessages={viewInterestSystemMessages}
						formatMessage={formatMessage}
						layout={layout}
						formatWithCurrency={formatWithCurrency}
						helpContentText={helpContentText}
						interestPaidHelpTooltip={interestPaidHelpTooltip}
						unPaidInterestHelpTooltip={unPaidInterestHelpTooltip}
						helpContentUnpaidInterestText={helpContentUnpaidInterestText}
						commitmentDetails={commitmentDetails}
					/>
				</div>
			</Panel>
		</CustomPrintArea>
	)
}

export function ViewInterestPanelContent({
	formatMessage,
	layout,
	viewInterestDetails,
	formatWithCurrency,
	helpContentText,
	helpContentUnpaidInterestText,
	interestPaidHelpTooltip,
	unPaidInterestHelpTooltip,
	viewInterestSystemMessages,
	commitmentDetails
}) {
	if (viewInterestSystemMessages.message) {
		const { iconComponent, theme } = getSystemMessageIconData(viewInterestSystemMessages.icon)
		return (
			<Feedback icon={iconComponent} padding border role="alert" theme={theme}>
				{viewInterestSystemMessages.message}
			</Feedback>
		)
	}

	return (
		<>
			<BasicLabelValue
				className="CommitmentLabelValue"
				label={formatMessage({
					id: 'view-interest-panel.label.commitment'
				})}
				layout={{
					width: 'wf-row--padding',
					label: 'wf-col--3',
					field: 'wf-col--9'
				}}
				value={
					commitmentDetails.facilityNickname
						? `${commitmentDetails.facilityNickname} ${viewInterestDetails.commitment}`
						: viewInterestDetails.commitment
				}
			/>
			<div className="InterestPanel">
				<BasicLabel
					level={2}
					label={formatMessage({
						id: 'view-interest-panel.label.interest-paid'
					})}
				/>
				<div className="HelpButton">
					<HelpButton
						kind="chromeless"
						helpBody={helpContentText}
						aria-label={interestPaidHelpTooltip}
						centerIcon={<IconHelp size="small" />}
					/>
				</div>
			</div>
			<div className="AmountAlignment MaxWidth">
				<BasicLabelValue
					layout={layout}
					label={getPreviousYear()}
					value={formatWithCurrency(viewInterestDetails.previousYrInterest)}
				/>
				<BasicLabelValue
					layout={layout}
					className="MarginBottom"
					label={`${getPresentYear()}${formatMessage({
						id: 'view-interest-panel.label.year-to-date'
					})}`}
					value={formatWithCurrency(viewInterestDetails.currentYrInterest)}
				/>
			</div>
			<div className="InterestPanel">
				<BasicLabel
					level={2}
					label={formatMessage({
						id: 'view-interest-panel.label.unpaid-interest'
					})}
				/>
				<div className="HelpButton">
					<HelpButton
						kind="chromeless"
						helpBody={helpContentUnpaidInterestText}
						aria-label={unPaidInterestHelpTooltip}
						centerIcon={<IconHelp size="small" />}
					/>
				</div>
			</div>
			<div className="AmountAlignment UnpaidInterest">
				<BasicLabelValue
					layout={layout}
					label={formatMessage({
						id: 'view-interest-panel.label.current-accrued-unpaid-interest'
					})}
					value={formatWithCurrency(viewInterestDetails.unpaidInterest)}
				/>
				<BasicLabelValue
					layout={layout}
					className="MarginBottom"
					label={formatMessage({
						id: 'view-interest-panel.label.daily-interest-accrual'
					})}
					value={formatWithCurrency(viewInterestDetails.dailyInterest)}
				/>
			</div>
		</>
	)
}
