import { GET_LEDGERS_SUCCESS } from '../actions/ledgers'
import { DataLink } from 'ecpfs-react-data-driven-components'
import get from 'lodash.get'

export const initialState = {
	get ledgers() {
		return []
	},
	get selectedLedger() {
		return {
			get dataLink() {
				return {}
			},
			get label() {
				return ''
			}
		}
	},
	get featureFlags() {
		return {}
	}
}

export function ledgers(state = initialState, action = {}) {
	switch (action.type) {
		case GET_LEDGERS_SUCCESS:
			return {
				...state,
				ledgers: DataLink.getContainerContent(action.ledgers, []),
				selectedLedger: DataLink.getContainerContent(action.ledgers, [])[0],
				featureFlags: get(
					DataLink.getContainerContent(action.ledgers, []),
					'[0].userAllowedFeatures',
					{}
				)
			}
		default:
			return state
	}
}
