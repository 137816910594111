import React, { useContext, useState, useEffect } from 'react'
import {
	BasicLabel,
	BasicTable,
	BasicText,
	BasicNoContent,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { Button, Feedback, Tabs, Tab } from '@wf/ria'
import get from 'lodash.get'
import { config } from '../../package.json'
import CustomPrintArea from './CustomPrintArea'
import { getSystemMessageIconData } from '../utils/getSystemMessageIconData'
import ObligationDetailsPanel from './ObligationDetailsPanel'
import LoanIqBasicHeader from './LoanIqBasicHeader'
import isEmpty from 'lodash.isempty'
import { FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'
import { Fragment } from 'react'

const { apiContext } = config

// constants for tab names - values need to match what
// we get from the commitment detail tabs api
export const tabDetails = {
	loanTab: {
		key: 'loans',
		label: 'loan-summary.view-commitment-details.tab.label.loans'
	},
	obligationTab: {
		key: 'obligations',
		label: 'loan-summary.view-commitment-details.tab.label.obligations'
	},
	otherTab: {
		key: 'other-obligations',
		label: 'loan-summary.view-commitment-details.tab.label.other-obligations'
	},
	transactionTab: {
		key: 'transaction-history',
		label: 'loan-summary.view-commitment-details.tab.label.transaction-history'
	}
}

export function CommitmentDetailsTabs({
	dropdownValue,
	formatMessage,
	onGetCommitmentTableData,
	onApplyFilter,
	onResetCommitmentTableFilter,
	appliedFilters,
	onHandleFilterValueChange,
	tabs = [],
	facilityKey,
	commitmentSystemMessage = {},
	commitmentDetailsTableInfo = {},
	onUpdateSystemMessage,
	fromInternalSite = false,
	commitments = [],
	validStartMonth,
	validEndMonth,
	onClearCommitmentTableData,
	preferenceMonth,
	defaultTab,
	commitmentDetails
}) {
	const { clearMessages } = useContext(SystemMessagesContext)
	const modifyPermission = getModifyPermission()
	const tableFiltersConfig = getTableFilters({
		formatMessage,
		onApplyFilter,
		onResetCommitmentTableFilter,
		appliedFilters,
		onHandleFilterValueChange,
		facilityKey,
		onUpdateSystemMessage,
		clearMessages,
		validStartMonth,
		validEndMonth,
		onClearCommitmentTableData,
		fromInternalSite
	})

	function getModifyPermission() {
		if (commitments.length > 0) {
			return !!commitments
				.flatMap(commitment => commitment.facilityGroup)
				.find(facility => facility.encryptedKey === facilityKey)?.modifyPermission
		}
	}

	if (dropdownValue !== 'viewObligations') {
		return <BasicNoContent />
	}

	return (
		<div className="CommitmentDetailsTabs">
			<BasicLabel
				level={2}
				label={formatMessage({
					id: 'view-commitment-details.label.obligations'
				})}
			/>
			<CommitmentDetailsTabsHeader
				commitmentDetailsTableInfo={commitmentDetailsTableInfo}
				formatMessage={formatMessage}
			/>
			<RenderTabs
				tabs={tabs}
				clearMessages={clearMessages}
				onUpdateSystemMessage={onUpdateSystemMessage}
				onGetCommitmentTableData={onGetCommitmentTableData}
				facilityKey={facilityKey}
				tabDetails={tabDetails}
				preferenceMonth={preferenceMonth}
				tableFiltersConfig={tableFiltersConfig}
				modifyPermission={modifyPermission}
				commitmentDetailsTableInfo={commitmentDetailsTableInfo}
				commitmentSystemMessage={commitmentSystemMessage}
				formatMessage={formatMessage}
				defaultTab={defaultTab}
				fromInternalSite={fromInternalSite}
				commitmentDetails={commitmentDetails}
			/>
		</div>
	)
}

const RenderTabs = ({
	tabs,
	tabDetails,
	clearMessages,
	onUpdateSystemMessage,
	onGetCommitmentTableData,
	facilityKey,
	preferenceMonth,
	tableFiltersConfig,
	modifyPermission,
	commitmentDetailsTableInfo,
	commitmentSystemMessage,
	formatMessage,
	defaultTab,
	fromInternalSite,
	commitmentDetails
}) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)
	const params = get(commitmentDetailsTableInfo, 'dataLink.params', {})
	const { isSingleCustomer = false } = params
	const tableColumnConfig = getTableColumns(
		isSingleCustomer,
		fromInternalSite,
		modifyPermission,
		formatMessage,
		tabs[selectedTabIndex]
	)
	useEffect(() => {
		if (defaultTab) {
			const selectedtab = tabs.findIndex(tab => tab === defaultTab)
			setSelectedTabIndex(selectedtab)
		} else {
			setSelectedTabIndex(0)
		}
	}, [facilityKey, defaultTab, tabs])

	if (tabs.length === 0) {
		return <BasicNoContent />
	}

	function tabChanged(tabIndex) {
		setSelectedTabIndex(tabIndex)
		clearMessages()
		onUpdateSystemMessage([])
		onGetCommitmentTableData(tabs[tabIndex], facilityKey, { rowsOffset: 0 })
	}

	return (
		<Tabs selectedTab={selectedTabIndex} onSelectedTabChange={tabChanged}>
			{tabs.map(tabKey => {
				const tab =
					tabDetails[Object.keys(tabDetails).find(tabName => tabDetails[tabName].key === tabKey)]
				return (
					<DisplayTab
						formatMessage={formatMessage}
						labelKey={tab.label}
						isSelected={tabs[selectedTabIndex] === tab?.key}
						primaryKey="encryptedKey"
						columns={get(tableColumnConfig, `${tab.key}.columns`, {})}
						extraContent={get(tableColumnConfig, `${tab.key}.extraContent`, {})}
						filter={get(tableFiltersConfig, `${tab.key}`, { show: false })}
						selectedTab={tabs[selectedTabIndex]}
						facilityKey={facilityKey}
						commitmentSystemMessage={commitmentSystemMessage}
						commitmentDetailsTableInfo={commitmentDetailsTableInfo}
						clearMessages={clearMessages}
						onUpdateSystemMessage={onUpdateSystemMessage}
						onGetCommitmentTableData={onGetCommitmentTableData}
						commitmentDetailsSelectedTab={tabs[selectedTabIndex]}
						preferenceMonth={preferenceMonth}
						commitmentDetails={commitmentDetails}
					/>
				)
			})}
		</Tabs>
	)
}

function DisplayTab({
	formatMessage,
	labelKey,
	isSelected,
	primaryKey,
	columns,
	extraContent,
	filter,
	selectedTab,
	facilityKey,
	commitmentSystemMessage,
	commitmentDetailsTableInfo,
	clearMessages,
	onUpdateSystemMessage,
	onGetCommitmentTableData,
	commitmentDetailsSelectedTab,
	preferenceMonth,
	commitmentDetails
}) {
	const filterData = get(commitmentDetailsTableInfo, 'dataLink.params.userData.filterData', {})
	const footer = {
		content: [
			{
				tag: 'CustomPrintButton',
				event: 'DISE0020',
				label: 'Print',
				showIcon: false
			},
			{
				tag: 'CustomMenuDropDown',
				event: 'DISE0021',
				display: 'standard',
				label: formatMessage({
					id: 'commitment-details.tabs.label.export'
				}),
				className: 'ExportTable',
				items: [
					{
						label: formatMessage({
							id: 'commitment-details.tabs.label.csv'
						}),
						name: 'csv',
						dataLink: {
							download: true,
							api: `${apiContext}/facilityDetailExport`,
							params: {
								exportRecordKey: {
									encryptedKey: facilityKey
								},
								reportName: selectedTab,
								exportType: 'csv',
								productCategory: 'export',
								userData: { filterData }
							}
						}
					},
					{
						label: formatMessage({
							id: 'commitment-details.tabs.label.excel'
						}),
						name: 'excel',
						dataLink: {
							download: true,
							api: `${apiContext}/facilityDetailExport`,
							params: {
								exportRecordKey: {
									encryptedKey: facilityKey
								},
								reportName: selectedTab,
								exportType: 'xls',
								productCategory: 'export',
								userData: { filterData }
							}
						}
					}
				]
			}
		]
	}

	return (
		<Tab title={formatMessage({ id: labelKey })}>
			<DisplayTable
				show={isSelected}
				primaryKey={primaryKey}
				columns={columns}
				extraContent={extraContent}
				filter={filter}
				footer={footer}
				commitmentSystemMessage={commitmentSystemMessage}
				commitmentDetailsTableInfo={commitmentDetailsTableInfo}
				facilityKey={facilityKey}
				formatMessage={formatMessage}
				clearMessages={clearMessages}
				onUpdateSystemMessage={onUpdateSystemMessage}
				selectedTab={selectedTab}
				onGetCommitmentTableData={onGetCommitmentTableData}
				commitmentDetailsSelectedTab={commitmentDetailsSelectedTab}
				preferenceMonth={preferenceMonth}
				commitmentDetails={commitmentDetails}
			/>
		</Tab>
	)
}

function DisplayTable({
	show,
	primaryKey,
	columns,
	extraContent,
	filter,
	footer,
	commitmentSystemMessage,
	commitmentDetailsTableInfo,
	commitmentDetails,
	facilityKey,
	formatMessage,
	clearMessages,
	onUpdateSystemMessage,
	selectedTab,
	onGetCommitmentTableData,
	commitmentDetailsSelectedTab,
	preferenceMonth
}) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedObligation, setSelectedObligation] = useState({})
	const [source, setSource] = useState('')
	function addObligationLinkToObligationColumn(columns) {
		const columnsUpdated = [...columns]

		const obligationColumn = columnsUpdated.find(column => column.name === 'obligationId')

		obligationColumn.formatter = ({ item }) => {
			return (
				<Button
					kind="simple"
					underlined
					label={item.obligationId}
					onClick={() => {
						setSelectedObligation(item)
						setSource({
							table: selectedTab,
							column: 'obligationId'
						})
						setIsModalOpen(true)
					}}
				/>
			)
		}

		return columnsUpdated
	}
	if (!show) return <BasicNoContent />
	const columnsWithObligationLink = addObligationLinkToObligationColumn(columns)

	function onPagination({ rowsOffset, rowsBatchSize }) {
		const params = get(commitmentDetailsTableInfo, 'dataLink.params', {})
		const filterData = get(commitmentDetailsTableInfo, 'dataLink.params.userData.filterData', {})
		clearMessages()
		onUpdateSystemMessage([])
		onGetCommitmentTableData(
			selectedTab,
			facilityKey,
			{ ...params, rowsOffset, rowsBatchSize },
			filterData
		)
	}

	function onColumnSort(paginationParams) {
		const params = get(commitmentDetailsTableInfo, 'dataLink.params', {})
		const filterData = get(commitmentDetailsTableInfo, 'dataLink.params.userData.filterData', {})
		clearMessages()
		onUpdateSystemMessage([])
		onGetCommitmentTableData(
			selectedTab,
			facilityKey,
			{ ...params, ...paginationParams },
			filterData
		)
	}

	return (
		<CustomPrintArea>
			<Note
				currentTab={commitmentDetailsSelectedTab}
				formatMessage={formatMessage}
				preferenceMonth={preferenceMonth}
				commitmentDetailsTableInfo={commitmentDetailsTableInfo}
			/>
			<CommitmentSystemMessage commitmentSystemMessage={commitmentSystemMessage} />
			<AsOfDateAndTime
				currentTab={commitmentDetailsSelectedTab}
				formatMessage={formatMessage}
				commitmentDetailsTableInfo={commitmentDetailsTableInfo}
			/>
			<CommitmentsTable
				primaryKey={primaryKey}
				columnsWithObligationLink={columnsWithObligationLink}
				extraContent={extraContent}
				commitmentDetailsTableInfo={commitmentDetailsTableInfo}
				onPagination={onPagination}
				filter={filter}
				footer={footer}
				onColumnSort={onColumnSort}
				formatMessage={formatMessage}
			/>
			<ObligationDetailsPanel
				formatMessage={formatMessage}
				selectedObligation={selectedObligation}
				source={source}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				commitmentDetailsSelectedTab={commitmentDetailsSelectedTab}
			/>
			<CommitmentDetailsFooterContent
				currentTab={commitmentDetailsSelectedTab}
				formatMessage={formatMessage}
				commitmentDetails={commitmentDetails}
			/>
		</CustomPrintArea>
	)
}

function CommitmentSystemMessage({ commitmentSystemMessage }) {
	if (isEmpty(commitmentSystemMessage)) {
		return <BasicNoContent />
	}
	const { iconComponent, theme } = getSystemMessageIconData(commitmentSystemMessage.icon, 'medium')
	return (
		<div className="CommitmentSystemMessage">
			<Feedback icon={iconComponent} size="large" role="alert" padding border theme={theme}>
				{commitmentSystemMessage.message}
			</Feedback>
		</div>
	)
}

function CommitmentsTable({
	primaryKey,
	columnsWithObligationLink,
	extraContent,
	commitmentDetailsTableInfo,
	onPagination,
	filter,
	footer,
	onColumnSort,
	formatMessage
}) {
	const params = get(commitmentDetailsTableInfo, 'dataLink.params', {})
	const { hideTable = false } = params
	if (hideTable) {
		return <BasicNoContent />
	}

	return (
		<BasicTable
			className={'CommitmentDetailsTable'}
			ariaCaption={formatMessage({
				id: 'commitment-details.tabs.label.table.aria-caption'
			})}
			primaryKey={primaryKey}
			columns={columnsWithObligationLink}
			extraContent={extraContent}
			rows={commitmentDetailsTableInfo}
			pagination={getTablePagination(commitmentDetailsTableInfo, onPagination)}
			filter={filter}
			footer={footer}
			onSorted={onColumnSort}
			messages={{
				collapsedRowAnnouncement: (itemKey, item) =>
					`${formatMessage({
						id: 'basic-table.label.more-about'
					})} ${item.obligationId}${formatMessage({
						id: 'basic-table.label.collapsed'
					})}`,
				expandedRowAnnouncement: (itemKey, item) =>
					`${formatMessage({
						id: 'basic-table.label.more-about'
					})} ${item.obligationId}${formatMessage({
						id: 'basic-table.label.expanded'
					})}`,
				rowExpandButtonLabel: (itemKey, { item, expanded }) =>
					`${
						expanded
							? formatMessage({
									id: 'commitment-details.tabs.label.table.collapse'
							  })
							: formatMessage({
									id: 'commitment-details.tabs.label.table.expand'
							  })
					} ${formatMessage({
						id: 'basic-table.label.more-about'
					})} ${item.obligationId}`,
				rowExpandedDetails: (itemKey, item) =>
					`${item.obligationId} ${formatMessage({
						id: 'basic-table.label.expanded-details'
					})}`
			}}
		/>
	)
}

export function getTablePagination(commitmentDetailsTableInfo, onPagination) {
	const {
		dataLink: { params = {} }
	} = commitmentDetailsTableInfo
	const { rowsOffset = 0, rowsBatchSize = 0, rowsCountOnServer = 0 } = params
	return {
		show: true,
		rowsOffset,
		rowsCountOnServer,
		rowsBatchSize,
		totalItems: get(commitmentDetailsTableInfo, 'rows', []).length,
		onChange: onPagination,
		paginationClassName: 'wf-col--4 label-right'
	}
}

function getTableColumns(
	singleCustomerView,
	fromInternalSite,
	modifyPermission,
	formatMessage,
	commitmentDetailsSelectedTab
) {
	const columnsByTab = {
		[tabDetails.loanTab.key]: {
			columns: [
				{
					key: 'obligationId',
					name: 'obligationId',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.obligation'
					}),
					sort: true,
					collapse: 7
				},
				{
					name: 'obligationNickname',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.nickname'
					}),
					collapse: 3
				},
				{
					name: 'customer',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.customer'
					}),
					tag: 'CustomCustomerNumberColumn',
					sort: true,
					collapse: 1
				},
				{
					name: 'interestRate',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.interest-rate'
					}),
					sort: true,
					collapse: 4
				},
				{
					name: 'description',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.description'
					}),
					sort: true,
					collapse: 2
				},
				{
					name: 'outstandingPrincipalBalance',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.outstanding-principal'
					}),
					sort: true,
					collapse: 5,
					align: 'right'
				},
				{
					label: formatMessage({ id: 'loan-summary.view-commitment-details.table.column.action' }),
					name: 'action',
					collapse: 6,
					actions: [
						{
							tag: 'CustomObligationMenu',
							label: formatMessage({ id: 'loan-summary.view-commitment-details.table.select' }),
							display: 'simple',
							commitmentDetailsSelectedTab
						}
					]
				}
			],
			extraContent: {
				tooltip: {
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.more-about.tooltip.label'
					}),
					column: 'obligationId'
				},
				content: () => {
					return [
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'maturityDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.maturity-date'
							}),
							className: 'AFSTableExtraContents'
						},
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'actionDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.action-date'
							}),
							className: 'AFSTableExtraContents'
						},
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'rateMaturityDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.rate-maturity-date'
							}),
							className: 'AFSTableExtraContents'
						}
					]
				}
			}
		},
		[tabDetails.obligationTab.key]: {
			columns: [
				{
					key: 'obligationId',
					name: 'obligationId',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.obligation'
					}),
					sort: true,
					collapse: 6,
					actions: [
						{
							tag: 'ObligationDetailsPanel',
							formatMessage: formatMessage
						}
					]
				},
				{
					name: 'obligationNickname',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.nickname'
					}),
					sort: false,
					collapse: 3
				},
				{
					name: 'customer',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.customer'
					}),
					tag: 'CustomCustomerNumberColumn',
					sort: true,
					collapse: 1
				},
				{
					name: 'description',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.description'
					}),
					sort: true,
					collapse: 2
				},
				{
					name: 'outstandingPrincipalBalance',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.balance'
					}),
					sort: true,
					collapse: 4,
					align: 'right'
				},
				{
					label: formatMessage({ id: 'loan-summary.view-commitment-details.table.column.action' }),
					name: 'action',
					collapse: 5,
					actions: [
						{
							tag: 'CustomObligationMenu',
							label: formatMessage({ id: 'loan-summary.view-commitment-details.table.select' }),
							display: 'simple',
							commitmentDetailsSelectedTab
						}
					]
				}
			],
			extraContent: {
				tooltip: {
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.more-about.tooltip.label'
					}),
					column: 'obligationId'
				},
				content: () => {
					return [
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'letterOfCreditNumber',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.letter-of-credit-number'
							}),
							className: 'AFSTableExtraContents'
						},
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'maturityDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.maturity-date'
							}),
							className: 'AFSTableExtraContents'
						},
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'actionDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.action-date'
							}),
							className: 'AFSTableExtraContents'
						},
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'rateMaturityDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.rate-maturity-date'
							}),
							className: 'AFSTableExtraContents'
						}
					]
				}
			}
		},
		[tabDetails.otherTab.key]: {
			columns: [
				{
					key: 'obligationId',
					name: 'obligationId',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.obligation'
					}),
					sort: true,
					collapse: 6,
					actions: [
						{
							tag: 'ObligationDetailsPanel',
							formatMessage: formatMessage
						}
					]
				},
				{
					name: 'obligationNickname',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.nickname'
					}),
					sort: false,
					collapse: 3
				},
				{
					name: 'customer',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.customer'
					}),
					tag: 'CustomCustomerNumberColumn',
					sort: true,
					collapse: 1
				},
				{
					name: 'description',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.description'
					}),
					sort: true,
					collapse: 2
				},
				{
					name: 'outstandingPrincipalBalance',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.balance'
					}),
					sort: true,
					collapse: 4,
					align: 'right'
				},
				{
					label: formatMessage({ id: 'loan-summary.view-commitment-details.table.column.action' }),
					name: 'action',
					collapse: 5,
					actions: [
						{
							tag: 'CustomObligationMenu',
							label: formatMessage({ id: 'loan-summary.view-commitment-details.table.select' }),
							display: 'simple',
							commitmentDetailsSelectedTab
						}
					]
				}
			],
			extraContent: {
				tooltip: {
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.more-about.tooltip.label'
					}),
					column: 'obligationId'
				},
				content: () => {
					return [
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'letterOfCreditNumber',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.letter-of-credit-number'
							}),
							className: 'AFSTableExtraContents'
						},
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'maturityDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.maturity-date'
							}),
							className: 'AFSTableExtraContents'
						},
						{
							tag: 'CustomFacilityBasicLabelValue',
							name: 'actionDate',
							label: formatMessage({
								id: 'loan-summary.view-commitment-details.table.extra.action-date'
							}),
							className: 'AFSTableExtraContents'
						}
					]
				}
			}
		},
		[tabDetails.transactionTab.key]: {
			columns: [
				{
					name: 'customer',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.customer'
					}),
					tag: 'CustomCustomerNumberColumn',
					sort: true,
					orderByField: '',
					collapse: 1
				},
				{
					key: 'obligationId',
					name: 'obligationId',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.obligation'
					}),
					sort: false,
					collapse: 3,
					actions: [
						{
							tag: 'ObligationDetailsPanel',
							formatMessage: formatMessage
						}
					]
				},
				{
					name: 'obligationNickname',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.nickname'
					}),
					sort: false,
					collapse: 3
				},
				{
					name: 'effectiveDate',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.effective-date'
					}),
					sort: true,
					orderByField: '',
					collapse: 6
				},
				{
					name: 'transactionType',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.transaction-type'
					}),
					sort: true,
					orderByField: '',
					collapse: 4
				},
				{
					name: 'transactionAmount',
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.column.transaction-amount'
					}),
					sort: true,
					orderByField: '',
					collapse: 5,
					align: 'right'
				}
			],
			extraContent: {
				tooltip: {
					label: formatMessage({
						id: 'loan-summary.view-commitment-details.table.more-about.tooltip.label'
					}),
					column: 'effectiveDate'
				}
			}
		}
	}

	if (singleCustomerView) {
		Object.keys(columnsByTab).forEach(key => {
			columnsByTab[key].columns = columnsByTab[key].columns.filter(
				column => column.name !== 'customer'
			)
		})
	}
	if (fromInternalSite || !modifyPermission) {
		Object.keys(columnsByTab).forEach(key => {
			columnsByTab[key].columns = columnsByTab[key].columns.filter(
				column => column.name !== 'action'
			)
		})
	}

	return columnsByTab
}

function Note({ currentTab, formatMessage, preferenceMonth, commitmentDetailsTableInfo }) {
	if (currentTab === 'transaction-history') {
		return (
			<Fragment>
				<div className="CommitmentDetailsTabsNote">
					<BasicText
						text={formatMessage({ id: 'commitment-details-page-table.tansaction-history.note' })}
					/>
				</div>
				<div className="NoTransactionMesssage">
					<TransactionDateRangeMessage
						preferenceMonth={preferenceMonth}
						commitmentDetailsTableInfo={commitmentDetailsTableInfo}
						formatMessage={formatMessage}
					/>
				</div>
			</Fragment>
		)
	}
	return <BasicNoContent />
}

function TransactionDateRangeMessage({ preferenceMonth, commitmentDetailsTableInfo }) {
	const params = get(commitmentDetailsTableInfo, 'dataLink.params', {})
	const { hideTable = false, earliestTxnEffectiveDate = '' } = params
	if (hideTable || !earliestTxnEffectiveDate || earliestTxnEffectiveDate === null) {
		return (
			<FormattedMessage
				id="commitment-details-page-table.tansaction-history.note.no-transaction"
				values={{
					preferenceMonth: preferenceMonth
				}}
			/>
		)
	}
	return (
		<FormattedMessage
			id="commitment-details-page-table.tansaction-history.note.earliest-transaction"
			values={{
				preferenceMonth: preferenceMonth,
				validStartMonth: earliestTxnEffectiveDate
			}}
		/>
	)
}

function AsOfDateAndTime({ currentTab, formatMessage, commitmentDetailsTableInfo }) {
	const params = get(commitmentDetailsTableInfo, 'dataLink.params', {})
	const { hideTable = false } = params
	if (currentTab === 'transaction-history' && !hideTable) {
		return (
			<LoanIqBasicHeader
				description={`${formatMessage({
					id: 'commitment-details-page-table.tansaction-history.current.date-time'
				})} ${moment()
					.tz('America/Los_Angeles')
					.format('MM/DD/YYYY hh:mm a')} PT`}
			/>
		)
	}
	return <BasicNoContent />
}

function getTableFilters({
	formatMessage,
	onApplyFilter,
	appliedFilters = {},
	onHandleFilterValueChange,
	facilityKey,
	validStartMonth,
	validEndMonth,
	onClearCommitmentTableData,
	fromInternalSite
}) {
	function applyFilterData(tabKey) {
		const {
			setAsDefault,
			effectiveDateType,
			startEffectiveDate = validStartMonth,
			endEffectiveDate = validEndMonth
		} = appliedFilters
		const filterData =
			effectiveDateType !== 'customDate'
				? { filterType: effectiveDateType, setAsDefault }
				: { startEffectiveDate, endEffectiveDate }
		onApplyFilter(tabKey, facilityKey, { rowsOffset: 0 }, filterData)
	}
	const isApplied = Object.keys(appliedFilters).length > 0

	return {
		[tabDetails.transactionTab.key]: {
			show: true,
			isApplied,
			label: formatMessage({
				id: 'commitment-details-page-filter.title'
			}),
			appliedLabel: formatMessage({
				id: 'commitment-details-page-filter.applied-label'
			}),
			ariaLabel: formatMessage({
				id: 'commitment-details-page-filter.aria-label'
			}),
			apply: {
				label: formatMessage({
					id: 'commitment-details-page-filter.apply-button'
				}),
				onClick() {
					onClearCommitmentTableData()
					applyFilterData(tabDetails.transactionTab.key)
				}
			},
			cancel: {
				label: formatMessage({
					id: 'commitment-details-page-filter.cancel-button'
				})
			},
			content: [
				{
					tag: 'TransactionHistoryTableFilter',
					name: 'TransactionHistoryTableFilter',
					formatMessage: formatMessage,
					appliedFilters: appliedFilters,
					onHandleFilterValueChange: onHandleFilterValueChange,
					validEndMonth: validEndMonth,
					validStartMonth: validStartMonth,
					fromInternalSite: fromInternalSite
				}
			],
			help: {
				helpBody: formatMessage({
					id: 'commitment-details-page-transaction-history.filter.help.body'
				}),
				helpAriaLabel: formatMessage({
					id: 'commitment-details-page-transaction-history.filter.help.aria-label'
				})
			}
		}
	}
}

function CommitmentDetailsTabsHeader({ formatMessage, commitmentDetailsTableInfo }) {
	const rowsLength = commitmentDetailsTableInfo?.rows?.length
	if (rowsLength === 0) {
		return <BasicNoContent />
	}
	return (
		<div className="CommitmentDetailsTabsHeader">
			<BasicText
				text={formatMessage({ id: 'commitment-details-page-table.expand-instructions' })}
			/>
		</div>
	)
}

function CommitmentDetailsFooterContent({ currentTab, formatMessage, commitmentDetails }) {
	if (currentTab !== 'transaction-history') {
		return (
			<>
				<div className="FooterContent">
					<b>
						{formatMessage({
							id:
								'loan-summary.view-commitment-details.table.footer.content.action-date-or-currentRateMaturity-date.bold.text'
						})}
					</b>
					<span>
						{formatMessage({
							id:
								'loan-summary.view-commitment-details.table.footer.content.action-date-or-rateMaturity-date'
						})}
					</span>
					<BorrowingBaseNote commitmentDetails={commitmentDetails} formatMessage={formatMessage} />
				</div>
				<hr />
			</>
		)
	}
	return <BasicNoContent />
}

function BorrowingBaseNote({ commitmentDetails, formatMessage }) {
	if (commitmentDetails.commitmentMsgReqFlag) {
		return (
			<span>
				{formatMessage({
					id: 'loan-summary.view-commitment-details.table.footer.content.borrowing-base-note'
				})}
			</span>
		)
	} else {
		return <BasicNoContent />
	}
}
