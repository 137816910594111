/**
 * This function is used to trigger a blur event on all elements that
 * match the selector provided and have a value set.
 *
 * This is used to resolve a problem with forms loading with pre-set data
 * but not triggering validation logic. There is a solution in the works
 * that involves RIA and RDD updates. This is a short term stop-gap until then.
 */
function getEventObject() {
	// ie compatibility
	if (typeof Event === 'function') {
		return new Event('blur')
	} else {
		const event = document.createEvent('Event')
		event.initEvent('blur', true, true)
		return event
	}
	// end ie compatibility
}

export function blurFields(selector) {
	const fields = document.querySelectorAll(selector)
	fields.forEach(field => {
		if (field) {
			const event = getEventObject()
			field.dispatchEvent(event)
		}
	})
}
