import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { BasicAction, SystemMessagesContext } from 'ecpfs-react-data-driven-components'
import { useIntl } from 'react-intl'
import { downloadInvoice } from '../actions/billingStatements'
import { updateSystemMessage } from '../actions/systemMessages'

export function CustomInvoiceDownloadColumn({ rowData }) {
	const { formatMessage } = useIntl()
	const dispatch = useDispatch()
	const { invoiceNumber, encryptedKey } = rowData.item
	const { clearMessages } = useContext(SystemMessagesContext)
	const downloadMessage = formatMessage({
		id: 'custom-invoice-download-column.label.download-message'
	})
	return (
		<BasicAction
			className="DownloadInvoiceLink"
			label={invoiceNumber}
			ariaLabel={`${downloadMessage} ${invoiceNumber}`}
			onClick={() => {
				clearMessages()
				dispatch(updateSystemMessage([]))
				dispatch(downloadInvoice(encryptedKey, formatMessage))
				window.scrollTo(0, 0)
			}}
			display="link"
		/>
	)
}

CustomInvoiceDownloadColumn.propTypes = {
	rowData: PropTypes.object
}

CustomInvoiceDownloadColumn.displayName = 'CustomInvoiceDownloadColumn'

export default CustomInvoiceDownloadColumn
