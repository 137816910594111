import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withUserDataForm } from 'ecpfs-react-data-driven-components'
import { EditObligationNicknamePanel } from '../components/EditObligationNicknamePanel'
import { submitObligationNickname, openEditObligationNicknamePanel } from '../actions/obligation'

export const mapStateToProps = state => ({
	show: state.obligation.showEditObligationNicknamePanel,
	obligationRow: state.obligation.obligationRow
})

export const mapDispatchToProps = dispatch => ({
	onSubmitObligationNickname(params, commitmentDetailsSelectedTab) {
		dispatch(submitObligationNickname(params, commitmentDetailsSelectedTab))
	},
	onOpenPanel(isOpen) {
		dispatch(openEditObligationNicknamePanel(isOpen))
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withUserDataForm()(EditObligationNicknamePanel))
)
component.displayName = 'EditObligationNicknamePanel'

export default component
