import React from 'react'
import { BasicNoContent, BasicText } from 'ecpfs-react-data-driven-components'

function LoanIqBasicHeader({ labels, description }) {
	return (
		<div className="wf-row">
			<div className="CustomLIQTableHeaderLabels wf-col wf-u-text-align-start">
				<CustomLIQTableHeaderLabels labels={labels} />
			</div>
			<div className="CustomLIQTableHeaderDescription wf-col wf-u-text-align-right">
				<BasicText text={description} />
			</div>
		</div>
	)
}

function CustomLIQTableHeaderLabels({ labels }) {
	if (!labels) {
		return <BasicNoContent />
	}

	return labels.map(label => {
		return (
			<h2 key={label} className="wf-header-secondary">
				<strong>{label}</strong>
			</h2>
		)
	})
}

LoanIqBasicHeader.displayName = 'LoanIqBasicHeader'

export default LoanIqBasicHeader
