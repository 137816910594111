import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Panel } from '@wf/ria'
import { FormattedMessage } from 'react-intl'
import { BasicAction, BasicNoContent } from 'ecpfs-react-data-driven-components'
import CustomPrintArea from './CustomPrintArea'
import CustomPrintButton from './CustomPrintButton'

export function ViewAllCustomersPanel({ formatMessage, commitmentNumber, show = false }) {
	const [panelOpen, setPanelOpen] = useState(false)
	const customersList = useSelector(state => state.commitment.customersList)

	if (!show) {
		return <BasicNoContent />
	}

	function onClosePanel() {
		setPanelOpen(false)
	}

	function onOpenPanel() {
		setPanelOpen(true)
	}

	return (
		<CustomPrintArea>
			<Panel
				onClose={onClosePanel}
				open={panelOpen}
				title={formatMessage({
					id: 'view-all-customers-panel.label.customers'
				})}
				trigger={
					<BasicAction
						label={formatMessage({
							id: 'view-all-customers-panel.label.view-all-customers'
						})}
						className="ViewAllCustomersPanelButton"
						disabled={false}
						display="link"
						onClick={onOpenPanel}
					/>
				}
				footer={
					<div className="wf-row ViewModal">
						<BasicAction
							label={formatMessage({
								id: 'view-all-customers-panel.button.label.close'
							})}
							className="wf-col--10 CloseViewAllCustomersPanel"
							display="standard"
							onClick={onClosePanel}
						/>
						<CustomPrintButton
							className="wf-col--2"
							label={formatMessage({
								id: 'view-all-customers-panel.button.label.print'
							})}
							showIcon={false}
						/>
					</div>
				}
			>
				<div className="ViewAllCustomersPanel">
					<AllCustomersPanelContent
						labelId="view-all-customers-panel.label.commitment"
						value={commitmentNumber}
					/>
					<AllCustomersPanelContent
						labelId="view-all-customers-panel.label.customers"
						value={customersList.map((customer, index) => {
							return (
								<div key={index} className="CustomerMarginBottom">
									{`${customer.customerName} ...${customer.customerNumber.substr(-4)}`}
								</div>
							)
						})}
					/>
				</div>
			</Panel>
		</CustomPrintArea>
	)
}

export function AllCustomersPanelContent({ labelId, value }) {
	return (
		<dl className="wf-dl-inline">
			<dt>
				<FormattedMessage id={labelId} />
			</dt>
			<dd>{value}</dd>
		</dl>
	)
}

ViewAllCustomersPanel.displayName = 'ViewAllCustomersPanel'

export default ViewAllCustomersPanel
