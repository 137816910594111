import React from 'react'
import { useSelector } from 'react-redux'
import {
	BasicDivider,
	BasicLabel,
	BasicLabelValue,
	BasicNoContent,
	BasicOverview
} from 'ecpfs-react-data-driven-components'
import isEmpty from 'lodash.isempty'
import ViewAllCustomersPanel from '../components/ViewAllCustomersPanel'

export function CommitmentDetailsOverview({ formatMessage, commitmentDetails }) {
	const showCustomersPanel = useSelector(state => state.commitment.showCustomersPanel)

	if (isEmpty(commitmentDetails)) {
		return <BasicNoContent />
	}

	return (
		<div className="CommitmentDetailsOverview">
			<div data-wf-efmya-commitment-section className="wf-row--padding">
				<CustomerSection
					commitmentDetails={commitmentDetails}
					formatMessage={formatMessage}
					showCustomersPanel={showCustomersPanel}
				/>
				<CommitmentSection commitmentDetails={commitmentDetails} formatMessage={formatMessage} />
				<BalancesSection commitmentDetails={commitmentDetails} formatMessage={formatMessage} />
			</div>
		</div>
	)
}

export function CustomerSection({ commitmentDetails, formatMessage, showCustomersPanel }) {
	const content = [
		<BasicLabel
			level={2}
			label={formatMessage({
				id: 'commitment-details-overview.label.customer'
			})}
		/>,
		<BasicDivider />,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.customer-name'
			})}
			value={commitmentDetails.customerName}
		/>,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.customer-number'
			})}
			value={
				commitmentDetails.borrowerid ? `...${commitmentDetails.borrowerid.substr(-4)}` : undefined
			}
		/>,
		<ViewAllCustomersPanel
			formatMessage={formatMessage}
			commitmentNumber={commitmentDetails.facilityNumber}
			show={showCustomersPanel}
		/>,
		<CustomBasicLabelValue
			hide={
				commitmentDetails.realEstateNumber === null || commitmentDetails.realEstateNumber === ''
			}
			label={formatMessage({
				id: 'commitment-details-overview.label.real-estate-number'
			})}
			value={commitmentDetails.realEstateNumber}
		/>
	]

	return (
		<BasicOverview
			className="wf-col--desk-4 wf-col--tab-4 wf-col--mob-12"
			content={content}
		></BasicOverview>
	)
}

export function CommitmentSection({ commitmentDetails, formatMessage }) {
	const content = [
		<BasicLabel
			level={2}
			label={formatMessage({
				id: 'commitment-details-overview.label.commitment'
			})}
		/>,
		<BasicDivider />,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.commitment'
			})}
			value={commitmentDetails.facilityNumber}
		/>,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.nickname'
			})}
			value={commitmentDetails.facilityNickname}
		/>,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.commitment-type'
			})}
			value={commitmentDetails.facilityType}
		/>,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.maturity-date'
			})}
			value={commitmentDetails.maturityDate}
		/>,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.next-action-date'
			})}
			value={commitmentDetails.actionDate}
		/>,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.next-rate-maturity-date'
			})}
			value={commitmentDetails.nextRateOptionMaturityDate}
		/>,
		<CustomBasicLabelValue
			label={formatMessage({
				id: 'commitment-details-overview.label.origination-date'
			})}
			value={commitmentDetails.effectiveDate}
		/>
	]

	return (
		<BasicOverview
			className="wf-col--desk-4 wf-col--tab-4 wf-col--mob-12"
			content={content}
		></BasicOverview>
	)
}

export function BalancesSection({ commitmentDetails, formatMessage }) {
	function getAmount(stringValue = '') {
		return Number(stringValue.replace(/,/g, ''))
	}
	const content = [
		<BasicLabel
			level={2}
			label={formatMessage({
				id: 'commitment-details-overview.label.balances'
			})}
		/>,
		<BasicDivider />,
		<CustomBasicLabelValue
			align="right"
			label={formatMessage({
				id: 'commitment-details-overview.label.commitment-amount'
			})}
			value={formatWithCurrency(commitmentDetails.commitmentAmount)}
		/>,
		<CustomBasicLabelValue
			align="right"
			label={formatMessage({
				id: 'commitment-details-overview.label.amount-available'
			})}
			value={formatWithCurrency(commitmentDetails.amountAvailable)}
		/>,
		<CustomBasicLabelValue
			align="right"
			label={formatMessage({
				id: 'commitment-details-overview.label.amount-used'
			})}
			value={formatWithCurrency(commitmentDetails.amountUsed)}
		/>,
		<CustomBasicLabelValue
			align="right"
			label={formatMessage({
				id: 'commitment-details-overview.label.outstanding-principal'
			})}
			value={formatWithCurrency(commitmentDetails.outstandingPrincipal)}
		/>,
		<CustomBasicLabelValue
			align="right"
			label={formatMessage({
				id: 'commitment-details-overview.label.outstanding-letters-of-credit'
			})}
			value={formatWithCurrency(commitmentDetails.outstandingLettersOfCredit)}
		/>,
		<CustomBasicLabelValue
			align="right"
			label={formatMessage({
				id: 'commitment-details-overview.label.borrowing-base-amount'
			})}
			hide={
				commitmentDetails.borrowingBaseAmt &&
				getAmount(commitmentDetails.borrowingBaseAmt) > 0 &&
				getAmount(commitmentDetails.borrowingBaseAmt) <
					getAmount(commitmentDetails.commitmentAmount)
					? false
					: true
			}
			value={formatWithCurrency(commitmentDetails.borrowingBaseAmt)}
		/>
	]

	function formatWithCurrency(amount) {
		const currencyUsd = formatMessage({
			id: 'view-interest-panel.label.currency-usd'
		})
		if (amount === undefined || amount === null) {
			return ''
		}
		return `${amount} ${currencyUsd}`
	}

	return (
		<BasicOverview
			className="CommitmentDetailsBalanceSection wf-col--desk-4 wf-col--tab-4 wf-col--mob-12"
			content={content}
			actions={[
				{
					tag: 'ViewInterestPanel'
				}
			]}
		></BasicOverview>
	)
}

export function CustomBasicLabelValue({ label, value, hide = false, align }) {
	if (hide === true || !value) {
		return <BasicNoContent />
	}
	return <BasicLabelValue label={label} value={value} align={align} />
}
