import React from 'react'
import { BasicPage } from 'ecpfs-react-data-driven-components'
import { useIntl } from 'react-intl'

export function OutagePage() {
	const { formatMessage } = useIntl()
	return (
		<BasicPage
			className="OutagePage"
			body={[
				{
					tag: 'BasicLabel',
					label: formatMessage({ id: 'outage-page.header.label' }),
					level: 3
				},
				{
					tag: 'BasicText',
					text: formatMessage({
						id: 'outage-page.description.text'
					})
				}
			]}
		/>
	)
}
