import React from 'react'
import { BasicToolbar } from 'ecpfs-react-data-driven-components'
import { Panel } from '@wf/ria'
import CustomPrintArea from './CustomPrintArea'
import ObligationDetailsPanelContent from '../containers/ObligationDetailsPanelContent'

export default function ObligationDetailsPanel({
	formatMessage,
	open,
	onClose,
	selectedObligation,
	source,
	commitmentDetailsSelectedTab
}) {
	const customToolbar = {
		ariaLabel: formatMessage({
			id: 'obligation-details-modal.label.obligation-details'
		}),
		className: 'ObligationDetails',
		actions: [
			{
				tag: 'BasicAction',
				className: 'Close',
				name: 'closeAction',
				label: formatMessage({
					id: 'obligation-details-modal.label.close'
				}),
				onClick: onClose,
				display: 'standard'
			},
			{
				tag: 'CustomPrintButton',
				label: formatMessage({
					id: 'obligation-details-panel.label.print'
				}),
				showIcon: false
			}
		]
	}
	const height = '70%'
	const width = '55%'

	return (
		<CustomPrintArea>
			<Panel
				id="panel"
				title={formatMessage({
					id: 'obligation-details-modal.label.obligation-details'
				})}
				className="ObligationDetailsPanel"
				open={open}
				onClose={onClose}
				height={height}
				width={width}
				elementToFocusOnClose="#panel"
				footer={
					<BasicToolbar
						actions={customToolbar.actions}
						ariaLabel={formatMessage({
							id: 'obligation-details-modal.label.obligation-details'
						})}
						className={'ObligationDetails'}
					/>
				}
			>
				<ObligationDetailsPanelContent
					source={source}
					selectedObligation={selectedObligation}
					commitmentDetailsSelectedTab={commitmentDetailsSelectedTab}
				/>
			</Panel>
		</CustomPrintArea>
	)
}
