import {
	GET_BILLING_STATEMENTS_SUCCESS,
	GET_ACCOUNT_AND_BALANCES_SUCCESS,
	CLEAR_ACCOUNT_AND_BALANCES,
	GET_CONFIRMATION_VIEW_DATA_SUCCESS,
	SHOW_SOURCEACCOUNT_LOADING
} from '../actions/billingStatements'
import { DataLink } from 'ecpfs-react-data-driven-components'

export const initialState = {
	get accounts() {
		return []
	},
	get confirmationViewData() {
		return []
	},
	get showAccountsLoading() {
		return false
	}
}

export function billingStatements(state = initialState, action) {
	switch (action.type) {
		case GET_BILLING_STATEMENTS_SUCCESS:
			return {
				...state,
				billingStatements: action.billingStatements,
				singleCustomerView: action.view
			}
		case GET_ACCOUNT_AND_BALANCES_SUCCESS:
			return {
				...state,
				accounts: DataLink.getContainerContent(action.accounts, []),
				effectiveDate: action.accounts.effectiveDate
			}
		case CLEAR_ACCOUNT_AND_BALANCES:
			return {
				...state,
				accounts: []
			}
		case GET_CONFIRMATION_VIEW_DATA_SUCCESS:
			return {
				...state,
				confirmationViewData: action.confirmationViewData
			}
		case SHOW_SOURCEACCOUNT_LOADING:
			return {
				...state,
				showAccountsLoading: action.showAccountsLoading
			}
		default:
			return state
	}
}
