import { send } from '@wf/ria-analytics'
import { DataLink } from 'ecpfs-react-data-driven-components'
import { fetchUserProfile } from '../services/fetchUserProfile'

const prdt = 'Digital Servicing'
const chn = 'CEO Portal'

let userId
let companyId

export function isNotInitialized(userId, companyId) {
	return userId === undefined || companyId === undefined
}

export async function sendAnalytics(tagId) {
	if (tagId === undefined) return

	if (process.env.NODE_ENV === 'test' && tagId !== 'TEST0001') {
		return
	}

	if (isNotInitialized(userId, companyId) === true) {
		try {
			const response = await fetchUserProfile()
			const userProfileResponse = DataLink.getContainerContent(response, [{}])[0]
			userId = userProfileResponse.userId
			companyId = userProfileResponse.companyId
		} catch {
			console.log('Unable to fetch user profile. User id or company id has not been initialized.')
			return
		}
	}

	const tag = tags.find(item => item.id === tagId.toUpperCase())
	const standardAttributes = {
		...tag,
		prdt,
		chn,
		cid: companyId,
		uid: userId
	}
	send({
		standardAttributes
	})
}

const tags = [
	{
		id: 'DISE0001',
		ftr: 'Select Navigation Expand/Collapse',
		typ: 'Product, Navigation, Product'
	},
	{
		id: 'DISE0002',
		ftr: 'Select Treasury Information Reporting Quick Reports',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0003',
		ftr: 'Expand/Collapse Support',
		typ: 'Product, Expand, Product'
	},
	{
		id: 'DISE0004',
		ftr: 'Select Help link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0005',
		ftr: 'Select Contact Us link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0006',
		ftr: 'Select Locations link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0007',
		ftr: 'Select Holiday Schedule link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0008',
		ftr: 'Select CEO Expert Community link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0009',
		ftr: 'Select Sign Off button',
		typ: 'Product, Button, Product'
	},
	{
		id: 'DISE0053',
		ftr: 'Select Support Drop Down',
		typ: 'Product, Dropdown, Product'
	},
	{
		id: 'DISE0091',
		ftr: 'Support, Select Help link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0092',
		ftr: 'Support, Select Contact Us link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0093',
		ftr: 'Support, Select Locations link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0094',
		ftr: 'Support, Select Holiday Schedule link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0095',
		ftr: 'Support, Select CEO Expert Community link',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0013',
		ftr: 'Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0054',
		ftr: 'Sort Facility',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0055',
		ftr: 'Sort Facility Type',
		typ: 'Product, Sort, Product'
	},

	{
		id: 'DISE0057',
		ftr: 'Sort Commitment Amount',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0058',
		ftr: 'Sort Amount Used',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0059',
		ftr: 'Sort Amount Available',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0014',
		ftr: 'Select Print',
		typ: 'Product, Button, Print'
	},

	{
		id: 'DISE0015',
		ftr: 'Select Export',
		typ: 'Product, Button, Download'
	},
	{
		id: 'DISE0061',
		ftr: 'Open/Collapse selection',
		typ: 'Product, Expand/Collapse, Product'
	},
	{
		id: 'DISE0096',
		ftr: 'Select Select Dropdown',
		typ: 'Product, Dropdown, Product'
	},
	{
		id: 'DISE0097',
		ftr: 'Select Dropdown, View Facility Details',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0098',
		ftr: 'Select Dropdown, View Transaction History',
		typ: 'Product, Link, Product'
	},
	{
		id: 'DISE0099',
		ftr: 'Application Message',
		typ: 'Product, Message, Product'
	},
	{
		id: 'DISE0016',
		ftr: 'Facility Details, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0017',
		ftr: 'Facility Details, Select Other Obligations',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0018',
		ftr: 'Facility Details, Select Archived Obligations',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0019',
		ftr: 'Facility Details, Select Transaction History',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0062',
		ftr: 'Facility Details, Sort Obligation',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0063',
		ftr: 'Facility Details, Sort Interest Rate',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0065',
		ftr: 'Facility Details, Sort Effective Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0066',
		ftr: 'Facility Details, Sort Description',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0067',
		ftr: 'Facility Details, Sort Outstanding Principal Balance',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0068',
		ftr: 'Facility Details, Sort Balance in Facility Currency',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0069',
		ftr: 'Facility details, Expand/Collapse selection',
		typ: 'Product, Expand/Collapse, Product'
	},
	{
		id: 'DISE0020',
		ftr: 'Facility Details, Print Selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0021',
		ftr: 'Facility Details, Export Selection',
		typ: 'Product, Button, Download'
	},
	{
		id: 'DISE0022',
		ftr: 'Facility Details, Select Loans',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0023',
		ftr: 'Facility Details, Balances, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0024',
		ftr: 'Facility Details, Customer List, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0025',
		ftr: 'Facility Details, Customer List, Print Selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0026',
		ftr: 'Facility Details, Interest and Fees, Balances, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0027',
		ftr: 'Facility Details, Interest and Fees, Balances, Homepage, Print selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0028',
		ftr: 'Facility Details, Obligation Interest and Fees, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0029',
		ftr: 'Facility Details, Obligation Interest and Fees, Print selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0030',
		ftr: 'Facility Details, Obligation Interest and Fees, Download selection',
		typ: 'Product, Button, Download'
	},
	{
		id: 'DISE0031',
		ftr: 'Facility Details, Select Letters of Credit',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0032',
		ftr: 'Facility Details, Select Obligations',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0033',
		ftr: 'Facility Details, Select Archived Letters of Credit',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0100',
		ftr: 'Facility Details, Select Facility Dropdown',
		typ: 'Product, Dropdown, Product'
	},
	{
		id: 'DISE0101',
		ftr: 'Facility Details,  Application Message',
		typ: 'Product, Message, Product'
	},
	{
		id: 'DISE0107',
		ftr: 'Facilities Details, Select Archived Loans',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0071',
		ftr: 'Facility Details, Transaction History, Sort Transaction Type',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0072',
		ftr: 'Facility Details, Transaction History, Sort Obligation',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0073',
		ftr: 'Facility Details, Transaction History, Sort Effective Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0074',
		ftr: 'Facility Details, Transaction History, Sort Transaction Amount',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0034',
		ftr: 'Facility Details, Summary,  Obligation Details, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0035',
		ftr: 'Facility Details, Summary,  Obligation Details, Select Transaction History',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0036',
		ftr: 'Facility Details, Summary,  Obligation Details, Select Rate Calculation History',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0037',
		ftr: 'Facility Details, Summary,  Obligation Details, Print Selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0039',
		ftr: 'Facility Details, Obligation History, Obligation Details, Summary, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0040',
		ftr: 'Facility Details, Obligation History, Obligation Details, Summary, Print Selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0042',
		ftr: 'Facility Details, Transaction History, Obligation Details, Transaction History, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0043',
		ftr: 'Facility Details, Transaction History, Select Summary',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0044',
		ftr: 'Facility Details, Transaction History, Obligation Details, Summary, Print Selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0045',
		ftr: 'Facility Details, Transaction History, Obligation Details, Summary, Download Selection',
		typ: 'Product, Button, Download'
	},
	{
		id: 'DISE0046',
		ftr: 'Facility Details, Rate History, Rate calculation History, Print selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0047',
		ftr: 'Facility Details, Rate History, Rate calculation History, Download selection',
		typ: 'Product, Button, Download'
	},
	{
		id: 'DISE0048',
		ftr: 'Facility Details, Commission Fee History, Print Selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0049',
		ftr: 'Facility Details, Commission Fee History, Download Selection',
		typ: 'Product, Button, Download'
	},
	{
		id: 'DISE0050',
		ftr: 'Paid Off Facilities, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0077',
		ftr: 'Paid Off Facilities, Sort Facility',
		typ: 'Product, Sort, Product'
	},

	{
		id: 'DISE0079',
		ftr: 'Paid Off Facilities, Sort Customer',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0080',
		ftr: 'Paid Off Facilities, Sort Facility Type',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0082',
		ftr: 'Paid Off Facilities, Sort Expiration Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0083',
		ftr: 'Paid Off Facilities, Sort Maturity Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0084',
		ftr: 'Paid Off Facilities, Sort Paid Off Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0085',
		ftr: 'Paid Off Facilities, Sort Commitment Amount',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0102',
		ftr: 'Paid Off Facilities, Expand/Collapse selection',
		typ: 'Product, Expand/Collapse, Product'
	},
	{
		id: 'DISE0051',
		ftr: 'Paid Off Facilities Details, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0052',
		ftr: 'Paid Off Facilities Details, Select Transaction History',
		typ: 'Product, Tab, Product'
	},
	{
		id: 'DISE0086',
		ftr: 'Paid Off Facilities Details, Archived Obligations, Sort Obligation',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0087',
		ftr: 'Paid Off Facilities Details, Archived Obligations, Sort Customer',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0088',
		ftr: 'Paid Off Facilities Details, Archived Obligations, Sort Effective Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0089',
		ftr: 'Paid Off Facilities Details, Archived Obligations, Sort Inactive Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0090',
		ftr: 'Paid Off Facilities Details, Archived Obligations, Sort Description',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0053',
		ftr: 'Paid Off Facilities Details, Interest and Fees, Homepage',
		typ: 'Product, Pageload, Product'
	},
	{
		id: 'DISE0092',
		ftr: 'Paid Off Facilities Details, Transaction History, Sort Transaction Type',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0093',
		ftr: 'Paid Off Facilities Details, Transaction History, Sort Obligation',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0094',
		ftr: 'Paid Off Facilities Details, Transaction History, Sort Effective Date',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0095',
		ftr: 'Paid Off Facilities Details, Transaction History, Sort Transaction Amount',
		typ: 'Product, Sort, Product'
	},
	{
		id: 'DISE0103',
		ftr: 'Paid Off Facilities Details, Archived Obiligations, Export selection',
		typ: 'Product, Button, Export'
	},
	{
		id: 'DISE0104',
		ftr: 'Paid Off Facilities Details, Transaction History, Print selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0105',
		ftr: 'Paid Off Facilities Details, Transaction History, Print selection',
		typ: 'Product, Button, Export'
	},
	{
		id: 'DISE0108',
		ftr: 'Paid Off Facilities, Print Selection',
		typ: 'Product, Button, Print'
	},
	{
		id: 'DISE0109',
		ftr: 'Paid Off Facilities, Download Selection',
		typ: 'Product, Button, Download'
	}
]
