import { UPDATE_SHOW_LOADING } from '../actions/siteLoader'

export const initialState = {
	get showLoading() {
		return false
	}
}

export function siteLoader(state = initialState, action) {
	switch (action.type) {
		case UPDATE_SHOW_LOADING:
			return {
				...state,
				showLoading: action.showLoading
			}
		default:
			return state
	}
}
