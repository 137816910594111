import get from 'lodash.get'
import { fetchNavigator } from '../services/fetchNavigator'
import { fetchSignOut } from '../services/fetchSignOut'
import { updateShowFeedbackPrompt } from './feedback'
import { updateSystemMessage } from './systemMessages'

export const GET_APP_NAVIGATOR_SUCCESS = 'GET_APP_NAVIGATOR_SUCCESS'
export function getAppNavigatorSuccess(navigator) {
	return {
		type: GET_APP_NAVIGATOR_SUCCESS,
		navigator
	}
}

export const GET_APP_NAVIGATOR_ERROR = 'GET_APP_NAVIGATOR_ERROR'
export function getAppNavigatorError(error) {
	return {
		type: GET_APP_NAVIGATOR_ERROR,
		error
	}
}

export function getAppNavigator(params) {
	return async function getAppNavigatorThunk(dispatch) {
		try {
			const navigator = await fetchNavigator(params)
			dispatch(getAppNavigatorSuccess(navigator))
			const showFeedbackPrompt = get(navigator, 'session.signOut.showFeedbackPrompt', false)
			dispatch(updateShowFeedbackPrompt(showFeedbackPrompt))
			return navigator
		} catch (error) {
			dispatch(getAppNavigatorError(error))
		}
	}
}

export function navigateToPath(path, setPath, additionalParams) {
	return function navigateToPathThunk() {
		const historyParams = additionalParams ? additionalParams : undefined
		if (typeof path === 'string' && typeof setPath === 'function') {
			setPath({ path }, historyParams)
		}
	}
}

export function signOut(redirectToSignOff, isCldpAdmin, cldpAdminSignOffUrl) {
	return async function getSignOut(dispatch) {
		try {
			if (isCldpAdmin) {
				window.location.href = cldpAdminSignOffUrl
				return cldpAdminSignOffUrl
			} else {
				await fetchSignOut()
				redirectToSignOff()
			}
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}
