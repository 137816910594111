import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { getBillingStatements, getConfirmationViewData } from '../actions/billingStatements'
import { ViewLoanBillingStatementsPage } from '../components/ViewLoanBillingStatementsPage'
import { getLedgers } from '../actions/ledgers'
import get from 'lodash.get'
import { updateSystemMessage } from '../actions/systemMessages'

export const mapStateToProps = state => ({
	billingStatements: get(state, 'billingStatements.billingStatements'),
	singleCustomerView: get(state, 'billingStatements.singleCustomerView'),
	ledger: get(state, 'ledgers.selectedLedger.dataLink.params.encryptedKey'),
	systemMessages: get(state, 'systemMessages.systemMessages', []),
	showLoading: get(state, 'siteLoader.showLoading', false)
})

export const mapDispatchToProps = dispatch => ({
	onGetBillingStatements(ledger, paginationParams) {
		dispatch(getBillingStatements(ledger, paginationParams))
	},
	onGetLedger() {
		dispatch(getLedgers())
	},
	onGetConfirmationViewData(
		invoiceNumber,
		invoiceAmount,
		selectedAccountEncryptedKey,
		responseStatus
	) {
		dispatch(
			getConfirmationViewData(
				invoiceNumber,
				invoiceAmount,
				selectedAccountEncryptedKey,
				responseStatus
			)
		)
	},
	onUpdateSystemMessage(systemMessages) {
		dispatch(updateSystemMessage(systemMessages))
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ViewLoanBillingStatementsPage)
)

component.displayName = 'ViewLoanBillingStatementsPage'
export default component
