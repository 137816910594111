import get from 'lodash.get'
import { GET_APP_NAVIGATOR_SUCCESS } from '../actions/navigator'

export const initialState = {
	get id() {
		return 'wf-efmya-digital'
	},
	get title() {
		return 'Digital Servicing'
	},
	get fromInternalSite() {
		return false
	},
	get session() {
		return {
			get signOut() {
				return {
					get url() {
						return '/digital-servicing/SignOut.html'
					},
					get label() {
						return 'Sign Out'
					}
				}
			},
			get timeout() {
				return {
					get ping() {
						return '/digital-servicing-ui/session/ping'
					},
					get message() {
						return 'Your session is about to expire due to inactivity.'
					}
				}
			}
		}
	},
	get brand() {
		return {
			get stageCoach() {
				return false
			},
			get brandId() {
				return ''
			},
			get region() {
				return ''
			},
			get logos() {
				return []
			}
		}
	},
	get links() {
		return []
	}
}

export function navigator(state = initialState, action) {
	switch (action.type) {
		case GET_APP_NAVIGATOR_SUCCESS:
			return {
				...action.navigator,
				fromInternalSite: get(action.navigator, 'fromInternalSite', false),
				showFeedbackPrompt: get(action.navigator, 'session.signOut.showFeedbackPrompt', false)
			}
		default:
			return state
	}
}
