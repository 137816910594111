import { updateSystemMessage } from './systemMessages'
import { fetchDeleteNickname } from '../services/fetchDeleteNickname'
import { fetchLoanSummaryDetails } from '../services/fetchLoanSummaryDetails'
import { fetchSaveCommitmentNickname } from '../services/fetchSaveCommitmentNickname'
import { fetchGetFacilityDetailsByKey } from '../services/fetchGetFacilityDetailsByKey'
import { fetchGetFacilities } from '../services/fetchGetFacilities'
import { fetchFacilityDetailsTabs } from '../services/fetchFacilityDetailsTabs'
import { fetchFacilityDetailsTabData } from '../services/fetchFacilityDetailsTabData'
import { fetchAllCustomersList } from '../services/fetchAllCustomersList'
import { fetchViewInterestDetails } from '../services/fetchViewInterestDetails'
import { fetchPayDownsAndAdvancesData } from '../services/fetchPayDownsAndAdvancesData'
import { fetchPayDownsAndAdvancesConfirmationViewData } from '../services/fetchPayDownsAndAdvancesConfirmationViewData'
import get from 'lodash.get'
import { updateShowLoading } from './siteLoader'
import { DataLink } from 'ecpfs-react-data-driven-components'

export const AFS = 'ceoAppAFS'
export const LIQ = 'ceoAppLIQ'

export function submitCommitmentNickname(params, tableParams) {
	return async function submitCommitmentNicknameThunk(dispatch) {
		dispatch(updateShowLoading(true))
		const { encryptedKey, nickname } = params
		const submitNicknameParams = {
			customerEnrollmentKey: {
				encryptedKey
			},
			nickname
		}
		try {
			const response = await fetchSaveCommitmentNickname(submitNicknameParams)
			const updatedResponse = {
				...response,
				systemMessages: response.systemMessages.map(systemMessage => ({
					...systemMessage,
					type: 'messageBar',
					time: new Date()
				}))
			}
			dispatch(updateSystemMessage(updatedResponse))
			dispatch(getLoanSummaryDetails(tableParams))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
		dispatch(updateShowLoading(false))
	}
}

export function deleteNickname(tableParams) {
	return async function deleteNicknameThunk(dispatch, getState) {
		const { commitmentRow } = getState().commitment
		const deleteNicknameParams = {
			customerEnrollmentKey: {
				encryptedKey: commitmentRow.encryptedKey
			}
		}
		try {
			const response = await fetchDeleteNickname(deleteNicknameParams)
			const updatedResponse = {
				...response,
				systemMessages: response.systemMessages.map(systemMessage => ({
					...systemMessage,
					type: 'messageBar',
					time: new Date()
				}))
			}
			dispatch(updateSystemMessage(updatedResponse))
			dispatch(getLoanSummaryDetails(tableParams))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export const OPEN_ADD_NICKNAME_PANEL = 'OPEN_ADD_NICKNAME_PANEL'

export function openAddNicknamePanel(isOpen) {
	return {
		type: OPEN_ADD_NICKNAME_PANEL,
		isOpen
	}
}

export const OPEN_EDIT_NICKNAME_PANEL = 'OPEN_EDIT_NICKNAME_PANEL'

export function openEditNicknamePanel(isOpen) {
	return {
		type: OPEN_EDIT_NICKNAME_PANEL,
		isOpen
	}
}

export const GET_SELECTED_COMMITMENT_ROW = 'GET_SELECTED_COMMITMENT_ROW'

export function getSelectedCommitmentRow(commitmentRow) {
	return {
		type: GET_SELECTED_COMMITMENT_ROW,
		commitmentRow
	}
}

export function getLoanSummaryDetails(params) {
	return async function getLoanSummaryDetailsThunk(dispatch, getState) {
		const { path } = getState().commitment
		try {
			const response = await fetchLoanSummaryDetails(params, path)
			const tableData = { rows: { ...response } }
			dispatch(setTableData(tableData))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export const SET_TABLE_DATA = 'SET_TABLE_DATA'

export function setTableData(tableData) {
	return {
		type: SET_TABLE_DATA,
		tableData
	}
}

export const RESET_TABLE_DATA = 'RESET_TABLE_DATA'

export function resetTableData() {
	return {
		type: RESET_TABLE_DATA
	}
}

export const SET_TABLE_PARAMS = 'SET_TABLE_PARAMS'

export function setTableParams(tableParams) {
	return {
		type: SET_TABLE_PARAMS,
		tableParams
	}
}

export const SET_LOAN_SUMMARY_PATH = 'SET_LOAN_SUMMARY_PATH'

export function setLoanSummaryPath(path) {
	return {
		type: SET_LOAN_SUMMARY_PATH,
		path
	}
}

// Commitment Details

export function loadCommitmentDetailsPage(facilityKey, defaultTab) {
	return async function loadCommitmentDetailsPageThunk(dispatch) {
		dispatch(updateShowLoading(true))
		dispatch(clearCommitmentDetails())
		dispatch(getCommitments(facilityKey))
		dispatch(getCommitmentDetails(facilityKey))
		dispatch(getCommitmentTabs(facilityKey, defaultTab))
		dispatch(updateShowLoading(false))
	}
}

export const CLEAR_COMMITMENT_DETAILS = 'CLEAR_COMMITMENT_DETAILS'

export function clearCommitmentDetails() {
	return {
		type: CLEAR_COMMITMENT_DETAILS
	}
}

export function getCommitments(facilityKey) {
	return async function getCommitmentsThunk(dispatch) {
		try {
			const params = {
				facilityKey: {
					encryptedKey: facilityKey
				}
			}
			const response = await fetchGetFacilities(params)
			dispatch(getCommitmentsSuccess(response))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export const GET_COMMITMENTS_SUCCESS = 'GET_COMMITMENTS_SUCCESS'

export function getCommitmentsSuccess(commitments) {
	return {
		type: GET_COMMITMENTS_SUCCESS,
		commitments
	}
}

export function getCommitmentDetails(facilityKey) {
	return async function getCommitmentDetailsThunk(dispatch) {
		try {
			const params = {
				borrowerIdFCNKey: {
					encryptedKey: facilityKey
				}
			}
			const response = await fetchGetFacilityDetailsByKey(params)
			await dispatch(getCommitmentDetailsSuccess(response))
			if (response?.rows[0].borrowerid) {
				dispatch(getAllCustomers(facilityKey))
			}
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export const GET_COMMITMENT_DETAILS_SUCCESS = 'GET_COMMITMENT_DETAILS_SUCCESS'

export function getCommitmentDetailsSuccess(commitmentDetails) {
	return {
		type: GET_COMMITMENT_DETAILS_SUCCESS,
		commitmentDetails
	}
}

export const SET_SELECTED_COMMITMENT = 'SET_SELECTED_COMMITMENT'

export function setSelectedCommitment(facilityKey) {
	return {
		type: SET_SELECTED_COMMITMENT,
		facilityKey
	}
}

export const SET_DROPDOWN_VALUE = 'SET_DROPDOWN_VALUE'

export function setDropdownValue(dropdownValue) {
	return {
		type: SET_DROPDOWN_VALUE,
		dropdownValue
	}
}

export function getSelectedCommitmentDetails(facilityKey, defaultTab) {
	return async function getSelectedCommitmentDetailsThunk(dispatch) {
		dispatch(clearCommitmentDetailsInfo())
		dispatch(clearCommitmentDetailsTableData())
		await dispatch(setSelectedCommitment(facilityKey))
		await dispatch(getCommitmentDetails(facilityKey))
		dispatch(getCommitmentTabs(facilityKey, defaultTab))
	}
}

export const CLEAR_COMMITMENT_DETAILS_INFO = 'CLEAR_COMMITMENT_DETAILS_INFO'

export function clearCommitmentDetailsInfo() {
	return {
		type: CLEAR_COMMITMENT_DETAILS_INFO
	}
}

export function getCommitmentTabs(facilityKey, defaultTab) {
	return async function getCommitmentTabsThunk(dispatch) {
		try {
			const params = {
				borrowerIdFCNKey: {
					encryptedKey: facilityKey
				}
			}
			const response = await fetchFacilityDetailsTabs(params)
			const tabs = get(response, 'tabs', [])
			await dispatch(getCommitmentTabsSuccess(tabs))
			const tab = getTab(defaultTab, tabs)
			dispatch(getCommitmentTableData(tab, facilityKey, { rowsOffset: 0 }))
		} catch (error) {
			dispatch(updateSystemMessage(error))
		}
	}
}

export function getTab(defaultTab, tabs) {
	return defaultTab !== '' ? defaultTab : tabs[0]
}

export const GET_COMMITMENT_TABS_SUCCESS = 'GET_COMMITMENT_TABS_SUCCESS'

export function getCommitmentTabsSuccess(tabs) {
	return {
		type: GET_COMMITMENT_TABS_SUCCESS,
		tabs
	}
}

export function getCommitmentTableData(selectedTab, facilityKey, pagination, filterData) {
	return async function getCommitmentTableDataThunk(dispatch) {
		dispatch(updateShowLoading(true))
		dispatch(clearCommitmentDetailsTableData())
		try {
			const userData = selectedTab === 'transaction-history' ? { filterData } : {}
			const tableDataParams = {
				facilityDetailsTabKey: {
					encryptedKey: facilityKey
				},
				configName: selectedTab,
				userData,
				...pagination
			}
			const response = await fetchFacilityDetailsTabData(tableDataParams)
			if (get(response, 'systemMessages')) {
				dispatch(getCommitmentDetailsTableSystemMessages(response.systemMessages))
			}
			dispatch(getCommitmentTableDataSuccess(response))
		} catch (error) {
			dispatch(getCommitmentDetailsTableSystemMessages(error.systemMessages))
		}
		dispatch(updateShowLoading(false))
	}
}

export const CLEAR_COMMITMENT_DETAILS_TABLE_DATA = 'CLEAR_COMMITMENT_DETAILS_TABLE_DATA'

export function clearCommitmentDetailsTableData() {
	return {
		type: CLEAR_COMMITMENT_DETAILS_TABLE_DATA
	}
}

export const GET_COMMITMENT_DETAILS_TABLE_SYSTEM_MESSAGES =
	'GET_COMMITMENT_DETAILS_TABLE_SYSTEM_MESSAGES'

export function getCommitmentDetailsTableSystemMessages(systemMessages) {
	return {
		type: GET_COMMITMENT_DETAILS_TABLE_SYSTEM_MESSAGES,
		systemMessages
	}
}

export const GET_COMMITMENT_TABLE_DATA_SUCCESS = 'GET_COMMITMENT_TABLE_DATA_SUCCESS'

export function getCommitmentTableDataSuccess(commitmentDetailsTableData) {
	return {
		type: GET_COMMITMENT_TABLE_DATA_SUCCESS,
		commitmentDetailsTableData
	}
}

export const HANDLE_FILTER_VALUE_CHANGE = 'HANDLE_FILTER_VALUE_CHANGE'

export function handleFilterValueChange(filter) {
	return {
		type: HANDLE_FILTER_VALUE_CHANGE,
		filter
	}
}

export function getAllCustomers(facilityKey) {
	return async function getAllCustomersThunk(dispatch) {
		const customersListParams = {
			borrowerIdFCNKey: {
				encryptedKey: facilityKey
			}
		}
		try {
			const response = await fetchAllCustomersList(customersListParams)
			if (response?.customers.length === 0) {
				dispatch(updateShowCommitmentDetailsCustomersPanel(false))
			} else {
				dispatch(updateShowCommitmentDetailsCustomersPanel(true))
			}
			dispatch(getAllCustomersSuccess(response))
		} catch (error) {
			dispatch(updateShowCommitmentDetailsCustomersPanel(false))
			dispatch(updateSystemMessage(error))
		}
	}
}

export const UPDATE_SHOW_COMMITMENT_DETAILS_CUSTOMERS_PANEL =
	'UPDATE_SHOW_COMMITMENT_DETAILS_CUSTOMERS_PANEL'

export function updateShowCommitmentDetailsCustomersPanel(showCustomersPanel) {
	return {
		type: UPDATE_SHOW_COMMITMENT_DETAILS_CUSTOMERS_PANEL,
		showCustomersPanel
	}
}

export const GET_ALL_CUSTOMERS_SUCCESS = 'GET_ALL_CUSTOMERS_SUCCESS'

export function getAllCustomersSuccess(customersList) {
	return {
		type: GET_ALL_CUSTOMERS_SUCCESS,
		customersList
	}
}

export function getViewInterestDetails(facilityKey) {
	return async function getViewInterestDetailsThunk(dispatch) {
		const viewInterestParams = {
			borrowerIdFCNKey: {
				encryptedKey: facilityKey
			}
		}
		try {
			dispatch(clearViewInterestSystemMessages())
			const response = await fetchViewInterestDetails(viewInterestParams)
			dispatch(getViewInterestDetailsSuccess(response))
		} catch (error) {
			dispatch(getViewInterestDetailsFail(error.systemMessages))
		}
	}
}

export const GET_VIEW_INTEREST_DETAILS_SUCCESS = 'GET_VIEW_INTEREST_DETAILS_SUCCESS'

export function getViewInterestDetailsSuccess(viewInterestDetails) {
	return {
		type: GET_VIEW_INTEREST_DETAILS_SUCCESS,
		viewInterestDetails
	}
}

export const GET_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES = 'GET_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES'

export function getViewInterestDetailsFail(systemMessages) {
	return {
		type: GET_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES,
		systemMessages
	}
}

export const CLEAR_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES =
	'CLEAR_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES'

export function clearViewInterestSystemMessages(systemMessages) {
	return {
		type: CLEAR_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES
	}
}

export function getPayDownsAndAdvancesData(facilityKey, dropdownValue) {
	return async function getPayDownsAndAdvancesDataThunk(dispatch) {
		dispatch(updateShowLoading(true))
		dispatch(resetPayDownsAndAdvancesTableData())
		try {
			const tableDataParams = {
				payDownKey: {
					encryptedKey: facilityKey
				},
				dropDownValue: dropdownValue
			}
			const response = await fetchPayDownsAndAdvancesData(tableDataParams)
			const rows = DataLink.getContainerContent(response, [])
			if (rows.length > 0) {
				dispatch(getPayDownsAndAdvancesDataSuccess(response))
			} else {
				dispatch(getPaydownsAndAdvancesTableSystemMessages(response?.systemMessages))
			}
		} catch (error) {
			dispatch(getPaydownsAndAdvancesTableSystemMessages(error?.systemMessages))
		}
		dispatch(updateShowLoading(false))
	}
}

export const GET_PAYDOWNS_AND_ADVANCES_DATA_SUCCESS = 'GET_PAYDOWNS_AND_ADVANCES_DATA_SUCCESS'

export function getPayDownsAndAdvancesDataSuccess(payDownsAndAdvancesData) {
	return {
		type: GET_PAYDOWNS_AND_ADVANCES_DATA_SUCCESS,
		payDownsAndAdvancesData
	}
}

export const RESET_PAYDOWNS_AND_ADVANCES_TABLE_DATA = 'RESET_PAYDOWNS_AND_ADVANCES_TABLE_DATA'

export function resetPayDownsAndAdvancesTableData() {
	return {
		type: RESET_PAYDOWNS_AND_ADVANCES_TABLE_DATA
	}
}

export const GET_PAYDOWNS_AND_ADVANCES_TABLE_SYSTEM_MESSAGES =
	'GET_PAYDOWNS_AND_ADVANCES_TABLE_SYSTEM_MESSAGES'

export function getPaydownsAndAdvancesTableSystemMessages(systemMessages) {
	return {
		type: GET_PAYDOWNS_AND_ADVANCES_TABLE_SYSTEM_MESSAGES,
		systemMessages
	}
}

function getAmount(stringValue = '') {
	return Number(stringValue.replace(/,/g, ''))
}

export function getPayDownsConfirmationViewData(
	dropdownValue,
	obligationId,
	paymentTotal,
	selectedAccountEncryptedKey,
	responseStatus,
	additionalAdvanceParams
) {
	return async function getPayDownsConfirmationViewDataThunk(dispatch) {
		try {
			const convertedPaymentTotal = getAmount(paymentTotal.split(' ')[0])
			const request = {
				dropDownValue: dropdownValue,
				obligationNumber: obligationId,
				paymentTotal: convertedPaymentTotal,
				tmsRequest: {
					encryptedKey: selectedAccountEncryptedKey
				}
			}
			const advanceRequest = { ...request, ...additionalAdvanceParams }
			const response = await fetchPayDownsAndAdvancesConfirmationViewData(
				dropdownValue === 'payDownPrincipal' ? request : advanceRequest
			)
			if (response.systemMessages && response.systemMessages.length === 1) {
				let responseMsg = response.systemMessages[0]
				console.log(responseMsg)
				if (responseMsg.hasOwnProperty('icon') && responseMsg.icon === 'error') {
					responseStatus(false)
					dispatch(updateSystemMessage(response.systemMessages))
				}
			} else {
				responseStatus(true)
				dispatch(getPayDownsConfirmationViewDataSuccess(response?.rows))
			}
		} catch (error) {
			responseStatus(false)
			dispatch(updateSystemMessage(error))
		}
	}
}

export const GET_PAYDOWNS_CONFIRMATION_VIEW_DATA_SUCCESS =
	'GET_PAYDOWNS_CONFIRMATION_VIEW_DATA_SUCCESS'

export function getPayDownsConfirmationViewDataSuccess(payDownsConfirmationViewData) {
	return {
		type: GET_PAYDOWNS_CONFIRMATION_VIEW_DATA_SUCCESS,
		payDownsConfirmationViewData
	}
}
