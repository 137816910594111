import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { OutagePage } from './components/OutagePage'
import { getAfsStore } from './getAfsStore'
import en from './locale/en'
import { runAddComponentsAfs } from './AfsApp'
import { renderDOM } from './index'

export function customRequest(POST) {
	POST('userBrandBusinessNavigation.json', { locale: '' })
		.then(async navigator => {
			runAddComponentsAfs()
			const afsStore = await getAfsStore(navigator)
			renderDOM(afsStore)
			return afsStore
		})
		.catch(error => {
			console.log('userBrandBusinessNavigation error', error)
			ReactDOM.render(
				<IntlProvider locale={'en'} messages={en}>
					<OutagePage />
				</IntlProvider>,
				document.getElementById('root')
			)
			return null
		})
}
