import React, { useEffect } from 'react'
import { BasicText } from 'ecpfs-react-data-driven-components'
import { Table } from '@wf/ria'

function CustomSourceAccountTable({
	formatMessage,
	accounts = [],
	selectedAccount,
	setSelectedAccount,
	label,
	ariaCaption
}) {
	useEffect(() => {
		if (selectedAccount.length === 0 && accounts.length > 0) {
			setSelectedAccount(defaultValue => {
				defaultValue.push(accounts[0].encryptedKey)
				return defaultValue
			})
		}
	}, [accounts, selectedAccount.length, setSelectedAccount])

	return (
		<div className="SourceAccountTable">
			<BasicText
				required={true}
				text={formatMessage({
					id: label ?? 'loan-billing.statements-page.modal.secondary.header'
				})}
			/>
			<Table
				columns={[
					{
						key: 'maskedAccountNumber',
						label: formatMessage({
							id: 'loan-billing.statements-page.modal.table.column.account'
						})
					},
					{
						key: 'currentAvailableBalance',
						label: formatMessage({
							id: 'loan-billing.statements-page.modal.table.column.current-balance'
						}),
						align: 'right'
					}
				]}
				data={accounts}
				rowKey="encryptedKey"
				autoWidth={true}
				ariaCaption={ariaCaption}
				selectable={{
					selectionType: 'single',
					selectedRows: selectedAccount,
					disabledRows: [],
					rowAriaLabel: ({ item }) => `${item.maskedAccountNumber} row`,
					onSelectedChange: sourceAccountTable => {
						setSelectedAccount(sourceAccountTable.selectedRows)
					}
				}}
			/>
		</div>
	)
}

export default CustomSourceAccountTable
