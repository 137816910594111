import React from 'react'
import PropTypes from 'prop-types'
import { BasicAction, BasicNoContent } from 'ecpfs-react-data-driven-components'

function CustomFacilityColumn({ dataLink, rowData }) {
	const {
		item: { commitment = '' }
	} = rowData

	const dataLinkWithRowData = {
		...dataLink,
		params: {
			rowData
		}
	}

	if (commitment && commitment !== '') {
		return (
			<BasicAction
				dataLink={dataLinkWithRowData}
				label={commitment}
				display="link"
				className="CustomFacilityColumn"
			/>
		)
	}

	return <BasicNoContent />
}

CustomFacilityColumn.propTypes = {
	dataLink: PropTypes.object,
	rowData: PropTypes.object
}

CustomFacilityColumn.displayName = 'CustomFacilityColumn'

export default CustomFacilityColumn
