import React, { useEffect, useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	BasicTable,
	BasicText,
	withFetchDataLink,
	createRddComponent,
	BrowserNavigationContext,
	BasicNoContent,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { Feedback, Button } from '@wf/ria'
import {
	setTableParams,
	getLoanSummaryDetails,
	resetTableData,
	setLoanSummaryPath
} from '../actions/commitment'
import isEmpty from 'lodash.isempty'
import { getSystemMessageIconData } from '../utils/getSystemMessageIconData'
import { AFS } from '../actions/commitment'
import { CustomWaitMessage } from './CustomWaitMessage'
import { useIntl } from 'react-intl'
import { updateSystemMessage } from '../actions/systemMessages'

export function useProjectDocumentTitle() {
	const { formatMessage } = useIntl()
	return formatMessage({ id: 'credit-management-home-page.tab.title' })
}

export function CustomHomePageTableComponent(props) {
	const { ledgerAppId = '' } = props
	document.title = useProjectDocumentTitle()

	if (ledgerAppId === AFS) {
		return <HomePageTable {...props} />
	}
	return <BasicNoContent />
}

export function HomePageTable(props) {
	const dispatch = useDispatch()

	const showLoading = useSelector(state => state.siteLoader.showLoading)
	const AfsHomePageRef = useRef()
	const { formatMessage } = useIntl()
	const { rows = { dataLink: {} } } = props
	const {
		dataLink: { api },
		rows: actualRows = []
	} = rows
	dispatch(setLoanSummaryPath(api))

	const loadingLabel = formatMessage({
		id: 'loan-billing.statements-page.label.loading'
	})

	return (
		<div className="AfsHomePage" ref={AfsHomePageRef}>
			<CustomWaitMessage
				isLoading={showLoading}
				loadingMessage={loadingLabel}
				containerNode={AfsHomePageRef}
			/>
			<HomePageTableHeader rowLength={actualRows.length} formatMessage={formatMessage} />
			<HomePageSystemMessage systemMessages={props.systemMessages?.content?.[0]} />
			<CommitmentsTable props={props} dispatch={dispatch} formatMessage={formatMessage} />
			<AfsFooterContent rowLength={actualRows.length} formatMessage={formatMessage} />
		</div>
	)
}

export function CommitmentsTable({ props, dispatch, formatMessage }) {
	const tableData = useSelector(state => state.commitment.tableData)
	const tableParams = useSelector(state => state.commitment.tableParams)
	const {
		rows = { dataLink: {} },
		columns,
		className,
		extraContent,
		footer,
		primaryKey,
		ledgerAppId,
		header,
		messages
	} = props
	const { dataLink } = rows
	const { params } = dataLink
	const { clearMessages } = useContext(SystemMessagesContext)
	const [isInitialLoad, setIsInitialLoad] = useState(true)
	const { setPath } = useContext(BrowserNavigationContext)
	const updatedMessages = {
		...messages,
		collapsedRowAnnouncement: (itemKey, item) =>
			`${formatMessage({
				id: 'basic-table.label.more-about'
			})} ${item.commitment}${formatMessage({
				id: 'basic-table.label.collapsed'
			})}`,
		expandedRowAnnouncement: (itemKey, item) =>
			`${formatMessage({
				id: 'basic-table.label.more-about'
			})} ${item.commitment}${formatMessage({
				id: 'basic-table.label.expanded'
			})}`,
		rowExpandButtonLabel: (itemKey, { item, expanded }) =>
			`${
				expanded
					? formatMessage({
							id: 'loan-summary.view-commitment-details.table.label.collapse'
					  })
					: formatMessage({
							id: 'loan-summary.view-commitment-details.table.label.expand'
					  })
			} ${formatMessage({
				id: 'basic-table.label.more-about'
			})} ${item.commitment}`,
		rowExpandedDetails: (itemKey, item) =>
			`${item.commitment} ${formatMessage({
				id: 'basic-table.label.expanded-details'
			})}`
	}
	function commitmentDetailsPath(rowData) {
		setPath({
			path: '/viewCommitmentDetails',
			params: { rowData }
		})
	}
	useEffect(() => {
		if (isInitialLoad) {
			setIsInitialLoad(false)
			clearMessages()
			dispatch(updateSystemMessage([]))
			if (params) {
				dispatch(resetTableData())
				dispatch(setTableParams(params))
			}
		}
	}, [dispatch, params, clearMessages, isInitialLoad])

	function handlePaginationAndSorting(paginationParams) {
		const updatedParams = { ...tableParams, ...paginationParams }
		dispatch(setTableParams(updatedParams))
		dispatch(getLoanSummaryDetails(updatedParams))
	}

	const commitmentIndex = columns.findIndex(col => col.name === 'commitment')
	if (commitmentIndex !== -1)
		columns[commitmentIndex].formatter = rowData => {
			if (!rowData) {
				return ''
			}
			return (
				<Button
					label={rowData.item.commitment}
					kind="simple"
					underlined="true"
					role="link"
					aria-label={`${formatMessage({
						id: 'loan-summary.view-commitment-details.table.commitment.aria-label.text'
					})} ${rowData.item.commitment}`}
					className="CustomFacilityColumn"
					onClick={() => {
						commitmentDetailsPath(rowData)
					}}
				/>
			)
		}

	return (
		<BasicTable
			rows={rows}
			ledgerAppId={ledgerAppId}
			columns={columns}
			className={className}
			extraContent={extraContent}
			footer={footer}
			primaryKey={primaryKey}
			header={header}
			ariaCaption={formatMessage({
				id: 'loan-summary.view-commitment-details.table.commitment.aria-caption'
			})}
			messages={updatedMessages}
			{...tableData}
			onSortRows={handlePaginationAndSorting}
			pagination={{
				show: true,
				onChange: paginationParams => handlePaginationAndSorting(paginationParams),
				paginationClassName: 'wf-col--12 label-right'
			}}
		/>
	)
}

function HomePageSystemMessage({ systemMessages }) {
	if (isEmpty(systemMessages)) {
		return <BasicNoContent />
	}
	const { iconComponent, theme } = getSystemMessageIconData(systemMessages.icon, 'medium')
	return (
		<div className="CommitmentSystemMessage">
			<Feedback icon={iconComponent} size="large" role="alert" padding border theme={theme}>
				{systemMessages.message}
			</Feedback>
		</div>
	)
}

function AfsFooterContent({ rowLength, formatMessage }) {
	if (rowLength > 0) {
		return (
			<div className="Footer">
				<div className="FooterContent">
					<b>
						{formatMessage({
							id:
								'loan-summary.view-commitment-details.table.footer.content.action-date-or-rateMaturity-date.bold.text'
						})}
					</b>
					<span>
						{formatMessage({
							id:
								'loan-summary.view-commitment-details.table.footer.content.action-date-or-rateMaturity-date'
						})}
					</span>
				</div>
				<div className="FooterContent">
					<b>
						{formatMessage({
							id: 'loan-summary.view-commitment-details.table.footer.content.amound-used.bold.text'
						})}
					</b>
					<span>
						{formatMessage({
							id: 'loan-summary.view-commitment-details.table.footer.content.amound-used'
						})}
					</span>
				</div>

				<div className="FooterContent">
					<b>
						{formatMessage({
							id:
								'loan-summary.view-commitment-details.table.footer.content.amound-available.bold.text'
						})}
					</b>
					<span>
						{formatMessage({
							id: 'loan-summary.view-commitment-details.table.footer.content.amound-available'
						})}
					</span>
				</div>
				<hr />
			</div>
		)
	}
	return <BasicNoContent />
}

function HomePageTableHeader({ rowLength, formatMessage }) {
	if (rowLength === 0) {
		return <BasicNoContent />
	}
	return (
		<div className="HomePageTableHeader">
			<BasicText text={formatMessage({ id: 'loan-summary.view-page-table.expand-instructions' })} />
		</div>
	)
}

const CustomHomePageTable = createRddComponent(
	withFetchDataLink({ dataLink: {} })(CustomHomePageTableComponent)
)

CustomHomePageTable.displayName = 'CustomHomePageTable'

export default CustomHomePageTable
