import {
	GET_SELECTED_OBLIGATION_ROW,
	OPEN_ADD_OBLIGATION_NICKNAME_PANEL,
	OPEN_EDIT_OBLIGATION_NICKNAME_PANEL,
	GET_OBLIGATION_DETAILS_TABS_CONTENT_SYSTEM_MESSAGES,
	RESET_OBLIGATION_DETAILS_TAB_CONTENT,
	CLEAR_OBLIGATION_DETAILS_TAB_CONTENT,
	GET_OBLIGATION_DETAILS_SUMMARY_SUCCESS,
	GET_OBLIGATION_DETAILS_TRANSACTION_HISTORY_SUCCESS,
	SHOW_OBLIGATION_DETAILS_LOADING,
	UPDATE_FILTER_VALUE
} from '../actions/obligation'

export const initialState = {
	get showAddObligationNicknamePanel() {
		return false
	},
	get showEditObligationNicknamePanel() {
		return false
	},
	get obligationRow() {
		return {}
	},
	get obligationDetailsSystemMessage() {
		return []
	},
	get obligationDetailsSummary() {
		return {}
	},
	get showObligationLoading() {
		return false
	},
	get obligationDetailsTransactionHistory() {
		return {
			get dataLink() {
				return {
					get params() {
						return {
							get rowsOffset() {
								return 0
							},
							get rowsBatchSize() {
								return 10
							},
							get orderByField() {
								return ''
							},
							get orderByType() {
								return 'ASC'
							}
						}
					}
				}
			},
			get rows() {
				return []
			}
		}
	}
}

export function obligation(state = initialState, action) {
	switch (action.type) {
		case OPEN_ADD_OBLIGATION_NICKNAME_PANEL:
			return {
				...state,
				showAddObligationNicknamePanel: action.isOpen
			}
		case OPEN_EDIT_OBLIGATION_NICKNAME_PANEL:
			return {
				...state,
				showEditObligationNicknamePanel: action.isOpen
			}
		case GET_SELECTED_OBLIGATION_ROW:
			return {
				...state,
				obligationRow: action.obligationRow
			}
		case GET_OBLIGATION_DETAILS_TABS_CONTENT_SYSTEM_MESSAGES:
			return {
				...state,
				obligationDetailsSystemMessage: action.systemMessages
			}
		case RESET_OBLIGATION_DETAILS_TAB_CONTENT:
			return {
				...state,
				obligationDetailsTransactionHistory: {},
				obligationDetailsSummary: {}
			}
		case GET_OBLIGATION_DETAILS_SUMMARY_SUCCESS:
			return {
				...state,
				obligationDetailsSummary: action.obligationDetailsSummary
			}
		case GET_OBLIGATION_DETAILS_TRANSACTION_HISTORY_SUCCESS:
			const { obligationDetailsTransactionHistory = {} } = action
			const {
				dataLink: { params = {} }
			} = obligationDetailsTransactionHistory
			const { userData = {} } = params
			const { filterData = {} } = userData
			const { filterType, startEffectiveDate, endEffectiveDate } = filterData
			return {
				...state,
				obligationDetailsTransactionHistory: obligationDetailsTransactionHistory,
				appliedFilters: {
					...state.appliedFilters,
					effectiveDateType: startEffectiveDate === null ? filterType : 'customDate',
					startEffectiveDate: startEffectiveDate === null ? undefined : startEffectiveDate,
					endEffectiveDate: endEffectiveDate === null ? undefined : endEffectiveDate
				}
			}
		case UPDATE_FILTER_VALUE:
			return {
				...state,
				appliedFilters: {
					...state.appliedFilters,
					[action.filter.key]: action.filter.value
				}
			}
		case CLEAR_OBLIGATION_DETAILS_TAB_CONTENT:
			return {
				...state,
				obligationDetailsSystemMessage: initialState.obligationDetailsSystemMessage,
				obligationDetailsSummary: initialState.obligationDetailsSummary,
				obligationDetailsTransactionHistory: initialState.obligationDetailsTransactionHistory
			}
		case SHOW_OBLIGATION_DETAILS_LOADING:
			return {
				...state,
				showObligationLoading: action.showObligationLoading
			}
		default:
			return state
	}
}
