import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withUserDataForm } from 'ecpfs-react-data-driven-components'
import { AddObligationNicknamePanel } from '../components/AddObligationNicknamePanel'
import { submitObligationNickname, openAddObligationNicknamePanel } from '../actions/obligation'

export const mapStateToProps = state => ({
	show: state.obligation.showAddObligationNicknamePanel,
	obligationRow: state.obligation.obligationRow
})

export const mapDispatchToProps = dispatch => ({
	onSubmitObligationNickname(params, commitmentDetailsSelectedTab) {
		dispatch(submitObligationNickname(params, commitmentDetailsSelectedTab))
	},
	onOpenPanel(isOpen) {
		dispatch(openAddObligationNicknamePanel(isOpen))
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withUserDataForm()(AddObligationNicknamePanel))
)
component.displayName = 'AddObligationNicknamePanel'

export default component
