import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
	getSelectedCommitmentDetails,
	loadCommitmentDetailsPage,
	setDropdownValue,
	getCommitmentTableData,
	getCommitmentDetails
} from '../actions/commitment'
import { ViewCommitmentDetails } from '../components/ViewCommitmentDetails'
import get from 'lodash.get'
import { updateSystemMessage } from '../actions/systemMessages'

export const mapStateToProps = state => ({
	commitments: get(state, 'commitment.commitments'),
	commitmentDetails: get(state, 'commitment.commitmentDetails'),
	ledger: get(state, 'payments.selectedLedger.dataLink.params.encryptedKey'),
	facilityKey: get(state, 'commitment.facilityKey'),
	systemMessages: get(state, 'systemMessages.systemMessages', []),
	showLoading: get(state, 'siteLoader.showLoading', false),
	dropdownValue: state.commitment.dropdownValue,
	tabs: state.commitment.tabs
})

export const mapDispatchToProps = dispatch => ({
	onLoadCommitmentDetailsPage(facilityKey, defaultTab) {
		dispatch(loadCommitmentDetailsPage(facilityKey, defaultTab))
	},
	onGetSelectedCommitmentDetails(facilityKey, defaultTab) {
		dispatch(getSelectedCommitmentDetails(facilityKey, defaultTab))
	},
	onSetDropdownValue(dropdownValue) {
		dispatch(setDropdownValue(dropdownValue))
	},
	onUpdateSystemMessage(systemMessages) {
		dispatch(updateSystemMessage(systemMessages))
	},
	onGetCommitmentTableData(selectedTab, facilityKey, pagination, filterData) {
		dispatch(getCommitmentTableData(selectedTab, facilityKey, pagination, filterData))
	},
	onGetCommitmentDetails(facilityKey) {
		dispatch(getCommitmentDetails(facilityKey))
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ViewCommitmentDetails)
)

component.displayName = 'ViewCommitmentDetails'
export default component
