import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { MenuItem, MenuButton } from '@wf/ria'
import {
	BasicNoContent,
	useFetchDataLinkWithUserData,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { onExport } from './CustomColumnDownloadActions'
import { sendAnalytics } from '../utils/sendAnalytics'
import {
	openAddNicknamePanel,
	openEditNicknamePanel,
	deleteNickname,
	getSelectedCommitmentRow
} from '../actions/commitment'
import { CustomNicknameOptions } from './CustomNicknameOptions'

export function CustomMenuDropDown({
	label,
	event,
	display = 'simple',
	items = [],
	className,
	rowData = {},
	ariaLabel
}) {
	return (
		<MenuDropDown
			label={label}
			event={event}
			ariaLabel={ariaLabel}
			display={display}
			items={items}
			rowData={rowData}
			className={className}
		/>
	)
}

export function MenuDropDown({ label, event, display, items, className, rowData, ariaLabel }) {
	const { formatMessage } = useIntl()

	const { item = {} } = rowData
	const dispatch = useDispatch()

	const tableParams = useSelector(state => state?.commitment?.tableParams)
	const { clearMessages } = useContext(SystemMessagesContext)

	const [submit] = useFetchDataLinkWithUserData()

	function onOpenAddNicknamePanel() {
		clearMessages()
		dispatch(openAddNicknamePanel(true))
		dispatch(getSelectedCommitmentRow(item))
	}

	function onOpenEditNicknamePanel() {
		clearMessages()
		dispatch(openEditNicknamePanel(true))
		dispatch(getSelectedCommitmentRow(item))
	}

	function onDeleteNickname() {
		clearMessages()
		dispatch(getSelectedCommitmentRow(item))
		dispatch(deleteNickname(tableParams))
	}

	function onSelected(value, row) {
		if (value === 'editNickName') {
			onOpenEditNicknamePanel()
		} else if (value === 'addNickName') {
			onOpenAddNicknamePanel()
		} else if (value === 'deleteNickname') {
			onDeleteNickname()
		} else {
			onSelectedOption(value)
		}
	}

	function onSelectedOption(value) {
		const menuItem = items.find(({ name }) => name === value)
		const { event: itemEvent } = menuItem
		onItemClick(menuItem, rowData, submit, dispatch)
		sendAnalytics(itemEvent)
	}

	const classNames = ['CustomMenuDropDown']
	if (typeof className === 'string') {
		classNames.push(className)
	}
	const menuButtonClassName = classNames.join(' ')

	//https://jira.wellsfargo.net/browse/EFMYA-24923
	if (
		label === 'Export' &&
		window?.Bridge?.isNative() &&
		items.length > 0 &&
		items[0].dataLink?.download === true
	) {
		return <BasicNoContent />
	}
	function getAriaLabel() {
		if (ariaLabel === 'commitment') {
			return `${item.commitment} commitment select`
		}
		return ariaLabel
	}

	return (
		<div onClick={() => sendAnalytics(event)}>
			<MenuButton
				className={menuButtonClassName}
				kind={display}
				aria-label={getAriaLabel() || label}
				label={label}
				onSelectedValueChange={value => onSelected(value, rowData)}
			>
				{MenuItems({ event, items, rowData, submit })}
				{NicknameMenuItems({ label, item, formatMessage })}
				{DeleteNicknameMenuItem({ item, formatMessage })}
			</MenuButton>
			<CustomNicknameOptions encryptedKey={item.encryptedKey} />
		</div>
	)
}

export function MenuItems({ items }) {
	if (items.length === 0) return <BasicNoContent />
	return items.map(item => {
		const { name, label } = item
		return (
			<MenuItem key={name} value={name}>
				{label}
			</MenuItem>
		)
	})
}

export function NicknameMenuItems({ label, item, formatMessage }) {
	const { facilityNickname = '', modifyPermission = false } = item

	if (label === 'Select' && modifyPermission) {
		if (facilityNickname) {
			return (
				<MenuItem key="editNickName" value="editNickName">
					{formatMessage({
						id: 'custom-action-menu-dropdown.label.edit-nickname'
					})}
				</MenuItem>
			)
		} else {
			return (
				<MenuItem key="addNickName" value="addNickName">
					{formatMessage({
						id: 'custom-action-menu-dropdown.label.add-nickname'
					})}
				</MenuItem>
			)
		}
	}
	return <BasicNoContent />
}

export function DeleteNicknameMenuItem({ item, formatMessage }) {
	const { facilityNickname = '', modifyPermission = false } = item

	if (modifyPermission && facilityNickname) {
		return (
			<MenuItem key="deleteNickname" value="deleteNickname">
				{formatMessage({
					id: 'custom-action-menu-dropdown.label.delete-nickname'
				})}
			</MenuItem>
		)
	}
	return <BasicNoContent />
}

export function onItemClick(item, rowData, submit, dispatch) {
	const { dataLink = {} } = item
	const { api, params, download } = dataLink
	if (download) {
		onExport(api, params, dispatch)
	} else {
		const useDataLink = getDataLink(item, rowData)
		submit({ ...useDataLink, ttl: 0 })
	}
}

export function getDataLink(item, rowData) {
	if (item.dataLink) {
		return {
			...item.dataLink,
			params: {
				...item.dataLink.params,
				rowData
			}
		}
	}
}

CustomMenuDropDown.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	/**
	 * Determine if button is displayed as primary or secondary action. Default (primary).
	 */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			label: PropTypes.string
		})
	)
}

CustomMenuDropDown.displayName = 'CustomMenuDropDown'

export default CustomMenuDropDown
