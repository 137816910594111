import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { IconPrint } from '@wf/ria-icons'
import {
	organizeClassNames,
	useEvents,
	useParseLayout,
	BasicAction,
	RenderComponent
} from 'ecpfs-react-data-driven-components'
import { PrintAreaContext } from './CustomPrintArea'

export function CustomPrintButton(props) {
	const { htmlData, label, showIcon, event } = props
	const layout = useParseLayout(props.layout)
	const className = organizeClassNames(props.className, 'CustomPrintButton')

	if (props.layout) {
		const fieldClasses = organizeClassNames(className, layout.field.classes)
		return (
			<div className={layout.width.classes} style={layout.width.styles}>
				<div aria-hidden="true" className={layout.label.classes} style={layout.label.styles} />
				<div
					className={fieldClasses}
					data-wf-efmya-tag="basic-print-button"
					{...htmlData}
					style={layout.field.styles}
				>
					<CustomPrintAction label={label} showIcon={showIcon} event={event} />
				</div>
			</div>
		)
	}

	return (
		<div className={className} data-wf-efmya-tag="basic-print-button" {...htmlData}>
			<CustomPrintAction label={label} showIcon={showIcon} event={event} />
		</div>
	)
}

export function CustomPrintAction({ label, event, showIcon = true }) {
	const notify = useEvents()
	const children = useContext(PrintAreaContext)
	const data = {
		label,
		display: 'standard',
		onClick() {
			notify(event)
			handlePrint(children).then(() => {
				if (
					window.Bridge &&
					window.Bridge.getInstance !== undefined &&
					typeof window.Bridge.getInstance === 'function'
				) {
					const bridgeInstance = new window.Bridge.getInstance('ceo-liq')
					bridgeInstance.print()
				} else {
					window.print()
				}
			})
		}
	}
	if (showIcon) {
		data.leftIcon = <IconPrint size="small" decorative />
	}
	return <RenderComponent data={data} DefaultComponent={BasicAction} />
}

async function handlePrint(children) {
	await ReactDOM.render(
		PrintArea(children),
		document.querySelector('[data-wf-efmya-media="print"]')
	)
}

function PrintArea(children) {
	if (children.current instanceof HTMLElement) {
		const toBePrinted = children.current.cloneNode(true)
		return <div key={new Date()} ref={ref => ref && ref.appendChild(toBePrinted)} />
	}
	return null
}

CustomPrintButton.propTypes = {
	label: PropTypes.string,
	/**
	 * Toggle to display print icon in button. Default true.
	 */
	showIcon: PropTypes.bool
}

CustomPrintButton.displayName = 'CustomPrintButton'

export default CustomPrintButton
