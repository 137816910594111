import React, { Fragment, useState, useEffect, useContext } from 'react'
import {
	RenderComponents,
	BasicLabel,
	BasicNoContent,
	BasicText,
	SystemMessagesContext
} from 'ecpfs-react-data-driven-components'
import { Panel, Feedback, MessageBox } from '@wf/ria'
import { IconVerify, IconConfirmation } from '@wf/ria-icons'
import { FormattedMessage } from 'react-intl'
import CustomCustomerNumberColumn from './CustomCustomerNumberColumn'
import CustomPrintArea from './CustomPrintArea'
import { useSelector, useDispatch } from 'react-redux'
import { getAccountAndBalances } from '../actions/billingStatements'
import CustomSourceAccountTable from './CustomSourceAccountTable'
import { updateSystemMessage } from '../actions/systemMessages'
import { getSystemMessageIconData } from '../utils/getSystemMessageIconData'
import moment from 'moment-timezone'

export function MakePaymentPanel({
	show,
	onClose,
	rowData,
	formatMessage,
	onGetConfirmationViewData
}) {
	if (show === false) return <BasicNoContent />
	return (
		<PayStatementPanel
			onClose={onClose}
			rowData={rowData}
			formatMessage={formatMessage}
			onGetConfirmationViewData={onGetConfirmationViewData}
		/>
	)
}

function PanelContentRow({ labelId, value }) {
	return (
		<div className="PanelFields">
			<dt className="TextAlignRight">
				<FormattedMessage id={labelId} />
			</dt>
			<dd className="TextAlignLeft">{value}</dd>
		</div>
	)
}

function PayStatementPanel({ onClose, rowData, formatMessage, onGetConfirmationViewData }) {
	const { item = {} } = rowData
	const { invoiceNumber, invoiceDate, dueDate, invoiceAmount } = item
	const [openPayPanel, setOpenPayPanel] = useState(true)
	const [openVerifyPanel, setOpenVerifyPanel] = useState(false)
	const [openConfirmationPanel, setOpenConfirmationPanel] = useState(false)
	const [openErrorPanel, setOpenErrorPanel] = useState(false)
	const [selectedAccount, setSelectedAccount] = useState([])
	const [showInternalSiteMessage, setshowInternalSiteMessage] = useState(false)
	const { accounts, effectiveDate = '', confirmationViewData = [] } = useSelector(
		state => state.billingStatements
	)
	const { fromInternalSite = false } = useSelector(state => state.navigator)
	const { systemMessages } = useSelector(state => state.systemMessages)
	const { clearMessages } = useContext(SystemMessagesContext)
	const paymentsFeedbackMessage = systemMessages?.[0]
	const dispatch = useDispatch()
	useEffect(() => {
		const { encryptedKey } = item
		dispatch(getAccountAndBalances(encryptedKey))
	}, [dispatch, item])

	function onSubmit() {
		if (selectedAccount.length) {
			setOpenVerifyPanel(true)
			setOpenPayPanel(false)
		}
	}
	function onConfirm() {
		if (fromInternalSite) {
			setOpenVerifyPanel(false)
			setOpenErrorPanel(true)
			setshowInternalSiteMessage(true)
		} else {
			const selectedAccountEncryptedKey = getSelectedAccountEncryptedKey()
			setOpenVerifyPanel(false)
			const responseStatus = value => {
				if (value) {
					setOpenConfirmationPanel(true)
				} else {
					setOpenErrorPanel(true)
				}
			}
			onGetConfirmationViewData(
				invoiceNumber,
				invoiceAmount,
				selectedAccountEncryptedKey,
				responseStatus
			)
		}
	}
	function getSelectedAccountEncryptedKey() {
		if (accounts.length > 0) {
			const accountEncryptedKey = accounts.filter(
				account => account.encryptedKey === selectedAccount[0]
			)[0].encryptedKey
			return accountEncryptedKey
		}
	}
	function onVerifyEdit() {
		setOpenVerifyPanel(false)
		setshowInternalSiteMessage(false)
		setOpenPayPanel(true)
	}
	function onCloseVerifyPanel() {
		clearMessages()
		dispatch(updateSystemMessage([]))
		setOpenVerifyPanel(false)
		onClose()
	}
	function onCloseConfirmationpanel() {
		setOpenConfirmationPanel(false)
		onClose()
	}
	function onCloseErrorPanel() {
		clearMessages()
		dispatch(updateSystemMessage([]))
		setOpenErrorPanel(false)
		onClose()
	}
	function onCancel() {
		clearMessages()
		dispatch(updateSystemMessage([]))
		onClose()
	}
	const sourceAccountNumber = getSourceAccountNumber()
	function getSourceAccountNumber() {
		if (accounts.length > 0) {
			const accountNumber = accounts.filter(
				account => account.encryptedKey === selectedAccount[0]
			)[0]?.maskedAccountNumber
			return accountNumber
		}
	}
	const customToolbar = {
		actions: [
			{
				tag: 'BasicAction',
				className: 'SubmitPayment',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.submit'
				}),
				onClick: onSubmit
			},
			{
				tag: 'BasicAction',
				className: 'CancelPayment',
				display: 'standard',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.cancel'
				}),
				onClick: onCancel
			}
		]
	}

	return (
		<Fragment>
			<Panel
				className="PayStatementPanel"
				width="600px"
				onClose={onCancel}
				open={openPayPanel}
				title={formatMessage({
					id: 'loan-billing.statements-page.modal.title'
				})}
				footer={<RenderComponents list={customToolbar.actions} />}
				elementToFocusOnClose=".PayStatementPanel button"
			>
				<PaymentsMessage paymentsFeedbackMessage={paymentsFeedbackMessage} />
				<BasicText
					required={true}
					text={formatMessage({ id: 'loan-billing.statements-page.modal.required' })}
				/>
				<dl className="wf-dl-inline">
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.invoice-number"
						value={invoiceNumber}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.label.invoice-date"
						value={invoiceDate}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.due-date"
						value={dueDate}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.customer"
						value={<CustomCustomerNumberColumn rowData={rowData} />}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.invoice-amount"
						value={`${invoiceAmount} ${formatMessage({
							id: 'view-interest-panel.label.currency-usd'
						})}`}
					/>
				</dl>
				<CustomSourceAccountTable
					formatMessage={formatMessage}
					accounts={accounts}
					selectedAccount={selectedAccount}
					setSelectedAccount={setSelectedAccount}
					ariaCaption={formatMessage({
						id: 'pay-statement-panel.label.source-account-aria-caption'
					})}
				/>
				<Disclaimer isPayPanel={true} />
			</Panel>
			<VerifyPanel
				rowData={rowData}
				isOpen={openVerifyPanel}
				onClose={onCloseVerifyPanel}
				onConfirm={onConfirm}
				formatMessage={formatMessage}
				onVerifyEdit={onVerifyEdit}
				sourceAccountNumber={sourceAccountNumber}
				effectiveDate={effectiveDate}
			/>
			<ConfirmationPanel
				rowData={rowData}
				isOpen={openConfirmationPanel}
				onClose={onCloseConfirmationpanel}
				formatMessage={formatMessage}
				sourceAccountNumber={sourceAccountNumber}
				confirmationViewData={confirmationViewData}
			/>
			<ErrorPanel
				isOpen={openErrorPanel}
				onClose={onCloseErrorPanel}
				formatMessage={formatMessage}
				showInternalSiteMessage={showInternalSiteMessage}
				systemMessages={systemMessages}
			/>
		</Fragment>
	)
}

function InternalSiteMessage({ showInternalSiteMessage, systemMessage }) {
	if (showInternalSiteMessage) {
		return (
			<div className="InternalSiteMessage">
				<FormattedMessage id="loan-billing.statements-page.modal.verify.internal-site.message" />
			</div>
		)
	} else if (systemMessage) {
		return <div className="InternalSiteMessage">{systemMessage.message}</div>
	}
	return <BasicNoContent />
}

function PaymentsMessage({ paymentsFeedbackMessage }) {
	if (!paymentsFeedbackMessage) {
		return <BasicNoContent />
	}
	const { iconComponent, theme } = getSystemMessageIconData(paymentsFeedbackMessage.icon, 'medium')
	return (
		<Feedback icon={iconComponent} size="large" padding border theme={theme} role="alert">
			{paymentsFeedbackMessage.message}
		</Feedback>
	)
}

function VerifyPanel({
	rowData,
	isOpen,
	onClose,
	onConfirm,
	formatMessage,
	onVerifyEdit,
	sourceAccountNumber,
	effectiveDate,
	showInternalSiteMessage
}) {
	const { item } = rowData
	const customToolbar = {
		actions: [
			{
				tag: 'BasicAction',
				className: 'ConfirmButton',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.confirm'
				}),
				onClick: onConfirm
			},
			{
				tag: 'BasicAction',
				className: 'VerifyEditButton',
				display: 'standard',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.edit'
				}),
				onClick: onVerifyEdit
			},
			{
				tag: 'BasicAction',
				className: 'VerifyCancelButton',
				display: 'chromeless',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.cancel'
				}),
				onClick: onClose
			}
		]
	}

	return (
		<Panel
			className="VerifyStatementPanel"
			width="700px"
			onClose={onClose}
			open={isOpen}
			title={formatMessage({
				id: 'loan-billing.statements-page.verify.modal.header'
			})}
			footer={<RenderComponents list={customToolbar.actions} />}
			elementToFocusOnClose=".PayStatementPanel button"
		>
			<Feedback icon={<IconVerify size="large" theme="information" />} role="alert" size="large">
				<p>
					<FormattedMessage id="loan-billing.statements-page.modal.verify.informational.message" />
				</p>
			</Feedback>
			<BasicLabel
				level={2}
				label={formatMessage({
					id: 'loan-billing.statements-page.verify.modal.secondary.header'
				})}
				className="wf-header-secondary"
			/>

			<dl className="wf-dl-inline">
				<PanelContentRow
					labelId="loan-billing.statements-page.modal.column.label.invoice-number"
					value={item.invoiceNumber}
				/>
				<PanelContentRow
					labelId="loan-billing.statements-page.modal.label.invoice-date"
					value={item.invoiceDate}
				/>
				<PanelContentRow
					labelId="loan-billing.statements-page.modal.column.label.due-date"
					value={item.dueDate}
				/>
				<PanelContentRow
					labelId="loan-billing.statements-page.modal.column.label.customer"
					value={<CustomCustomerNumberColumn rowData={rowData} />}
				/>
				<PanelContentRow
					labelId="loan-billing.statements-page.modal.column.label.invoice-amount"
					value={`${item.invoiceAmount} ${formatMessage({
						id: 'view-interest-panel.label.currency-usd'
					})}`}
				/>
				<PanelContentRow
					labelId="loan-billing.statements-page.modal.verify.label.source-account"
					value={sourceAccountNumber}
				/>
				<PanelContentRow
					labelId="loan-billing.statements-page.modal.column.label.estimated-effective-date"
					value={effectiveDate}
				/>
				<Disclaimer isPayPanel={false} />
			</dl>
		</Panel>
	)
}

function Disclaimer({ isPayPanel }) {
	if (isPayPanel === true) {
		return (
			<div className="Disclaimer">
				<FormattedMessage id={'loan-billing.statements-page.payment.disclaimer'} />
			</div>
		)
	}

	return (
		<div className="Disclaimer">
			<FormattedMessage id={'loan-billing.statements-page.verify.disclaimer'} />
		</div>
	)
}

function ConfirmationPanel({
	rowData,
	isOpen,
	onClose,
	formatMessage,
	confirmationViewData,
	sourceAccountNumber
}) {
	const { item } = rowData
	const customToolbar = {
		actions: [
			{
				tag: 'CustomPrintButton',
				className: 'ConfirmationPrintButton',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.print'
				}),
				showIcon: false
			},
			{
				tag: 'BasicAction',
				className: 'ConfirmationCloseButton',
				display: 'chromeless',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.close'
				}),
				onClick: onClose
			}
		]
	}
	return (
		<CustomPrintArea>
			<Panel
				className="ConfirmationStatementPanel"
				onClose={onClose}
				open={isOpen}
				title={formatMessage({
					id: 'loan-billing.statements-page.confirmation.modal.header'
				})}
				footer={<RenderComponents list={customToolbar.actions} />}
				elementToFocusOnClose=".PayStatementPanel button"
			>
				<Feedback
					icon={<IconConfirmation size="large" theme="confirmation" />}
					size="large"
					role="alert"
				>
					<p>
						<FormattedMessage id="loan-billing.statements-page.confirmation.modal.message" />
					</p>
				</Feedback>
				<BasicLabel
					level={2}
					label={formatMessage({
						id: 'loan-billing.statements-page.verify.modal.secondary.header'
					})}
					className="wf-header-secondary"
				/>
				<dl className="wf-dl-inline">
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.invoice-number"
						value={item.invoiceNumber}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.label.invoice-date"
						value={item.invoiceDate}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.due-date"
						value={item.dueDate}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.customer"
						value={<CustomCustomerNumberColumn rowData={rowData} />}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.invoice-amount"
						value={`${item.invoiceAmount} ${formatMessage({
							id: 'view-interest-panel.label.currency-usd'
						})}`}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.verify.label.source-account"
						value={sourceAccountNumber}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.effective-date"
						value={confirmationViewData[0]?.effectiveDate}
					/>
					<PanelContentRow
						labelId="loan-billing.statements-page.modal.column.label.submission-date"
						value={`${moment()
							.tz('America/Los_Angeles')
							.format('MM/DD/YYYY hh:mm a')} PT`}
					/>
					<Disclaimer isPayPanel={false} />
				</dl>
			</Panel>
		</CustomPrintArea>
	)
}

function ErrorPanel({ isOpen, onClose, formatMessage, showInternalSiteMessage, systemMessages }) {
	const customToolbar = {
		actions: [
			{
				tag: 'BasicAction',
				className: 'ErrorCancelButton',
				label: formatMessage({
					id: 'loan-billing.statements-page.modal.ok'
				}),
				onClick: onClose
			}
		]
	}
	return (
		<MessageBox
			title={formatMessage({
				id: 'loan-billing.statements-page.modal.error'
			})}
			className="ErrorStatementPanel"
			onClose={onClose}
			open={isOpen}
			elementToFocusOnClose=".PayStatementButton button"
			footer={<RenderComponents list={customToolbar.actions} />}
			type={MessageBox.TYPES.ERROR}
		>
			<InternalSiteMessage
				showInternalSiteMessage={showInternalSiteMessage}
				systemMessage={systemMessages?.[0]}
			/>
		</MessageBox>
	)
}
