import React from 'react'
import PropTypes from 'prop-types'
import { BasicLabelValue, BasicNoContent } from 'ecpfs-react-data-driven-components'

export function CustomFacilityBasicLabelValue({ label, className, rowData, name, ...props }) {
	const rowValue = rowData.item[name]

	if (name === 'expiryDate' && rowValue === rowData.item['maturityDate']) {
		return <BasicNoContent />
	}

	if (!label || !rowValue || rowValue === '') {
		return <BasicNoContent />
	}

	return <BasicLabelValue label={label} value={rowValue} className={className} />
}

CustomFacilityBasicLabelValue.propTypes = {
	label: PropTypes.string,
	rowData: PropTypes.object,
	name: PropTypes.string
}

CustomFacilityBasicLabelValue.displayName = 'CustomFacilityBasicLabelValue'

export default CustomFacilityBasicLabelValue
