import {
	GET_SELECTED_COMMITMENT_ROW,
	OPEN_ADD_NICKNAME_PANEL,
	OPEN_EDIT_NICKNAME_PANEL,
	SET_TABLE_DATA,
	RESET_TABLE_DATA,
	RESET_PAYDOWNS_AND_ADVANCES_TABLE_DATA,
	SET_TABLE_PARAMS,
	SET_LOAN_SUMMARY_PATH,
	GET_COMMITMENT_DETAILS_SUCCESS,
	SET_SELECTED_COMMITMENT,
	GET_COMMITMENTS_SUCCESS,
	GET_COMMITMENT_TABS_SUCCESS,
	GET_COMMITMENT_TABLE_DATA_SUCCESS,
	GET_PAYDOWNS_AND_ADVANCES_DATA_SUCCESS,
	GET_ALL_CUSTOMERS_SUCCESS,
	HANDLE_FILTER_VALUE_CHANGE,
	GET_VIEW_INTEREST_DETAILS_SUCCESS,
	GET_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES,
	CLEAR_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES,
	CLEAR_COMMITMENT_DETAILS,
	CLEAR_COMMITMENT_DETAILS_INFO,
	CLEAR_COMMITMENT_DETAILS_TABLE_DATA,
	GET_COMMITMENT_DETAILS_TABLE_SYSTEM_MESSAGES,
	UPDATE_SHOW_COMMITMENT_DETAILS_CUSTOMERS_PANEL,
	SET_DROPDOWN_VALUE,
	GET_PAYDOWNS_AND_ADVANCES_TABLE_SYSTEM_MESSAGES,
	GET_PAYDOWNS_CONFIRMATION_VIEW_DATA_SUCCESS
} from '../actions/commitment'
import { DataLink } from 'ecpfs-react-data-driven-components'

export const initialState = {
	get showAddNicknamePanel() {
		return false
	},
	get showEditNicknamePanel() {
		return false
	},
	get commitmentRow() {
		return {}
	},
	get dropdownValue() {
		return 'viewObligations'
	},
	get tableData() {
		return {}
	},
	get tableParams() {
		return {}
	},
	get path() {
		return ''
	},
	get facilityKey() {
		return ''
	},
	get commitmentDetails() {
		return {}
	},
	get commitments() {
		return []
	},
	get appliedFilters() {
		return {}
	},
	get customersList() {
		return []
	},
	get viewInterestDetails() {
		return {}
	},
	get commitmentSystemMessage() {
		return {}
	},
	get payDownsAndAdvancesSystemMessage() {
		return {}
	},
	get showCustomersPanel() {
		return true
	},
	get viewInterestSystemMessages() {
		return {}
	},
	get tabs() {
		return []
	},
	get commitmentDetailsTableInfo() {
		return {
			get dataLink() {
				return {
					get params() {
						return {
							get rowsOffset() {
								return 0
							},
							get rowsBatchSize() {
								return 10
							},
							get orderByField() {
								return ''
							},
							get orderByType() {
								return 'ASC'
							}
						}
					}
				}
			},
			get rows() {
				return []
			}
		}
	},
	get payDownsAndAdvancesData() {
		return {
			get dataLink() {
				return {
					get params() {
						return {
							get isSingleCustomer() {
								return false
							}
						}
					}
				}
			},
			get rows() {
				return []
			}
		}
	},
	get payDownsConfirmationViewData() {
		return []
	}
}

export function commitment(state = initialState, action) {
	switch (action.type) {
		case GET_COMMITMENT_TABLE_DATA_SUCCESS:
			const { commitmentDetailsTableData = {} } = action
			const {
				dataLink: { params = {} }
			} = commitmentDetailsTableData
			const { userData = {} } = params
			const { filterData = {} } = userData
			const { filterType, setAsDefault, startEffectiveDate, endEffectiveDate } = filterData
			return {
				...state,
				commitmentDetailsTableInfo: commitmentDetailsTableData,
				appliedFilters: {
					...state.appliedFilters,
					effectiveDateType: startEffectiveDate === null ? filterType : 'customDate',
					setAsDefault: setAsDefault,
					startEffectiveDate: startEffectiveDate === null ? undefined : startEffectiveDate,
					endEffectiveDate: endEffectiveDate === null ? undefined : endEffectiveDate
				}
			}
		case GET_PAYDOWNS_AND_ADVANCES_DATA_SUCCESS:
			return {
				...state,
				payDownsAndAdvancesData: action.payDownsAndAdvancesData
			}
		case GET_COMMITMENT_TABS_SUCCESS:
			return {
				...state,
				tabs: action.tabs
			}
		case SET_LOAN_SUMMARY_PATH:
			return {
				...state,
				path: action.path
			}
		case SET_DROPDOWN_VALUE:
			return {
				...state,
				dropdownValue: action.dropdownValue
			}
		case SET_TABLE_DATA:
			return {
				...state,
				tableData: action.tableData
			}
		case RESET_TABLE_DATA:
			return {
				...state,
				tableData: {}
			}
		case RESET_PAYDOWNS_AND_ADVANCES_TABLE_DATA:
			return {
				...state,
				payDownsAndAdvancesData: initialState.payDownsAndAdvancesData,
				payDownsAndAdvancesSystemMessage: initialState.payDownsAndAdvancesSystemMessage
			}
		case GET_PAYDOWNS_AND_ADVANCES_TABLE_SYSTEM_MESSAGES:
			return {
				...state,
				payDownsAndAdvancesSystemMessage: DataLink.getContainerContent(action.systemMessages, [])[0]
			}
		case SET_TABLE_PARAMS:
			return {
				...state,
				tableParams: action.tableParams
			}
		case OPEN_ADD_NICKNAME_PANEL:
			return {
				...state,
				showAddNicknamePanel: action.isOpen
			}
		case OPEN_EDIT_NICKNAME_PANEL:
			return {
				...state,
				showEditNicknamePanel: action.isOpen
			}
		case GET_SELECTED_COMMITMENT_ROW:
			return {
				...state,
				commitmentRow: action.commitmentRow
			}
		case GET_COMMITMENT_DETAILS_SUCCESS:
			return {
				...state,
				commitmentDetails: DataLink.getContainerContent(action.commitmentDetails, [])[0]
			}
		case SET_SELECTED_COMMITMENT: {
			return {
				...state,
				facilityKey: action.facilityKey
			}
		}
		case GET_COMMITMENTS_SUCCESS:
			return {
				...state,
				facilityKey: DataLink.getContainerContent(
					action.commitments.dataLink.params.selectedParam,
					''
				),
				commitments: DataLink.getContainerContent(action.commitments, [])
			}
		case HANDLE_FILTER_VALUE_CHANGE:
			return {
				...state,
				appliedFilters: {
					...state.appliedFilters,
					[action.filter.key]: action.filter.value
				}
			}
		case GET_ALL_CUSTOMERS_SUCCESS:
			return {
				...state,
				customersList: action.customersList.customers
			}
		case GET_VIEW_INTEREST_DETAILS_SUCCESS:
			return {
				...state,
				viewInterestDetails: action.viewInterestDetails
			}
		case GET_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES:
			return {
				...state,
				viewInterestSystemMessages: DataLink.getContainerContent(action.systemMessages, [])[0]
			}
		case CLEAR_VIEW_INTEREST_DETAILS_SYSTEM_MESSAGES:
			return {
				...state,
				viewInterestSystemMessages: initialState.viewInterestSystemMessages
			}
		case CLEAR_COMMITMENT_DETAILS:
			return {
				...initialState
			}
		case CLEAR_COMMITMENT_DETAILS_INFO:
			return {
				...state,
				commitmentDetails: initialState.commitmentDetails,
				dropdownValue: initialState.dropdownValue
			}
		case CLEAR_COMMITMENT_DETAILS_TABLE_DATA:
			return {
				...state,
				commitmentDetailsTableInfo: initialState.commitmentDetailsTableInfo,
				commitmentSystemMessage: initialState.commitmentSystemMessage
			}
		case GET_COMMITMENT_DETAILS_TABLE_SYSTEM_MESSAGES:
			return {
				...state,
				commitmentSystemMessage: DataLink.getContainerContent(action.systemMessages, [])[0]
			}
		case UPDATE_SHOW_COMMITMENT_DETAILS_CUSTOMERS_PANEL:
			return {
				...state,
				showCustomersPanel: action.showCustomersPanel
			}
		case GET_PAYDOWNS_CONFIRMATION_VIEW_DATA_SUCCESS:
			return {
				...state,
				payDownsConfirmationViewData: action.payDownsConfirmationViewData
			}
		default:
			return state
	}
}
