import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Panel } from '@wf/ria'
import {
	BasicText,
	BasicAction,
	BasicLabelValue,
	BasicLabelInput
} from 'ecpfs-react-data-driven-components'

export function AddNicknamePanel({
	show,
	commitmentRow = {},
	tableParams,
	onOpenPanel,
	encryptedKey,
	onSubmitCommitmentNickname
}) {
	const { formatMessage } = useIntl()
	const [commitmentNickname, setCommitmentNickname] = useState('')
	const nickNameAllowedCharactersRegex = "^[A-Za-z0-9\\(\\)\\'\\,\\_\\s\\-]+$"
	const nickNameLengthRegex = /^.{1,20}$/

	function onSubmitNickname() {
		const params = {
			...commitmentRow,
			nickname: commitmentNickname
		}
		onSubmitCommitmentNickname(params, tableParams)
		onOpenPanel(false)
	}

	function onClosePanel() {
		onOpenPanel(false)
	}

	function onValueChange({ value }) {
		setCommitmentNickname(value)
	}

	return (
		<Panel
			open={show && encryptedKey === commitmentRow.encryptedKey}
			className="NickNamePanel"
			onClose={onClosePanel}
			title={formatMessage({
				id: 'add-nickname-panel.label.title'
			})}
			elementToFocusOnClose="body"
			footer={
				<div className="NicknameModalButtons">
					<BasicAction
						label={formatMessage({
							id: 'add-nickname-panel.button.label.submit'
						})}
						name={'submit'}
						display="primary"
						onClick={onSubmitNickname}
					/>
					<BasicAction
						label={formatMessage({
							id: 'add-nickname-panel.button.label.cancel'
						})}
						name={'cancel'}
						display="standard"
						className="CancelNickname"
						onClick={onClosePanel}
						disabled={false}
					/>
				</div>
			}
		>
			<div className="AddNicknamePanel">
				<div className="RequiredField">
					<BasicText
						required={true}
						text={formatMessage({ id: 'add-nickname-panel.label.required-field' })}
					/>
				</div>
				<BasicLabelValue
					label={formatMessage({
						id: 'add-nickname-panel.label.commitment'
					})}
					layout={{
						width: 'wf-row--padding',
						label: 'wf-col--3 CommitmentLabel',
						field: 'wf-col--6'
					}}
					value={commitmentRow.commitment}
				/>
				<BasicLabelInput
					required={true}
					name="nickname"
					label={formatMessage({
						id: 'add-nickname-panel.label.new-nickname'
					})}
					layout={{
						width: { classes: 'wf-row--padding' },
						label: 'wf-col--4 wf-col--mob-12 NicknameLabel',
						field: 'wf-col--desk-6 wf-col--tab-6 wf-col--mob-10'
					}}
					value=""
					onChanged={onValueChange}
					help={formatMessage({
						id: 'commitment-nickname-panel.label.help'
					})}
					validations={{
						[`${formatMessage({
							id: 'customer-service.basic.input.field-is-required.message'
						})}`]: 'required',
						[formatMessage({
							id: 'add-nickname-panel.validation.message.allowed-characters'
						})]: nickNameAllowedCharactersRegex,
						[formatMessage({
							id: 'add-nickname-panel.validation.message.invalid.maximum.length'
						})]: nickNameLengthRegex
					}}
				/>
			</div>
		</Panel>
	)
}
