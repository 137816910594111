import { Validator } from 'jsonschema'

const validator = new Validator()

export function validateJson(schema, instance) {
	try {
		const validationResult = validator.validate(instance, schema)
		console.log('validationResult', validationResult)
		const { errors, valid } = validationResult
		if (Array.isArray(errors) && errors.length > 0) {
			console.error(`Errors for: --${schema.id}--`, errors)
		}
		return valid
	} catch (error) {
		console.error(error)
		return false
	}
}
