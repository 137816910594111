import get from 'lodash.get'
import { GET_APP_NAVIGATOR_SUCCESS } from '../actions/navigator'

import { UPDATE_SHOW_FEEDBACK_PROMPT } from '../actions/feedback'

export const initialState = {
	get showFeedbackPrompt() {
		return false
	}
}

export function feedback(state = initialState, action) {
	switch (action.type) {
		case UPDATE_SHOW_FEEDBACK_PROMPT:
			return {
				...state,
				showFeedbackPrompt: action.showFeedbackPrompt
			}
		case GET_APP_NAVIGATOR_SUCCESS:
			return {
				...state,
				showFeedbackPrompt: get(action.navigator, 'session.signOut.showFeedbackPrompt', false)
			}
		default:
			return state
	}
}
