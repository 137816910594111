import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { ObligationDetailsPanelContent } from '../components/ObligationDetailsPanelContent'
import {
	getObligationDetailsSummary,
	getObligationDetailsTransactionHistory,
	resetObligationDetailsTabContent,
	updateFilterValue,
	clearObligationDetailsTabContent
} from '../actions/obligation'

export const mapStateToProps = state => ({
	obligationDetailsSummaryContent: state.obligation.obligationDetailsSummary,
	obligationDetailsTableContent: state.obligation.obligationDetailsTransactionHistory,
	showObligationLoading: state.obligation.showObligationLoading,
	commitmentDetails: state.commitment.commitmentDetails,
	appliedFilters: state.obligation.appliedFilters,
	preferenceMonth: state.ledgers.preferenceMonth,
	validStartMonth: state.ledgers.validStartMonth,
	validEndMonth: state.ledgers.validEndMonth
})

export const mapDispatchToProps = dispatch => ({
	onGetObligationDetailsSummaryContent(encryptedKey) {
		dispatch(getObligationDetailsSummary(encryptedKey))
	},
	onGetObligationDetailsTabContent(encryptedKey, pagination, filterData) {
		dispatch(getObligationDetailsTransactionHistory(encryptedKey, pagination, filterData))
	},
	onResetObligationDetailsTabContent() {
		dispatch(resetObligationDetailsTabContent())
	},
	onApplyFilter(encryptedKey, pagination, filterData) {
		dispatch(getObligationDetailsTransactionHistory(encryptedKey, pagination, filterData))
	},
	onUpdateFilterValue(filter) {
		dispatch(updateFilterValue(filter))
	},
	onclearObligationDetailsTabContent() {
		dispatch(clearObligationDetailsTabContent())
	}
})

const component = injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ObligationDetailsPanelContent)
)
component.displayName = 'ObligationDetailsPanelContent'

export default component
