import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { MenuItem, MenuButton } from '@wf/ria'
import { BasicNoContent, SystemMessagesContext } from 'ecpfs-react-data-driven-components'
import {
	openAddObligationNicknamePanel,
	openEditObligationNicknamePanel,
	deleteObligationNickname,
	getSelectedObligationRow
} from '../actions/obligation'
import AddObligationNicknamePanel from '../containers/AddObligationNicknamePanel'
import EditObligationNicknamePanel from '../containers/EditObligationNicknamePanel'

export function CustomObligationMenu(props) {
	const { formatMessage } = useIntl()
	const { label, display = 'simple', rowData = {}, commitmentDetailsSelectedTab } = props
	const { item = {} } = rowData
	const dispatch = useDispatch()
	const { clearMessages } = useContext(SystemMessagesContext)

	function onOpenAddObligationNicknamePanel() {
		clearMessages()
		dispatch(openAddObligationNicknamePanel(true))
		dispatch(getSelectedObligationRow(item))
	}

	function onOpenEditObligationNicknamePanel() {
		clearMessages()
		dispatch(openEditObligationNicknamePanel(true))
		dispatch(getSelectedObligationRow(item))
	}

	function onDeleteObligationNickname() {
		clearMessages()
		dispatch(getSelectedObligationRow(item))
		dispatch(deleteObligationNickname(commitmentDetailsSelectedTab))
	}

	function onSelected(value) {
		if (value === 'editObligationNickName') {
			onOpenEditObligationNicknamePanel()
		} else if (value === 'addObligationNickName') {
			onOpenAddObligationNicknamePanel()
		} else {
			onDeleteObligationNickname()
		}
	}

	return (
		<div>
			<MenuButton
				className="CustomObligationMenu"
				kind={display}
				label={label}
				onSelectedValueChange={value => onSelected(value)}
			>
				{NicknameMenuItems({ item, formatMessage })}
				{DeleteNicknameMenuItem({ item, formatMessage })}
			</MenuButton>
			<AddObligationNicknamePanel
				encryptedKey={item.encryptedKey}
				commitmentDetailsSelectedTab={commitmentDetailsSelectedTab}
			/>
			<EditObligationNicknamePanel
				encryptedKey={item.encryptedKey}
				commitmentDetailsSelectedTab={commitmentDetailsSelectedTab}
			/>
		</div>
	)
}

export function NicknameMenuItems({ item, formatMessage }) {
	const { obligationNickname = '' } = item

	if (obligationNickname) {
		return (
			<MenuItem
				className="EditObligationNickname"
				key="editObligationNickName"
				value="editObligationNickName"
			>
				{formatMessage({
					id: 'custom-action-menu-dropdown.label.edit-nickname'
				})}
			</MenuItem>
		)
	} else {
		return (
			<MenuItem
				className="AddObligationNickname"
				key="addObligationNickName"
				value="addObligationNickName"
			>
				{formatMessage({
					id: 'custom-action-menu-dropdown.label.add-nickname'
				})}
			</MenuItem>
		)
	}
}

export function DeleteNicknameMenuItem({ item, formatMessage }) {
	const { obligationNickname = '' } = item

	if (obligationNickname) {
		return (
			<MenuItem
				className="DeleteObligationNickname"
				key="deleteObligationNickname"
				value="deleteObligationNickname"
			>
				{formatMessage({
					id: 'custom-action-menu-dropdown.label.delete-nickname'
				})}
			</MenuItem>
		)
	}
	return <BasicNoContent />
}

CustomObligationMenu.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	/**
	 * Determine if button is displayed as primary or secondary action. Default (primary).
	 */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			label: PropTypes.string
		})
	)
}

CustomObligationMenu.displayName = 'CustomObligationMenu'

export default CustomObligationMenu
